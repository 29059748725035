import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import Select from 'react-select'
import './style.css'

import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import ReadOnlyRow2 from "./ReadOnlyRow2";
import EditableRow2 from "./EditableRow2";
import ReadOnlyRow3 from "./ReadOnlyRow3";
import EditableRow3 from "./EditableRow3";
import { nanoid } from "nanoid";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UploadFiles from "./upload_file";
function AddSiteEntry() {
  //Modal File Upload
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //////////////////////////////////////
  const [file, setFile] = useState([])
  const [siteId, setsiteId] = useState("")
  const [siteentryId, setsiteentryId] = useState("")
  const [TrackId, setTrackId] = useState("")
  const [sitelist, setsitelist] = useState([]);
  const [courier_mode, setcourier_mode] = useState([]);
  const [category_op, setcategory_op] = useState([{ label: "Invoice", value: "Invoice" }, { label: "Letter", value: "Letter" }, { label: "Invitation", value: "Invitation" }, { label: "Claim", value: "Claim" }, { label: "Others", value: "Others" }, { label: "Pettycash", value: "Pettycash" }]);
  const [category_ch, setcategory_ch] = useState([]);
  const [courierlist, setCourierlist] = useState([]);
  const [courier_mode_choosen, setcourier_mode_choosen] = useState([]);
  const [courierlist_choosen, setCourierlist_choosen] = useState([]);
  const date = new Date();
  // setcurdate(date.toLocaleString('en-US'));
  // alert(curdate)

  /////////////////////////////////////////////////////////////////
  const [categories2, setCategories2] = useState([]);
  const [imageid, setimageid] = useState("");

  const [addFormData2, setAddFormData2] = useState({
    entry_no: "",
    category: "",
    inv_no: "",
    inv_date: "",
    vendor_name: "",
    ref_no: "",
    amntrs: "",
    exp: "",
    remarks: "",
    bills_upl: ""
  });

  const [editFormData2, setEditFormData2] = useState({
    entry_no: "",
    category: "",
    inv_no: "",
    inv_date: "",
    vendor_name: "",
    ref_no: "",
    amntrs: "",
    exp: "",
    remarks: "",
    bills_upl: ""
  });

  const [editCategoryId2, setEditCategoryId2] = useState(null);
  // -------------------------
  const [categories3, setCategories3] = useState([]);


  const [addFormData3, setAddFormData3] = useState({
    entry_no: "",
    category: "",

    refno: "",

    remarks: ""

  });

  const [editFormData3, setEditFormData3] = useState({
    entry_no: "",
    category: "",

    refno: "",

    remarks: ""

  });

  const [editCategoryId3, setEditCategoryId3] = useState(null);
  // -------------------------
  const [categories, setCategories] = useState([]);


  const [addFormData, setAddFormData] = useState({
    entry_no: "",
    category: "",
    from_whom: "",
    to_whom: "",
    remarks: ""
  });

  const [editFormData, setEditFormData] = useState({
    entry_no: "",
    category: "",
    from_whom: "",
    to_whom: "",
    remarks: ""
  });

  const [editCategoryId, setEditCategoryId] = useState(null);
  //////////////////////////////////////FILE UPLOAD///////////////////
  const uploadSingleeditFile = async (e) => {
    // console.log("file", file);
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    // formData.append("fileName", imageid);
    formData.append("ext", e.target.files[0].type);

    await axios.post(`api/upload_image`, formData).then(res => {

      if (res.data.status === 200) {
        // e.target.reset();
        // console.log(file);
        file.push(axios.defaults.baseURL + 'images/' + res.data.resp);
        setFile([...file]);
        // console.log(file);
        // swal("Success",res.data.resp,"success");
        const newFormData = { ...editFormData2 };
        // console.log(newFormData);
        newFormData["bills_upl"] = file.join(",");
        // console.log(editFormData2);
        setEditFormData2(newFormData);


      }
      else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");


      }
    })
  }
  const handleShow2 = () => {

    setShow(true);
    setFile(editFormData2.bills_upl.split(','));
  }
  const uploadSingleFile = async (e) => {
    // console.log("file", file);
    e.preventDefault();
    // setimageid(nanoid())
    // Update the state
    // const data = {

    //       image : e.target.files[0],
    //       fileName: imageid,
    //       ext: e.target.files[0].type

    // }
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    // formData.append("fileName", imageid);
    formData.append("ext", e.target.files[0].type);

    await axios.post(`api/upload_image`, formData).then(res => {

      if (res.data.status === 200) {
        // e.target.reset();
        setFile([...file, axios.defaults.baseURL + 'images/' + res.data.resp]);
        // swal("Success",res.data.message,"success");



      }
      else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");


      }
    })
  }
  const ChangeImage = (id) => {
    // event.preventDefault();
    $("imageprev".id).css("display", "none");
    $("upl".id).css("display", "none");
    $("chng".id).css("display", "contents")

  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    // console.log(s);
  }
  const deleteFile2 = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    // console.log(s);
    const newFormData = { ...editFormData2 };
    // console.log(newFormData);
    newFormData["bills_upl"] = s.join(",");
    // console.log(editFormData2);
    setEditFormData2(newFormData);
  }
  ///////////////////////////////////////////////////////////////////
  const handleAddFormChange = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
    return false;
  };

  const handleAddFormChange2 = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData2, "category": "Invoice" };
    newFormData[fieldName] = fieldValue;


    setAddFormData2(newFormData);
    // console.log(addFormData2);
    return false;
  };
  const handleAddFormChange3 = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData3, "category": "Invoice" };
    newFormData[fieldName] = fieldValue;

    setAddFormData3(newFormData);

    return false;
  };
  const handleEditFormChange = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
    return false;
  };
  const handleEditFormChange2 = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData2 };
    newFormData[fieldName] = fieldValue;
    // newFormData["bills_upl"]=file;

    setEditFormData2(newFormData);
    return false;
  };
  const handleEditFormChange3 = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData3 };
    newFormData[fieldName] = fieldValue;

    setEditFormData3(newFormData);
    return false;
  };
  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    //////////////////////////////////////////////////////////

    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }

    const newCategory = {

      id: nanoid(),
      entry_no: document.getElementById("tracker_form_no").value,
      category: addFormData.category,
      from_whom: addFormData.from_whom,
      to_whom: addFormData.to_whom,
      remarks: addFormData.remarks
    };

    // alert(nanoid());
    // wBE1Sew_CZDEMXJjTERtA
    // 9jOES7tNRU7ltOIydEDhe

    axios.post(`api/store-site`, [data, newCategory]).then(res => {

      if (res.data.status === 200) {
        event.target.reset();
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories(res.data.resp);
        // alert(res.data.resp.id);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }

    });
  };

  const handleAddFormSubmit2 = (event) => {
    event.preventDefault();

    //////////////////////////////////////////////////////////

    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }

    const newCategory = {

      id: nanoid(),
      entry_no: document.getElementById("tracker_form_no").value,
      category: document.getElementById("category2").value,
      inv_no: addFormData2.inv_no,
      inv_date: addFormData2.inv_date,
      vendor_name: addFormData2.vendor_name,
      ref_no: addFormData2.ref_no,
      amntrs: addFormData2.amntrs,
      exp: addFormData2.exp,
      remarks: addFormData2.remarks,
      bills_upl: file
    };

    // alert(nanoid());
    // wBE1Sew_CZDEMXJjTERtA
    // 9jOES7tNRU7ltOIydEDhe

    axios.post(`api/store-site2`, [data, newCategory]).then(res => {

      if (res.data.status === 200) {
        event.target.reset();
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories2(res.data.resp);
        setFile([]);
        // alert(res.data.resp.id);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });

      }

    });


  };
  const handleAddFormSubmit3 = (event) => {
    event.preventDefault();

    //////////////////////////////////////////////////////////

    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }

    const newCategory = {

      id: nanoid(),
      entry_no: document.getElementById("tracker_form_no").value,
      category: document.getElementById("category3").value,

      refno: addFormData3.refno,
      remarks: addFormData3.remarks
    };

    // alert(nanoid());
    // wBE1Sew_CZDEMXJjTERtA
    // 9jOES7tNRU7ltOIydEDhe

    axios.post(`api/store-site3`, [data, newCategory]).then(res => {

      if (res.data.status === 200) {
        event.target.reset();
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories3(res.data.resp);
        // alert(res.data.resp.id);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }

    });
  };

  const handleEditFormSubmit2 = (event) => {
    event.preventDefault();
    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }
    const editedCategory = {
      tracker_form_no: document.getElementById("tracker_form_no").value,

      id: editCategoryId2,
      nano_id: editCategoryId2,
      entry_no: editFormData2.entry_no,
      category: editFormData2.category,
      inv_no: editFormData2.inv_no,
      inv_date: editFormData2.inv_date,
      vendor_name: editFormData2.vendor_name,
      ref_no: editFormData2.ref_no,
      amntrs: editFormData2.amntrs,
      exp: editFormData2.exp,
      remarks: editFormData2.remarks,
      bills_upl: editFormData2.bills_upl
    };


    axios.post(`api/edit-site2`, [data, editedCategory]).then(res => {

      // alert(res.data.status);
      if (res.data.status === 200) {
        event.target.reset();
        // alert(res.data.status);
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories2(res.data.resp);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }
    });

    setEditCategoryId2(null);
  };
  const handleEditFormSubmit3 = (event) => {
    event.preventDefault();
    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }
    const editedCategory = {
      tracker_form_no: document.getElementById("tracker_form_no").value,

      id: editCategoryId3,
      nano_id: editCategoryId3,
      entry_no: editFormData3.entry_no,
      category: editFormData3.category,

      refno: editFormData3.refno,

      remarks: editFormData3.remarks

    };


    axios.post(`api/edit-site3`, [data, editedCategory]).then(res => {

      // alert(res.data.status);
      if (res.data.status === 200) {
        event.target.reset();
        // alert(res.data.status);
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories3(res.data.resp);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }
    });

    setEditCategoryId3(null);
  };
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const data = {

      tracker_form_no: document.getElementById("tracker_form_no").value,
      date: document.getElementById("date").value,
      site_name: courierInput.site_name,
      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date
    }
    const editedCategory = {
      tracker_form_no: document.getElementById("tracker_form_no").value,
      id: editCategoryId,
      nano_id: editCategoryId,
      entry_no: editFormData.entry_no,
      category: editFormData.category,
      from_whom: editFormData.from_whom,
      to_whom: editFormData.to_whom,
      remarks: editFormData.remarks
    };


    axios.post(`api/edit-site`, [data, editedCategory]).then(res => {

      // alert(res.data.status);
      if (res.data.status === 200) {
        event.target.reset();
        // alert(res.data.status);
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
        setCategories(res.data.resp);
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }
    });
    // const editedCategory = {
    //   id: editCategoryId,
    //    entry_no: editFormData.entry_no,
    //   category: editFormData.category,
    //   from_whom: editFormData.from_whom,
    //   to_whom: editFormData.to_whom,
    //   remarks: editFormData.remarks
    // };

    // const newCategories = [...categories];

    // const index = categories.findIndex((category) => category.id === editCategoryId);

    // newCategories[index] = editedCategory;

    // setCategories(newCategories);
    setEditCategoryId(null);
  };
  const handleEditClick2 = (event, category) => {
    event.preventDefault();
    setEditCategoryId2(category.nano_id);

    const formValues = {
      entry_no: category.entry_no,
      category: category.category,

      inv_no: category.inv_no,
      inv_date: category.inv_date,
      vendor_name: category.vendor_name,
      ref_no: category.ref_no,
      amntrs: category.amntrs,
      exp: category.exp,

      bills_upl: category.bills_upl,
      remarks: category.remarks
    };

    setEditFormData2(formValues);
  };
  const handleEditClick3 = (event, category) => {
    event.preventDefault();
    setEditCategoryId3(category.nano_id);

    const formValues = {
      entry_no: category.entry_no,
      category: category.category,


      refno: category.refno,

      remarks: category.remarks


    };

    setEditFormData3(formValues);
  };
  const handleCancelClick2 = () => {
    setEditCategoryId2(null);
  };
  const handleCancelClick3 = () => {
    setEditCategoryId3(null);
  };
  const handleDeleteClick2 = (id) => {
    // alert(id);
    axios.get(`/api/delete-site2/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        // window.location.reload();
        setCategories2(res.data.resp);
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        // thisClicked.innerText = "Delete";
        // window.location.reload();
      }
    });
    const newCategories = [...categories];

    // const index = categories.findIndex((category) => category.id === categoryId);

    // newCategories.splice(index, 1);


  };
  const handleDeleteClick3 = (id) => {
    // alert(id);
    axios.get(`/api/delete-site3/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        // window.location.reload();
        setCategories3(res.data.resp);
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        // thisClicked.innerText = "Delete";
        // window.location.reload();
      }
    });

    const newCategories = [...categories];
    // const index = categories.findIndex((category) => category.id === categoryId);
    // newCategories.splice(index, 1);
  };

  const handleEditClick = (event, category) => {
    event.preventDefault();
    setEditCategoryId(category.nano_id);

    const formValues = {
      entry_no: category.entry_no,
      category: category.category,
      from_whom: category.from_whom,
      to_whom: category.to_whom,
      remarks: category.remarks
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditCategoryId(null);
  };

  const handleDeleteClick = (id) => {
    // alert(id);
    axios.get(`/api/delete-site/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        // window.location.reload();
        setCategories(res.data.resp);
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        // thisClicked.innerText = "Delete";
        // window.location.reload();
      }
    });
    const newCategories = [...categories];

    // const index = categories.findIndex((category) => category.id === categoryId);

    // newCategories.splice(index, 1);


  };

  ////////////////////////////////////////////////////////////////////
  const [curdate, setcurdate] = useState(
    date.toLocaleDateString('en-CA')
  );
  const [courierInput, setCourier] = useState({

    courier_mode_name: '',

    remarks: '',
    status: 1,
    error_list: [],
  });
  ///////////////////////////////////////////////////////////////////////
  //fetch Courier List
  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/view-courier`).then(res => {
      if (isMounted) {
        if (res.status === 200) {
          setCourierlist(res.data.courier)

        }
      }
    });

    return () => {
      isMounted = false
    };

  }, []);
  //////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/getlast_id`).then(res => {
      if (isMounted) {
        if (res.status === 200) {
          // alert(res.data.site)
          let site_id2 = res.data.site
          let site_id = 1;
          if (site_id2 == "") {
            site_id = 1
            setsiteentryId(site_id)
          }
          else {
            // alert();
            site_id = site_id2[0]['id'] + 1
            setsiteentryId(site_id)
          }
          // alert(site_id);
        }
      }
    });

    return () => {
      isMounted = false
    };

  }, []);
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // Fetch Courier Mode
  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/view-couriermode`).then(res => {
      if (isMounted) {
        if (res.status === 200) {
          setcourier_mode(res.data.courier)

        }
      }
    });

    return () => {
      isMounted = false
    };

  }, []);
  ////////////////////////////////////////////////////////////////////////
  // fetch site creation from api


  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/select-site`).then(res => {
      if (isMounted) {
        if (res.status === 200) {
          setsitelist(res.data.site)

        }
      }
    });

    return () => {
      isMounted = false
    };

  }, []);

  ///////////////////////////////////////////////////////////////////
  const options = sitelist.map((option, index) => {
    return { label: option.site_name, value: option.id, key: option.invoice_head }
  })
  const modeoptions = courier_mode.map((option, index) => {
    return { label: option.courier_mode_name, value: option.id }
  })
  const courieroptions = courierlist.map((option, index) => {
    return { label: option.company_name, value: option.id }
  })

  //////////////////////////////////////////////////////////////////
  const handleSelect2 = (option) => {

    setcourier_mode_choosen(option.value)
    setCourier({ ...courierInput, "mode": option.value })
  }
  const handleSelect3 = (option) => {

    setCourierlist_choosen(option.value)
    setCourier({ ...courierInput, "courier_name": option.value })
  }
  const handleSelect4 = (e) => {

    setcategory_ch(e.target.value)
    handleAddFormChange(e)
  }
  const handleSelect5 = (e) => {

    setcategory_ch(e.target.value)
    handleEditFormChange(e)
  }
  const onentereddate = (e) => {
    e.target.type = 'date'
  }
  const onentereddate_blur = (e) => {
    e.target.type = 'text'
  }
  //////////////////////////////////////////////////////////////////
  const handleInput = (e) => {
    e.persist();
    setCourier({ ...courierInput, [e.target.name]: e.target.value })
  }

  const setsite_name = (option) => {
    // e.preventDefault()
    setsiteId(option.value)
    // alert(option.key)

    var tracker_no = "";
    const date = new Date();
    // setcurdate(date.toLocaleString('en-US'));
    // alert(curdate)
    // const month=date.toLocaleString('en-US',{month:'short'})
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = (date.getFullYear()).toString().slice(-2);
    // alert(date);
    // alert(month);
    // alert(year);
    let site_id = (siteentryId).toString().padStart(4, '0')
    // alert(site_id);
    tracker_no = "CT-" + option.key + "-" + year + month + "-" + site_id

    $(".track_no1").html = tracker_no;
    $(".track_no2").val(tracker_no);

    setTrackId(tracker_no);


    setCourier({ ...courierInput, "site_name": option.value })
  }
  const submitCourier = (e) => {
    e.preventDefault();


    const data = {


      tracker_form_no: courierInput.tracker_form_no,
      date: courierInput.date,
      site_name: courierInput.site_name,

      mode: courierInput.mode,
      courier_name: courierInput.courier_name,
      docket_no: courierInput.docket_no,
      dated: courierInput.dated,
      entered_by: courierInput.entered_by,
      entered_time_and_date: courierInput.entered_time_and_date

    }

    axios.post(`api/store-site`, data).then(res => {
      if (res.data.status === 200) {
        e.target.reset();
        setCategories(res.data.resp)
        swal("Success", res.data.message, "success");
        // document.getElementById("hiddencard1").classList.toggle("show");
        // window.location.href="/admin/view-couriermode"
        // document.getElementById('CATEGORY_FORM').reset();
      }
      else if (res.data.status === 400) {
        setCourier({ ...courierInput, error_list: res.data.errors });
      }
    });

  }

  var display_errors = [];
  if (courierInput.error_list) {
    display_errors = [
      courierInput.error_list.tracker_form_no,
      courierInput.error_list.date,
      courierInput.error_list.site_name,
      courierInput.error_list.mode,
      courierInput.error_list.courier_name,
      courierInput.error_list.docket_no,
      courierInput.error_list.dated,
      courierInput.error_list.entered_by,
      courierInput.error_list.entered_time_and_date
    ]
  }

  return (
    <div className="AddSiteEntry_Acknowledge">
      <div className="container-fluid px-4">

        {
          // display_errors.map( (item,index) => {
          // return( <p className="mb-1" key={index}>{item}</p>)
          // })
        }

        <div className="card mt-4">
          <div className='card-icon'>
            <i className="fa-solid fa-file-lines" />
          </div>
          <div className="card-header">
            <div>
              <h4>Add Site Entry</h4>
            </div>
            <div>
              <Link to="/admin/view_ack_site_entry" className="btn btn-primary btn-sm float-end">View</Link>
            </div>
          </div>
          <div className="card-body" id="hiddencard1" >
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
        
                    <div className="form-group mb-3">
                      <div className="mb-5">
                        <label>Tracker Form No. :</label>
                        <p className="track_no1 idsty">{TrackId}</p>
                        <input type="hidden" name="tracker_form_no" id="tracker_form_no" placeholder="Tracker Form No." value={TrackId} className="track_no2 form-control" />
                        <span>{courierInput.error_list.tracker_form_no}</span>
                      </div>
                    </div>                                                    

              
                    <div className="form-group mb-3">
                      <label>Site Name</label>
                      <Select
                        placeholder='Select Site...'
                        className="form1"
                        isSearchable
                        value={options.label}
                        key={options.key}
                        options={options}
                        onChange={(option) => setsite_name(option)}
                      />
                      <span>{courierInput.error_list.site_name}</span>
                    </div>          

        
                    <div className="form-group mb-3">
                      <label>Courier Mode</label>
                      <Select
                        placeholder='Select Courier Mode...'
                        className="form1"
                        isSearchable
                        value={modeoptions.label}
                        key={modeoptions.value}
                        options={modeoptions}
                        onChange={(option) => handleSelect2(option)}
                      />
                      <span>{courierInput.error_list.courier_mode}</span>
                    </div>   

                    <div className="form-group mb-3">
                      <label>Courier Name</label>
                      <Select
                        placeholder='Select Courier Name...'
                        className="form1"
                        isSearchable
                        value={courieroptions.label}
                        key={courieroptions.value}
                        options={courieroptions}
                        onChange={(option) => handleSelect3(option)}
                      />
                      <span>{courierInput.error_list.courier_name}</span>
                    </div> 

                    <div className="form-group mb-3">
                      <label>Docket No</label>
                      <input type="text" name="docket_no" onChange={handleInput} placeholder="Docket No." className="form-control form1" />
                      <span>{courierInput.error_list.docket_no}</span>
                    </div> 

                        

              
                   
                  </div>  
                  <div className="col-xl-6 col-lg-6 col-sm-12 col-12">        
        
                    <div className="form-group mb-3">
                      <label>Date. :</label>
                      <input type="date" name="date" id="date" defaultValue={curdate} disabled={true} className="form-control" />
                      <span>{courierInput.error_list.date}</span>
                    </div> 

                    <div className="form-group mb-3">
                      <label>Entered By</label>
                      <input type="text" name="entered_by" onChange={handleInput} placeholder="Entered By" className="form-control form1" />
                      <span>{courierInput.error_list.entered_by}</span>
                    </div>    
                    
                                                       
                    <div className="form-group mb-3">
                      <label>Entered Time and Date</label>
                      <input type="text" name="entered_time_and_date" onChange={handleInput} placeholder="Entered Date and Time" title="Entered Date and Time" onFocus={(e) => onentereddate(e)} onBlur={(e) => onentereddate_blur(e)} className="form-control form1" />

                      <span>{courierInput.error_list.entered_time_and_date}</span>
                    </div>             

        
                    <div className="form-group mb-3">
                      <label>Dated</label>
                      <input type="text" name="dated" placeholder="Dated" onChange={handleInput} onFocus={(e) => onentereddate(e)} onBlur={(e) => onentereddate_blur(e)} className="form-control form1" />
                      <span>{courierInput.error_list.dated}</span>
                    </div>
                  </div>                  
                </div>
              </div>
            </form>
          </div>
        </div>
      


        <div className="card mt-4">
          <div className='card-icon'>
              <i className="fa-solid fa-table-list" />
          </div>
          <div className="card-header">


            <h4>Invoice</h4>


          </div>

          <div className="card-body">

            <div className="app-container">



              <form onSubmit={handleAddFormSubmit2} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">
                    <thead className="theadcss">
                      <tr>

                        <th>Category</th>
                        <th>Inv No</th>
                        <th>Inv Date</th>
                        <th>Vendor Name</th>
                        <th>Reference No(ERP)</th>
                        <th>Amount Rs.</th>
                        <th>Type of Expenses</th>
                        <th>Remarks</th>
                        <th>Bills Upload</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="hidden"
                            name="entry_no"
                            required="required"
                            placeholder="Entry No"
                            className="form-control"
                            value={TrackId}
                            onChange={handleAddFormChange2}
                          />
                          <input
                            type="text"
                            name="category"
                            id="category2"
                            value="Invoice"
                            readOnly="readonly"
                            placeholder="Invoice"
                            className="form-control"
                            onChange={handleAddFormChange2}
                          />
                        </td><td>
                          <input
                            type="text"
                            name="inv_no"
                            className="form-control inv_no"
                            required="required"
                            placeholder="Inv No"
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="date"
                            name="inv_date"
                            className="form-control Idate"
                            required="required"
                            placeholder="Inv Date"
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="text"
                            name="vendor_name"
                            className="form-control"
                            required="required"
                            placeholder="Vendor Name"
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="text"
                            name="ref_no"
                            className="form-control"
                            required="required"
                            placeholder="Reference No."
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="text"
                            name="amntrs"
                            className="form-control"
                            required="required"
                            placeholder="Amount Rs."
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="text"
                            name="exp"
                            className="form-control"
                            required="required"
                            placeholder="Expenses Type"
                            onChange={handleAddFormChange2}
                          /></td><td>
                          <input
                            type="text"
                            name="remarks"
                            className="form-control"
                            required="required"
                            placeholder="Remarks"
                            onChange={handleAddFormChange2}
                          />
                        </td><td>
                          <Button variant="primary" onClick={handleShow}>
                            <i className="fas fa-image" ></i>
                          </Button>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Upload Images</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row g-3" >
                                <div className="col-md-12">

                                  <input
                                    type="file"
                                    name="bills_upl"
                                    className="form-control"

                                    placeholder="Bills Upload"
                                    onChange={uploadSingleFile}
                                  />
                                </div>

                                <div className="col-md-12">
                                  <table className="table table-bordered table-striped">
                                    <tbody>
                                      {
                                        (file).length > 0 &&
                                        (file).map((item, index) => {
                                          return (
                                            <tr>
                                              <td>{item}</td>
                                              <td>
                                                <button type="button" className="btn btn-danger" onClick={() => deleteFile(index)}>
                                                  delete
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>

                              <Button variant="primary" onClick={handleClose}>
                                Save Changes
                              </Button>
                            </Modal.Footer>
                          </Modal></td><td>
                          <button type="submit" className="btn btn-primary">Add</button></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </form>
              <form onSubmit={handleEditFormSubmit2} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">

                    <tbody>
                      {categories2.map((category) => (
                        <Fragment>
                          {editCategoryId2 === category.nano_id ? (
                            <EditableRow2
                              editFormData2={editFormData2}

                              handleEditFormChange2={handleEditFormChange2}
                              handleCancelClick2={handleCancelClick2}
                              ChangeImage={ChangeImage}
                              deleteFile2={deleteFile2}
                              show={show}
                              handleClose={handleClose}
                              handleShow2={handleShow2}
                              uploadSingleeditFile={uploadSingleeditFile}
                            />
                          ) : (
                            <ReadOnlyRow2
                              category={category}
                              handleEditClick2={handleEditClick2}
                              handleDeleteClick2={handleDeleteClick2}
                              file={file}
                              deleteFile={deleteFile}
                            />
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>



          </div>

        </div>





        <div className="card mt-4">
            <div className='card-icon'>
              <i className="fa-solid fa-table-list" />
            </div>
          <div className="card-header">

            <h4> Letter / Invitation / Claim / Others </h4>


          </div>

          <div className="card-body">

            <div className="app-container">



              <form onSubmit={handleAddFormSubmit} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">
                    <thead>
                      <tr>

                        <th>Category</th>
                        <th>From Whom</th>
                        <th>To Whom</th>
                        <th>Remarks</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="hidden"
                            name="entry_no"
                            required="required"
                            placeholder="Entry No"
                            className="form-control"
                            value={TrackId}
                            onChange={handleAddFormChange}
                          />


                          <select name="category" defaultValue="" className="form-control" onChange={(e) => handleSelect4(e)}>
                            <option value="" disabled>Select Category</option>
                            <option value="Letter">Letter</option>
                            <option value="Invitation">Invitation</option>

                            <option value="Claim">Claim</option>
                            <option value="Others">Others</option>
                          </select>
                        </td>

                        <td>
                          <input
                            type="text"
                            name="from_whom"
                            className="form-control"
                            required="required"
                            placeholder="From_whom"
                            onChange={handleAddFormChange}
                          /></td>

                        <td>
                          <input
                            type="text"
                            name="to_whom"
                            className="form-control"
                            required="required"
                            placeholder="TO Whom"
                            onChange={handleAddFormChange}
                          /></td>

                        <td>
                          <input
                            type="text"
                            name="remarks"
                            className="form-control"
                            required="required"
                            placeholder="Remarks"
                            onChange={handleAddFormChange}
                          /></td>

                        <td>
                          <button type="submit" className="btn btn-primary">Add</button></td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
              <form onSubmit={handleEditFormSubmit} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">

                    <tbody>
                      {categories.map((category) => (
                        <Fragment>
                          {editCategoryId === category.nano_id ? (
                            <EditableRow
                              editFormData={editFormData}
                              handleSelect5={handleSelect5}
                              handleEditFormChange={handleEditFormChange}
                              handleCancelClick={handleCancelClick}
                            />
                          ) : (
                            <ReadOnlyRow
                              category={category}
                              handleEditClick={handleEditClick}
                              handleDeleteClick={handleDeleteClick}
                            />
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div className="card mt-4">
            <div className='card-icon'>
              <i className="fa-solid fa-table-list" />
            </div>
          <div className="card-header">


            <h4> Petty Cash </h4>


          </div>

          <div className="card-body">

            <div className="app-container">



              <form onSubmit={handleAddFormSubmit3} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">
                    <thead>
                      <tr>

                        <th>Category</th>
                        <th>Ref No</th>

                        <th>Remarks</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody><tr><td>
                      <input
                        type="hidden"
                        name="entry_no"
                        required="required"
                        placeholder="Entry No"
                        className="form-control"
                        value={TrackId}
                        onChange={handleAddFormChange3}
                      />


                      <input
                        type="text"
                        name="category"
                        className="form-control"
                        value="Petty Cash"
                        readOnly="readonly"
                        id="category3"
                        placeholder="Category"
                        onChange={handleAddFormChange3}
                      /></td><td>
                        <input
                          type="text"
                          name="refno"
                          className="form-control"
                          required="required"
                          placeholder="Ref No."
                          onChange={handleAddFormChange3}
                        /></td><td>
                        <input
                          type="text"
                          name="remarks"
                          className="form-control"
                          required="required"
                          placeholder="Remarks"
                          onChange={handleAddFormChange3}
                        /></td><td>
                        <button type="submit" className="btn btn-primary">Add</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
              <form onSubmit={handleEditFormSubmit3} className="sublistform">
                <div className='table-responsive'>
                  <table className="table ">

                    <tbody>
                      {categories3.map((category) => (
                        <Fragment>
                          {editCategoryId3 === category.nano_id ? (
                            <EditableRow3
                              editFormData3={editFormData3}
                              handleEditFormChange3={handleEditFormChange3}
                              handleCancelClick3={handleCancelClick3}
                            />
                          ) : (
                            <ReadOnlyRow3
                              category={category}
                              handleEditClick3={handleEditClick3}
                              handleDeleteClick3={handleDeleteClick3}
                            />
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default AddSiteEntry;