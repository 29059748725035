import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import Select from 'react-select'
import './style.css'


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

function EditSiteEntry() {
	//////////////////////////////////////FILE UPLOAD///////////////////
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	const [file, setFile] = useState([])
	//////////////////////////////////////

	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [courierInput, setCourier] = useState({
		error_list: []
	}
	);
	const [reccourierInput, setrecCourier] = useState([]);
	const [rec, setrec] = useState([]);
	const [error, setError] = useState([]);
	const courier_id = useParams().id;
	const [siteId, setsiteId] = useState("")
	const [siteentryId, setsiteentryId] = useState("")
	const [TrackId, setTrackId] = useState("")
	const [sitelist, setsitelist] = useState([]);
	const [courier_mode, setcourier_mode] = useState([]);
	const [category_op, setcategory_op] = useState([{ label: "Invoice", value: "Invoice" }, { label: "Letter", value: "Letter" }, { label: "Invitation", value: "Invitation" }, { label: "Claim", value: "Claim" }, { label: "Others", value: "Others" }, { label: "Pettycash", value: "Pettycash" }]);
	const [category_ch, setcategory_ch] = useState([]);
	const [courierlist, setCourierlist] = useState([]);
	const [courieracklist, setCourieracklist] = useState([]);
	const [courier_mode_choosen, setcourier_mode_choosen] = useState([]);
	const [courierlist_choosen, setCourierlist_choosen] = useState([]);
	const [checkrec1, setcheckrec1] = useState([]);
	const [checkrec2, setcheckrec2] = useState([]);
	const [checkrec3, setcheckrec3] = useState([]);
	const date = new Date();


	/////////////////////////////////////////////////////////////////
	const [categories2, setCategories2] = useState([]);




	// -------------------------
	const [categories3, setCategories3] = useState([]);



	// -------------------------
	const [categories, setCategories] = useState([]);






	useEffect(() => {

		// const courier_id = props.match.params.id;
		// alert(courier_id);
		axios.get(`/api/edit-courier-site-entry/${courier_id}`).then(res => {
			if (res.data.status === 200) {
				setCourier(res.data.courier);

				setCategories(res.data.resp2);

				setCategories2(res.data.resp1);
				setCategories3(res.data.resp3);


				if (!courierInput.courier_mode_name) {
					courierInput.courier_mode_name = ""
				}

				if (!courierInput.remarks) {
					courierInput.remarks = ""
				}



			}
			else if (res.data.status === 404) {
				swal("Error", res.data.message, "error");
				navigate('/admin/view_site_entry');
			}
			setLoading(false);
		});

	}, [courier_id, navigate]);

	const [curdate, setcurdate] = useState(
		date.toLocaleDateString('en-CA')
	);
	// const [courierInput, setCourier] = useState({

	// courier_mode_name: '',

	// remarks: '',
	// status: 1,
	// error_list: [],
	// });
	///////////////////////////////////////////////////////////////////////
	//fetch Courier List
	useEffect(() => {
		let isMounted = true;

		axios.get(`/api/view-courier`).then(res => {
			if (isMounted) {
				if (res.status === 200) {
					setCourierlist(res.data.courier)

				}
			}
		});

		return () => {
			isMounted = false
		};

	}, []);
	//////////////////////////////////////////////////////////////////////
	useEffect(() => {
		let isMounted = true;

		axios.get(`/api/getlast_id`).then(res => {
			if (isMounted) {
				if (res.status === 200) {
					// alert(res.data.site)
					let site_id2 = res.data.site
					let site_id = 1;
					if (site_id2 == "") {
						site_id = 1
						setsiteentryId(site_id)
					}
					else {
						// alert();
						site_id = site_id2[0]['id'] + 1
						setsiteentryId(site_id)
					}
					// alert(site_id);
				}
			}
		});

		return () => {
			isMounted = false
		};

	}, []);
	////////////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////
	// Fetch Courier Mode
	useEffect(() => {
		let isMounted = true;

		axios.get(`/api/view-couriermode`).then(res => {
			if (isMounted) {
				if (res.status === 200) {
					setcourier_mode(res.data.courier)

				}
			}
		});

		return () => {
			isMounted = false
		};

	}, []);
	////////////////////////////////////////////////////////////////////////
	// fetch site creation from api


	useEffect(() => {
		let isMounted = true;

		axios.get(`/api/select-site`).then(res => {
			if (isMounted) {
				if (res.status === 200) {
					setsitelist(res.data.site)

				}
			}
		});

		return () => {
			isMounted = false
		};

	}, []);

	///////////////////////////////////////////////////////////////////
	const options = sitelist.map((option, index) => {
		return { label: option.site_name, value: option.id, key: option.invoice_head }
	})
	const modeoptions = courier_mode.map((option, index) => {
		return { label: option.courier_mode_name, value: option.id }
	})
	const courieroptions = courierlist.map((option, index) => {
		return { label: option.company_name, value: option.id }
	})

	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	const handleInput2 = (e, n, index) => {
		e.persist();
	}
	const handleInput = (e, n, index) => {
		e.persist();
		var input = document.getElementsByName('received' + n + '[]');
		var received = [];


	}



	const hideshown = (e, trid) => {
		e.preventDefault();
		var tr = $('#child' + trid);
		// var row = table.row(tr);
		// alert(tr.className.indexOf('shown'));
		if (tr.attr('class') == 'shown') {

			tr.removeClass('shown');
			tr.addClass('hidden');
			$('#dtctrl' + trid).html('<i className="fa fa-plus-circle primary" aria-hidden="true"></i>');

		} else {

			tr.removeClass('hidden');
			tr.addClass('shown');
			$('#dtctrl' + trid).html('<i className="fa fa-minus-circle primary" aria-hidden="true"></i>');
			// tr.find('svg').attr('data-icon', 'minus-circle'); // FontAwesome 5
		}
	}
	//////////////////////////////////////////////////////////////////////////////////////
	const handleAddFormSubmit = (event) => {
		event.preventDefault();

		//////////////////////////////////////////////////////////
		///////////////////////sub_id/////////////////////////////
		var input = document.getElementsByName('sub_id[]');
		var sub_id = {};
		// console.log(sub_id);
		for (var i = 0; i < input.length; i++) {
			sub_id[i] = input[i].value;
		}
		////////////////////////////////////////////////////////
		//////////////////////handover_to_username/////////////

		var input2 = document.getElementsByName('handover_to_username[]');
		var handover_to_username = {};
		// console.log(sub_id);
		for (var i = 0; i < input2.length; i++) {
			handover_to_username[i] = input2[i].value;
		}
		///////////////////////////////////////////////////////
		//////////////////////handover_to_dept/////////////

		var input3 = document.getElementsByName('handover_to_dept[]');
		var handover_to_dept = {};
		// console.log(sub_id);
		for (var i = 0; i < input3.length; i++) {
			handover_to_dept[i] = input3[i].value;
		}
		///////////////////////////////////////////////////////
		//////////////////////category/////////////

		var input4 = document.getElementsByName('category[]');
		var category = {};
		// console.log(sub_id);
		for (var i = 0; i < input4.length; i++) {
			category[i] = input4[i].value;
		}
		///////////////////////////////////////////////////////
		//////////////////////received/////////////

		var input5 = document.getElementsByName('received[]');
		var received = {};
		// console.log(sub_id);
		for (var i = 0; i < input5.length; i++) {
			received[i] = input5[i].value;
		}
		///////////////////////////////////////////////////////
		const data = {

			tracker_form_no: event.target.tracker_form_no.value,
			main_id: event.target.main_id.value,
			received: received,
			sub_id: sub_id,
			handover_to_username: handover_to_username,
			handover_to_dept: handover_to_dept,
			Acknowledged_by: event.target.Acknowledged_by.value,
			dated: event.target.dated.value,
			category: category

		}




	};

	///////////////////////////////////////////////////////////////////////////////
	const filtered = options.filter(option => {
		return option.value == courierInput.site_name;
	});

	const filtered2 = modeoptions.filter(option => {
		return option.value == courierInput.mode
	});
	const filtered3 = courieroptions.filter(option => {
		return option.value == courierInput.courier_name;
	});
	useEffect(() => {
		// var track =courierInput.tracker_form_no

		axios.get(`/api/fetch-track/${courier_id}`).then(res => {
			if (res.data.status === 200) {

				setrecCourier(res.data.courier)

			}
		})
	}
		, []);
	// console.log(reccourierInput);
	//////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className='EditSiteEntry_Acknowledge'>
			<form className="sublistform2" onSubmit={handleAddFormSubmit}>
				<div className="container-fluid px-4">



					<div className="card mt-4">
						<div className='card-icon'>
							<i className="fa-solid fa-file-lines" />
						</div>
						<div className="card-header">
							<div>
								<h4>Edit Acknowledgment Entry</h4>
							</div>
							<div>
								<Link to="/admin/view_ack_site_entry" className="btn btn-primary btn-sm float-end">View Site Entry</Link>
							</div>
						</div>
						<div className="card-body" id="hiddencard1">

							<>
								<table className="flatTable">


									<tbody>
										<tr>
											<td>Tracker Form No</td>

											<td className="val2">{courierInput.tracker_form_no}</td>
											<td colSpan="2">
												<input type="text" className="inputext1" name="main_id" readOnly={true} value={courierInput.id} />
												<input type="text" className="inputext1" name="tracker_form_no" readOnly={true} value={courierInput.tracker_form_no} />
											</td>
										</tr>
										<tr>
											<td>Date</td>
											<td className="val">{courierInput.date}</td>
											<td colSpan="2"></td>
										</tr>
										<tr>
											<td>Site Name
											</td>
											<td className="val">{
												filtered.map(option => {
													return (option.label);
												})
											}</td>
											<td colSpan="2"></td>
										</tr>
										<tr>
											<td>Courier Mode</td>
											<td className="val">{
												filtered2.map(option => {
													return (option.label);
												})
											}</td>
											<td colSpan="2"></td>
										</tr>
										<tr>
											<td>Courier Name
											</td>
											<td className="val">{
												filtered3.map(option => {
													return (option.label);
												})
											}</td>
											<td>Entered By
											</td>
											<td className="val">{courierInput.entered_by}</td>
										</tr>
										<tr>
											<td>Docket No
											</td>
											<td className="val">{courierInput.docket_no}</td>
											<td>Entered Time and Date</td>
											<td className="val">{courierInput.entered_time_and_date}</td>

										</tr>
										<tr>
											<td>Dated</td>
											<td className="val">{courierInput.dated}</td>
											<td colSpan="2"></td>
										</tr>
									</tbody>
								</table>

								<table id="example" className="table table-bordered table-hover table-striped display" cellSpacing="0">
									<thead>
										<tr><th colSpan="9">INVOICE</th></tr>
										<tr>
											<th></th>
											<th>Received / Not</th>
											<th>Inv No</th>
											<th>Inv Date</th>
											<th>Vendor Name</th>
											<th>Reference No(ERP)</th>
											<th>Amount Rs.</th>
											<th>Type of Expenses</th>

											<th>Handover to </th>
										</tr>
									</thead>
									<tbody>
										{
											categories2.map((category, index) => (
												<>
													<tr key={category.id}>

														<td className="dt-control" id={`dtctrl${category.id}`} onClick={(e) => hideshown(e, category.id)}><i className="fa fa-plus-circle primary" aria-hidden="true"></i></td>
														<td>
															<input type="text" readOnly={true} className="inputext1" name="sub_id[]" value={category.id} />
															<div>
																<label className="toggler-wrapper style-23">

																	<input type="checkbox" name="received1[]" value={category.received} onChange={(e) => handleInput(e, 1, index)} />
																	<div className="toggler-slider">
																		<div className="toggler-knob"></div>
																	</div>
																</label>
															</div>
														</td>
														<td>{category.inv_no}</td>
														<td>{category.inv_date}</td>
														<td>{category.vendor_name}</td>
														<td>{category.ref_no}</td>
														<td>{category.amntrs}</td>
														<td>{category.exp}</td>

														<td className="inputext">
															<input type="hidden" className="form-control" value="Invoice" readOnly={true} name="category[]" placeholder="category" />
															<div><input type="text" className="form-control" onChange={handleInput2} name="handover_to_username[]" value={category.handover_to_username} placeholder="User Name" /></div>
															<div><input type="text" className="form-control" onChange={handleInput2} name="handover_to_dept[]" value={category.handover_to_dept} placeholder="Department" /></div>
														</td>
													</tr>
													<tr key={`child${category.id}`} id={`child${category.id}`} className="hidden">
														<td colSpan="9">
															<table cellPadding="9" cellSpacing="0" border="0" >
																<tbody>
																	<tr><td className="tblwidth">Remarks : </td> <td>{category.remarks}</td></tr>
																	<tr><td className="tblwidth">Bills Upload:</td>
																		<td>
																			{(category.bills_upl).split(",").length > 0 &&
																				(category.bills_upl).split(",").map((item, index) => {
																					return (
																						<div key={item}>
																							<img src={item} alt="" className="imageprev" />
																						</div>
																					);
																				})}
																		</td>

																	</tr>


																</tbody>
															</table>
														</td>
													</tr>
												</>
											))}
									</tbody>
								</table>





								<table className="table table-bordered table-striped">
									<thead>
										<tr><th colSpan="6">Letter / Invitation / Claim / Others</th></tr>
										<tr>
											<th>Recieved / Not</th>
											<th>Category</th>
											<th>From Whom</th>
											<th>To Whom</th>
											<th>Remarks</th>
											<th>Handover to </th>
										</tr>
									</thead>
									<tbody>
										{categories.map((category, index) => (
											<tr className="tablerow">
												<td>
													<input type="text" className="inputext1" readOnly={true} name="sub_id[]" value={category.id} />
													<div>
														<label className="toggler-wrapper style-23">
															<input type="checkbox" name="received2[]" value={category.received} onChange={(e) => handleInput(e, 2, index)} />
															<div className="toggler-slider">
																<div className="toggler-knob"></div>
															</div>
														</label></div></td>

												<td>{category.category}</td>
												<td>{category.from_whom}</td>
												<td>{category.to_whom}</td>
												<td>{category.remarks}</td>
												<td className="inputext">
													<input type="hidden" className="form-control" readOnly={true} value="letter" name="category[]" placeholder="category" />
													<div><input type="text" className="form-control" onChange={handleInput2} name="handover_to_username[]" value={category.handover_to_username} placeholder="User Name" /></div>
													<div><input type="text" className="form-control" onChange={handleInput2} name="handover_to_dept[]" value={category.handover_to_dept} placeholder="Department" /></div>

												</td>
											</tr>

										))}
									</tbody>
								</table>




								<table className="table table-bordered table-striped">
									<thead>
										<tr><th colSpan="5">Petty Cash</th></tr>
										<tr>
											<th>Recieved / Not</th>
											<th>Category</th>
											<th>Ref No</th>

											<th>Remarks</th>
											<th>Handover to </th>
										</tr>
									</thead>
									<tbody>
										{categories3.map((category, index) => (
											<tr className="tablerow">
												<td>
													<input type="text" className="inputext1" name="sub_id[]" readOnly={true} value={category.id} />
													<div>
														<label className="toggler-wrapper style-23">
															<input type="checkbox" name="received3[]" value={category.received} onChange={(e) => handleInput(e, 3, index)} />
															<div className="toggler-slider">
																<div className="toggler-knob"></div>
															</div>
														</label></div></td>
												<td>{category.category}</td>
												<td>{category.refno}</td>
												<td>{category.remarks}</td>
												<td className="inputext">
													<input type="hidden" className="form-control" readOnly={true} value="petty" name="category[]" />
													<div><input type="text" className="form-control" name="handover_to_username[]" onChange={handleInput2} value={category.handover_to_username} placeholder="User Name" /></div>
													<div><input type="text" className="form-control" name="handover_to_dept[]" onChange={handleInput2} value={category.handover_to_dept} placeholder="Department" /></div>
												</td>

											</tr>
										))}
									</tbody>
								</table>





							</>
						</div>
						<div className="card-footer">
							<div><input type="text" className="form-control" name="Acknowledged_by" onChange={handleInput2} value={reccourierInput.Acknowledged_by} placeholder="Acknowledged by" /></div>
							<div><input type="text" className="form-control" onChange={handleInput2} name="dated" value={reccourierInput.dated} placeholder="Dated" /></div>

							<button type="submit" className="btn btn-success float-end">Save</button>
						</div>
					</div>
				</div>
			</form>
		</div>

	)
}

export default EditSiteEntry;