import React, {useState, useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";

function EditCourier(props) 
{
    
 const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [courierInput, setCourier] = useState([]);
    const [error, setError] = useState([]);
 // console.log(useParams());
  const courier_id = useParams().id;
    useEffect(() => {
        
       // const courier_id = props.match.params.id;
         // alert(courier_id);
        axios.get(`/api/edit-couriermode/${courier_id}`).then(res=>{
            if(res.data.status === 200)
            {
                setCourier(res.data.courier);
if (!courierInput.courier_mode_name) {
    courierInput.courier_mode_name = ""
} 

 if (!courierInput.remarks) {
    courierInput.remarks = ""
} 
//  if (courierInput.status==1) {
//     courierInput.status ="on" 
// } 


        }
            else if(res.data.status === 404)
            {
                swal("Error",res.data.message,"error");
                 navigate('/admin/view-couriermode');
            }
            setLoading(false);
        });

    }, [courier_id, navigate]);

    const handleInput = (e) => {
        e.persist();
       
  // alert(e.target.value);
        setCourier({...courierInput, [e.target.name]: e.target.value });
    }

    const updateCourier = (e) => {
        e.preventDefault();
        
        // const courier_id = useParams().id;
//         var s=0;
        // alert(courierInput.status);
if(courierInput.status==="on")
{
courierInput.status=1;
}
if(courierInput.status==="0")
{
courierInput.status=1;
}
else{

    courierInput.status=0;
}
        const data = courierInput;
        axios.put(`/api/update-couriermode/${courier_id}`,data).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");
                setError([]);
                window.location.href="/admin/view-couriermode";
            }
            else if(res.data.status === 422)
            {
                swal("All fields are mandetory","","error");
                setError(res.data.errors);
            }
            else if(res.data.status === 404)
            {
                swal("Error",res.data.message,"error");
                 navigate('admin/view-couriermode');
            }
        });
    }
//  const Toogleclick= (e) => {
// co
// }
    if(loading)
    {
        return ( 
            <div className="loadingCenter">
                <FadeLoader color="#77ec0f" />
            </div>
        )
    }

    return (
        <div className="EditCourierMode">
            <div className="container px-4">
                <div className="card mt-4">
                    <div className='card-icon'>
                        <i className="fa-solid fa-file-lines" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Edit Courier Mode Creation </h4>
                        </div>
                        <div>
                                <Link to="/admin/view-couriermode" className="btn btn-primary btn-sm float-end">BACK</Link>
                        </div>
                    </div>
                    <div className="card-body">

                        <form onSubmit={updateCourier} id="COURIER_FORM">
                             
                                <div className="card-body">

                                    <div className="form-group mb-3">
                                        <label>Courier Company Name</label>
                                        <input type="text" name="courier_mode_name" onChange={handleInput} value={courierInput.courier_mode_name} className="form-control" />
                                      
                                    </div>
                                  


                                    <div className="form-group mb-3">
                                        <label>Remarks</label>
                                        <textarea name="remarks" onChange={handleInput} defaultValue={courierInput.remarks} className="form-control"></textarea>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Status</label>&nbsp;&nbsp;&nbsp;
                                        
                                        <input type="checkbox" name="status" onChange={handleInput}  value={courierInput.status} defaultChecked={courierInput.status==1 ? true : false }/>
                                    </div>

                                </div>
                            <button type="submit" className="btn btn-primary px-4 float-end">Update</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCourier;