import React, {useState, useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { motion } from 'framer-motion';

function EditCourier(props) 
{
    
 const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [courierInput, setCourier] = useState([]);
    const [error, setError] = useState([]);
 // console.log(useParams());
  const courier_id = useParams().id;
    useEffect(() => {
        
       // const courier_id = props.match.params.id;
         // alert(courier_id);
        axios.get(`/api/edit-courier/${courier_id}`).then(res=>{
            if(res.data.status === 200)
            {
                setCourier(res.data.courier);
if (!courierInput.company_name) {
    courierInput.company_name = ""
} 
if (!courierInput.company_person_name) {
    courierInput.company_person_name = ""
} 
if (!courierInput.mobile_no) {
    courierInput.mobile_no = ""
} 
 if (!courierInput.DOT) {
    courierInput.DOT = ""
} 
 if (!courierInput.email) {
   courierInput.email = ""
} 
 if (!courierInput.acc_holder_name ) {
    courierInput.acc_holder_name  = ""
}
         

 if (!courierInput.address) {
    courierInput.address = ""
} 
 if (!courierInput.accno) {
    courierInput.accno = ""
}
if (!courierInput.branch_name ) {
    courierInput.branch_name  = ""
}        

 if (!courierInput.ifsc_code) {
    courierInput.ifsc_code = ""
} 
 if (!courierInput.descrip) {
    courierInput.descrip = ""
} 
 if (courierInput.status==1) {
    courierInput.status ="true" 
} 

        }
            else if(res.data.status === 404)
            {
                swal("Error",res.data.message,"error");
                 navigate('/admin/view-courier');
            }
            setLoading(false);
        });

    }, [courier_id, navigate]);

    const handleInput = (e) => {
        e.persist();
        setCourier({...courierInput, [e.target.name]: e.target.value });
    }

    const updateCourier = (e) => {
        e.preventDefault();
        if(courierInput.status==="on")
{
courierInput.status=1;
}
if(courierInput.status==="0")
{
courierInput.status=1;
}
else{

    courierInput.status=0;
}
        // const courier_id = useParams().id;
//         var s=0;
//         alert(courierInput.status);
// if(courierInput.status===1)
// {
// courierInput.status=1;
// }
// else{

//     courierInput.status=0;
// }
        const data = courierInput;
        axios.put(`/api/update-courier/${courier_id}`,data).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");
                setError([]);
                window.location.href="/admin/view-courier";
            }
            else if(res.data.status === 422)
            {
                swal("All fields are mandetory","","error");
                setError(res.data.errors);
            }
            else if(res.data.status === 404)
            {
                swal("Error",res.data.message,"error");
                 navigate('admin/view-courier');
            }
        });
    }

    if(loading)
    {
        return (
            <div className="loadingCenter">
                <FadeLoader color="#77ec0f" />
            </div>
        )
    } 

    return (
        <div className="EditCourier">
            <div className="container px-4">
                <motion.div className="card mt-4"
                    initial={{ opacity: 0}} 
                    animate={{ opacity: 1 }} 
                    transition={{ type: 'spring', stiffness: 50}}>
                    <div className='card-icon'>
                        <i className="fa-solid fa-file-lines" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Edit Courier Company Creation</h4>
                        </div>
                        <div>
                            <Link to="/admin/view-courier" className="btn btn-primary btn-sm float-end">BACK</Link>
                        </div>
                    </div>
                    <div className="card-body">

                        <form onSubmit={updateCourier} id="COURIER_FORM">                             
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                            <label>Courier Company Name</label>
                                            <input type="text" name="company_name" onChange={handleInput} value={courierInput.company_name} className="form-control" />                                      
                                        </motion.div>
                                        <motion.div className="form-group mb-3" 
                                         initial={{y:80, opacity: 0}} 
                                         animate={{y:0, opacity: 1}}    
                                         transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                            <label>DOT(Landline Number)</label>
                                            <input type="text" name="DOT" onChange={handleInput} value={courierInput.DOT} className="form-control" />
                                        </motion.div>
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .3, type:'spring', stiffness: 180}}>
                                            <label>Account Holder Name</label>
                                            <input type="text" name="acc_holder_name" onChange={handleInput} value={courierInput.acc_holder_name} className="form-control" />
                                        </motion.div>
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .4, type:'spring', stiffness: 180}}>   
                                            <label>Address</label>
                                                <textarea name="address" onChange={handleInput} defaultValue={courierInput.address} className="form-control"></textarea>
                                        </motion.div> 
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                        <motion.div className="form-group mb-3"
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                            <label>Contact Person Name</label>
                                            <input type="text" name="company_person_name" onChange={handleInput} value={courierInput.company_person_name} className="form-control" />
                                        </motion.div>
                                        <motion.div className="form-group mb-3"
                                         initial={{y:80, opacity: 0}} 
                                         animate={{y:0, opacity: 1}}    
                                         transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                            <label>Email</label>
                                            <input type="email" name="email" onChange={handleInput} value={courierInput.email} className="form-control" />
                                        </motion.div>                                                                             
                                        <motion.div className="form-group mb-3"
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .3, type:'spring', stiffness: 180}}>
                                            <label>Account Number</label>
                                            <input type="text" name="accno" onChange={handleInput} value={courierInput.accno} className="form-control" />
                                        </motion.div>                                        
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .4, type:'spring', stiffness: 180}}>
                                            <label>Description</label>
                                            <textarea name="descrip" onChange={handleInput} defaultValue={courierInput.descrip} className="form-control"></textarea>
                                        </motion.div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                            <label>Mobile Number</label>
                                            <input type="text" name="mobile_no" onChange={handleInput} value={courierInput.mobile_no} className="form-control" />
                                        </motion.div>                                        
                                        <motion.div className="form-group mb-3"
                                         initial={{y:80, opacity: 0}} 
                                         animate={{y:0, opacity: 1}}    
                                         transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                            <label>Branch Name</label>
                                            <input type="text" name="branch_name" onChange={handleInput} value={courierInput.branch_name} className="form-control" />
                                        </motion.div>
                                        <motion.div className="form-group mb-3"
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .3, type:'spring', stiffness: 180}}>
                                            <label>IFSC Code</label>
                                            <input type="text" name="ifsc_code" onChange={handleInput} value={courierInput.ifsc_code} className="form-control" />
                                        </motion.div>                                       
                                        <motion.div className="form-group mb-3" 
                                        initial={{y:80, opacity: 0}} 
                                        animate={{y:0, opacity: 1}}    
                                        transition={{ delay: .4, type:'spring', stiffness: 180}}>
                                            <label>Status</label>&nbsp;&nbsp;&nbsp;
                                            <input type="checkbox" name="status" onChange={handleInput} value={courierInput.status} defaultChecked={courierInput.status==1 ? true : false } />
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-align'>
                                <button type="submit" className="btn btn-primary px-4 float-end">Update</button>        
                            </div>                            
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default EditCourier;