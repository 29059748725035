import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";
import Datetime from "react-datetime";
import {
  faHome,
  faArrowsUpDown,
  faEdit,
  faDeleteLeft,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const token2 = localStorage.getItem("auth_token");
const user_name = localStorage.getItem("auth_name");
const user_id = localStorage.getItem("rat_id");
const OtherSub = ({ MainData }) => {
  var user = null;
  if (token2) user = user_name;
  else user = null;
  var list = "";
  const [Pettycash, setPettycash] = useState([]);
  const [Sublist, setSublist] = useState([]);
  const [getbutton, setbutton] = useState([]);
  const [showemployee, setemployee] = useState([]);
  const [handover_by_choosen, sethandover_by_choosen] = useState([]);
  const [SubList, setSubList] = useState({
    sub_inv_no: "",
    sub_inv_date: "",
    vendor_name: "",
    refernce_no: "",
    amount: "",
    exp_type: "",
    remarks: "",

    bill_upload: "",
    handover: "",

    sub_from_wh: "",
    sub_to_wh: "",
    edit_id: "",
    error_list: [],
  });
  var button = "";
  var EDITVIST = "";
  const state = (e) => {
    SubList.sub_from_wh = "";
    SubList.sub_to_wh = "";
    SubList.remarks = "";
    SubList.handover = "";
    employee();
    handleInput(e);
  };
  useEffect(() => {
    setTimeout(() => {
      
      Getsublist();
    }, 4000);
  }, []);

  const Getsublist = (e) => {
    const data = {
      ho_entry_no: document.getElementById("invoice_no").value,

      category: "Others",
    };

    axios.post(`/api/view-invsub`, data).then((res) => {
      if (res.data.ho_entrysub != "") {
        document.getElementById("site_id").setAttribute("disabled", "disabled");
        document
          .getElementById("courier_name")
          .setAttribute("disabled", "disabled");
        document
          .getElementById("courier_mode")
          .setAttribute("disabled", "disabled");
        // document.getElementById("categoery")?.removeAttribute("disabled");

        setPettycash(res.data.ho_entrysub);
      } else {
        document.getElementById("site_id").removeAttribute("disabled");
        document.getElementById("courier_name").removeAttribute("disabled");
        document.getElementById("courier_mode").removeAttribute("disabled");
        // document.getElementById("categoery")?.removeAttribute("disabled");
        setPettycash(res.data.ho_entrysub);
      }
    });
  };

  useEffect(() => {
    if (EDITVIST == 200) {
      button = (
        <button
          type="submit"
          className="btn btn-primary px-4 float-end"
          onClick={ediothersub}
        >
          <span>Edit</span>
        </button>
      );
    } else {
      button = (
        <button
          type="submit"
          className="btn btn-primary px-4 float-end"
          onClick={submitothersub}
        >
          {" "}
          <span>Add</span>{" "}
        </button>
      );
    }
    setbutton(button);
    axios.post(`/api/get-employee`).then((res) => {
      if (res.data.status === 200) {
        setemployee(res.data.employee);
      }
    });
  }, []);
  const employee = (e) => {
    axios.post(`/api/get-employee`).then((res) => {
      if (res.data.status === 200) {
        setemployee(res.data.employee);
      }
    });
  };

  const handover_options = showemployee.map((option, index) => {
    return {
      label: option.employee_name + "/" + option.designation_name,
      value: option.user_id_a,
      key: option.user_id_a,
    };
  });

  const handleSelecthand = (option) => {
    document.getElementById("handover").value = option.value;

    sethandover_by_choosen(option.key);
    setSubList({ ...SubList, handover: option.key }, () => {});
  };
  // const getemployee = (e) => {

  //     axios.post(`/api/get-employee`).then(res => {
  //       if (res.data.status === 200) {
  //         Getsublist(e);
  //         setemployee(res.data.employee);

  //       }
  //     });

  //   }

  list = Pettycash.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td>{item.from_wh}</td>
          <td>{item.to_wh}</td>
          <td>{item.remarks}</td>

          <td>
            {item.employee_name} / {item.div_name}
          </td>
          <td className="td-flex">
            <button
              onClick={(e) => edit_sub(e, item.ho_id)}
              className="editclass btn-sm"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>

            <button
              type="button"
              onClick={(e) => sub_del(e, item.ho_id)}
              className="delclass btn-sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      </>
    );
  });

  const edit_sub = (e, id) => {
    e.preventDefault();
    axios.get(`/api/get-subho/${id}`).then((res) => {
      if (res.data.status === 200) {
        EDITVIST = res.data.status;

        SubList.sub_from_wh = res.data.from_wh;
        SubList.sub_to_wh = res.data.to_wh;

        SubList.remarks = res.data.remarks;
        SubList.handover = res.data.handover;
        SubList.edit_id = res.data.ho_id;
        handleInput(e);
        if (EDITVIST == 200) {
          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={ediothersub}
            >
              <span>Edit</span>
            </button>
          );
        } else {
          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitothersub}
            >
              <span>Add</span>
            </button>
          );
        }
        setbutton(button);
      }
    });
  };
  const sub_del = (e, id) => {
    e.preventDefault();
    axios.get(`/api/det-subho/${id}`).then((res) => {
      if (res.data.status === 200) {
        Getsublist(e);
      }
    });
  };

  const handleInput = (e) => {
    e.persist();
    setSubList({ ...SubList, [e.target.name]: e.target.value });
  };

  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  const submitothersub = (e) => {
    e.preventDefault();

    MainData.site_id = document.getElementById("s_id").value;
   
    MainData.courier_mode = document.getElementById("courier_mode").value;
    MainData.invoice_no = document.getElementById("invoice_no").value;
    MainData.docket_no = document.getElementById("docket_no").value;
    MainData.form_wh = document.getElementById("form_wh").value;
    MainData.recived_by = document.getElementById("recived_by").value;

    var edit_from_wh = document.getElementById("sub_from_wh").value;
    var edit_to_wh = document.getElementById("sub_to_wh").value;

    var edit_remarks = document.getElementById("other_remarks").value;
    var edit_handover = document.getElementById("handover").value;
    SubList.sub_from_wh = edit_from_wh;
    SubList.sub_to_wh = edit_to_wh;
    SubList.remarks = edit_remarks;
    SubList.handover = edit_handover;

    if (MainData.courier_mode == "By Hand") {
      MainData.by_hand = document.getElementById("by_hand").value;
      MainData.courier_name = "";
      if (!MainData.by_hand) {
        swal("Fill this by hand");

        return false;
      }
    } else if (MainData.courier_mode == "Courier") {
      MainData.courier_name = document.getElementById("c_id").value;
      MainData.by_hand = "";

      if (!MainData.courier_name) {
        swal("Select Courier Name");

        return false;
      }
    }

    if (MainData.entry_date == "") {
      MainData.entry_date = defaultValue;
    }

    MainData.enter_by = user;

    if (!MainData.site_id) {
      swal("Select Site Name");

      return false;
    } else if (!MainData.courier_mode) {
      swal("Select Mode Name");

      return false;
    } else if (!MainData.docket_no) {
      swal("Fill  docket no");

      return false;
    } else if (!MainData.form_wh) {
      swal("Fill  From Whom");

      return false;
    } else if (!MainData.recived_by) {
      swal("Fill recived by");

      return false;
    } else if (!SubList.sub_from_wh) {
      SubList.error_list = "fill From Whom";
      handleInput(e);
      return false;
    } else if (!SubList.sub_to_wh) {
      SubList.error_list = "fill To Whom";
      handleInput(e);
      return false;
    } else if (!SubList.handover) {
      SubList.error_list = "fill Handover To ";
      handleInput(e);
      return false;
    } else {
      SubList.error_list = "";

      handleInput(e);

      const data = {
        ho_entry_no: MainData.invoice_no,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        recived_by: MainData.recived_by,
        category: "Others",

        from_wh: SubList.sub_from_wh,
        to_wh: SubList.sub_to_wh,

        remarks: SubList.remarks,
        handover: SubList.handover,
      };

      axios.post(`api/store-ho`, data).then((res) => {
        if (res.data.status === 200) {
          Getsublist(e);

          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitothersub}
            >
              <span>Add</span>
            </button>
          );
          setbutton(button);
          state(e);
        } else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });
    }
  };
  const ediothersub = (e, id) => {
    e.preventDefault();

    MainData.site_id = document.getElementById("s_id").value;

    MainData.courier_mode = document.getElementById("courier_mode").value;
    MainData.invoice_no = document.getElementById("invoice_no").value;
    MainData.docket_no = document.getElementById("docket_no").value;
    MainData.form_wh = document.getElementById("form_wh").value;
    MainData.recived_by = document.getElementById("recived_by").value;

    var edit_from_wh = document.getElementById("sub_from_wh").value;
    var edit_to_wh = document.getElementById("sub_to_wh").value;

    var edit_remarks = document.getElementById("other_remarks").value;
    var edit_handover = document.getElementById("handover").value;
    SubList.sub_from_wh = edit_from_wh;
    SubList.sub_to_wh = edit_to_wh;
    SubList.remarks = edit_remarks;
    SubList.handover = edit_handover;

    if (MainData.entry_date == "") {
      MainData.entry_date = defaultValue;
    }

    MainData.enter_by = user;
    if (MainData.courier_mode == "By Hand") {
      MainData.by_hand = document.getElementById("by_hand").value;
      MainData.courier_name = "";
      if (!MainData.by_hand) {
        swal("Fill this by hand");

        return false;
      }
    } else if (MainData.courier_mode == "Courier") {
      MainData.courier_name = document.getElementById("c_id").value;
      MainData.by_hand = "";

      if (!MainData.courier_name) {
        swal("Select Courier Name");

        return false;
      }
    }

    if (!MainData.site_id) {
      swal("Select Site Name");

      return false;
    } else if (!MainData.courier_mode) {
      swal("Select Mode Name");

      return false;
    } else if (!MainData.docket_no) {
      swal("Fill  docket no");

      return false;
    } else if (!MainData.form_wh) {
      swal("Fill  From Whom");

      return false;
    } else if (!MainData.recived_by) {
      swal("Fill recived by");

      return false;
    } else if (!SubList.sub_from_wh) {
      SubList.error_list = "fill From Whom";
      handleInput(e);
      return false;
    } else if (!SubList.sub_to_wh) {
      SubList.error_list = "fill To Whom";
      handleInput(e);
      return false;
    } else if (!SubList.handover) {
      SubList.error_list = "fill Handover To ";
      handleInput(e);
      return false;
    } else {
      SubList.error_list = "";

      handleInput(e);

      const data = {
        ho_entry_no: MainData.invoice_no,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        recived_by: MainData.recived_by,
        category: "Others",

        from_wh: SubList.sub_from_wh,
        to_wh: SubList.sub_to_wh,

        remarks: SubList.remarks,
        handover: SubList.handover,
        edit_id: SubList.edit_id,
      };

      axios.post(`api/edit-ho`, data).then((res) => {
        if (res.data.status === 200) {
          Getsublist(e);

          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitothersub}
            >
              <span>Add</span>
            </button>
          );
          setbutton(button);
          state(e);
          e.target.reset();
        } else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });
    }
  };
  return (
    <form calssname="form-horizontal" id="HO_FORM">
      <span style={{ color: "red" }}>{SubList.error_list}</span>      
      <div className="table-responsive">
        <div className="table-hdbox lico">
          <div className="icon">
              <i className="fa-solid fa-envelope"></i>
          </div>
          <div className="">
            <h6>LETTER / INVITATION / CLAIM / OTHERS</h6>
          </div>
        </div>
        <table className="table  display" cellSpacing="0">
          <thead>
            <tr>
              <th>#S.No</th>
              <th>From Whom</th>
              <th>To Whom</th>
              <th>Remarks</th>
              <th>Handover to</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#</td>

              <td>
                {" "}
                <input
                  type="text"
                  name="sub_from_wh"
                  id="sub_from_wh"
                  onChange={handleInput}
                  value={SubList.sub_from_wh}
                  className="form-invsbcontrol"
                />
              </td>
              <td>
                {" "}
                <input
                  type="text"
                  name="sub_to_wh"
                  id="sub_to_wh"
                  onChange={handleInput}
                  value={SubList.sub_to_wh}
                  className="form-invsbcontrol"
                />
              </td>
              <td>
                {" "}
                <input
                  type="text"
                  name="remarks"
                  id="other_remarks"
                  onChange={handleInput}
                  value={SubList.remarks}
                  className="form-invsbcontrol"
                />
              </td>
              <td>
                <input
                  type="hidden"
                  value={handover_by_choosen}
                  id="handover"
                />
                <Select
                  placeholder="Select Person..."
                  name="handover"
                  isSearchable
                  value={handover_options.filter(
                    (handover_options) =>
                      handover_options.value == SubList.handover
                  )}
                  key={handover_options.key}
                  options={handover_options}
                  onChange={(option) => handleSelecthand(option)}
                />
              </td>
              <td>{getbutton}</td>
            </tr>
            {list}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default OtherSub;
export function Getsublist() {}
