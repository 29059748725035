import React from 'react'
import './App.css';
// import ReactDOM from 'react-dom'
import {useState} from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Dashboard from './components/admin/Dashboard';
import Profile from './components/admin/Profile';
import Login from './components/frontend/auth/Login';

import MasterLayout from './layouts/admin/MasterLayout';

import Courier from './components/admin/courier_company/Courier';
import EditCourier from './components/admin/courier_company/EditCourier';
import ViewCourier from './components/admin/courier_company/ViewCourier';

import CourierMode from './components/admin/courier_mode/CourierMode';
import EditCourierMode from './components/admin/courier_mode/EditCourierMode';
import ViewCourierMode from './components/admin/courier_mode/ViewCourierMode';

// import View_site_entry from './components/admin/site_entry/ViewSiteEntry';
// import Add_site_entry from './components/admin/site_entry/AddSiteEntry';
// import Edit_site_entry from './components/admin/site_entry/EditSiteEntry';

import View_ack_site_entry from './components/admin/acknowledge_site_entry/ViewSiteEntry';
import Add_ack_site_entry from './components/admin/acknowledge_site_entry/AddSiteEntry';
import Edit_ack_site_entry from './components/admin/acknowledge_site_entry/EditSiteEntry';

// site-Entry Import files
import CreateSite from './components/admin/site_entry/CreateSite';
import EditSite from './components/admin/site_entry/EditSite';
import ViewSite from './components/admin/site_entry/ViewSite';
import SiteSublist from './components/admin/site_entry/SiteSublist';
import AdminSite from './components/admin/site_entry/AdminSite';

import BillUploadSite from './components/admin/site_entry/BillUploadSite';
import UpdateSite from './components/admin/site_entry/UpdateSite';

// HO-Entry Import files
import CreateHo from './components/admin/ho_entry/CreateHo';
import EditHo from './components/admin/ho_entry/EditHo';
import ViewHo from './components/admin/ho_entry/ViewHo';
import HoSublist from './components/admin/ho_entry/HoSublist';

import BillUpload from './components/admin/ho_entry/BillUpload';
import UpdateHo from './components/admin/ho_entry/UpdateHo';
import AdminHo from './components/admin/ho_entry/AdminHo';

// Ho_recevier Import files

import AckView from './components/admin/Ho_recevier/AckView';
import ReceiverAction from './components/admin/Ho_recevier/ReceiverAction';
import ByHo from './components/admin/Ho_recevier/ByHo';
import BySite from './components/admin/Ho_recevier/BySite';




import View from './components/admin/site_acknowledgement/View';
import SiteAction from './components/admin/site_acknowledgement/SiteAction';
import axios from 'axios';

   axios.defaults.baseURL="https://courier.zigmaglobal.in/backend/";
    // axios.defaults.baseURL="http://192.168.1.51:8001/";

axios.defaults.headers.post['Content-Type']='application/json';
axios.defaults.headers.post['Accept']='application/json';
axios.defaults.withCredentials=true;
axios.interceptors.request.use(function(config){
const token=localStorage.getItem('auth_token');

config.headers.Authorization=token?`Bearer ${token}`:'';
return config;
});


// const [user, setUser] = useState("");
const token2=localStorage.getItem('auth_token');
const user_name=localStorage.getItem('auth_name');
const user_id=localStorage.getItem('rat_id');

function App() {

// if(token2)
// {
//   setUser(user_name)
// }
// else
// {
//   user=null;
// }
var user=null;

if(token2)
{
user=user_name;
// alert(user);
}
else
user=null;
// setUser()
return (

<BrowserRouter>


<Routes>
    <Route path="/" element={<Login/>} />
    <Route path="/admin" element={<MasterLayout/>} > 
        <Route path='/admin' element={<Dashboard />}/>
        <Route path='/admin/view-courier' element={<ViewCourier/>}/> 
        <Route path='/admin/edit-courier/:id' element={<EditCourier/>}/> 
        <Route path='/admin/courier' element={<Courier/>}/> 

        <Route path='/admin/view-couriermode' element={<ViewCourierMode/>}/> 
        <Route path='/admin/edit-couriermode/:id' element={<EditCourierMode/>}/> 
        <Route path='/admin/couriermode' element={<CourierMode/>}/> 

        {/* <Route path='/admin/view_site_entry' element={<View_site_entry/>}/> 
        <Route path='/admin/add_site_entry' element={<Add_site_entry/>}/> 
        <Route path='/admin/edit_site_entry/:id' element={<Edit_site_entry/>}/>  */}

        <Route path='/admin/view_ack_site_entry' element={<View_ack_site_entry/>}/> 
        <Route path='/admin/add_ack_site_entry' element={<Add_ack_site_entry/>}/> 
        <Route path='/admin/edit_ack_site_entry/:id' element={<Edit_ack_site_entry/>}/> 

          <Route path='/admin/create-ho' element={<CreateHo/>}/> 
        <Route path='/admin/edit-ho' element={<EditHo/>}/> 
        <Route path='/admin/view-ho' element={<ViewHo/>}/> 
        <Route path='/admin/update-ho/:id' element={<UpdateHo/>}/> 
        <Route path='/admin/admin-ho' element={<AdminHo/>}/> 
{/* Site Entry */}
        <Route path='/admin/create-site' element={<CreateSite/>}/> 
        <Route path='/admin/edit-site' element={<EditSite/>}/> 
        <Route path='/admin/view-site' element={<ViewSite/>}/> 
        <Route path='/admin/update-site/:id' element={<UpdateSite/>}/> 
        <Route path='/admin/admin-site' element={<AdminSite/>}/> 
       
        <Route path='/admin/ack' element={<AckView/>}/> 
        <Route path='/admin/by-site/:id' element={<BySite/>}/> 
        <Route path='/admin/by-ho/:id' element={<ByHo/>}/> 
        <Route path='/admin/rec-action/:id' element={<ReceiverAction/>}/>  
        {/* Acknowledgement */}
        <Route path='/admin/view-ac' element={<View/>}/>
        <Route path='/admin/ac-action/:id' element={<SiteAction/>}/>
       
    </Route>
     <Route path='/admin/bill-upload/:id' element={<BillUpload/>}/>
     <Route path='/admin/bill-upload-site/:id' element={<BillUploadSite/>}/>
     
</Routes>

</BrowserRouter>
);

}



export default App;
