import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {
  faHome,
  faArrowsUpDown,
  faEdit,
  faDeleteLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import { motion } from "framer-motion";

function AdminHo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [Holist, setHolist] = useState([]);
  var Data = "";
  var sn = 0;
  var no = "";
  const sortIcon = <FontAwesomeIcon icon={faArrowsUpDown} />;
  const deleteHo = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";

    axios.get(`/api/delete-Ho/${id}`).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");

        window.location.reload();
      } else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Delete";
        window.location.reload();
      }
    });
  };

  const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
  //list fliter function

  const [list, setlist] = useState({
    from_date: "",
    to_date: "",
    site_id: "",

    error_list: [],
  });
  const [sitelist, setsitelist] = useState([]);
  const [s_id, setsite_id] = useState([]);

  useEffect(() => {
    axios.get(`/api/view-site`).then((res) => {
      if (res.status === 200) {
        setsitelist(res.data.site);
      }
    });

    viewMain("empty");
  }, []);

  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  const site_options = sitelist.map((option, index) => {
    return { label: option.site_name, value: option.id, key: option.id };
  });
  const handleInput = (e) => {
    e.persist();
    setlist({ ...list, [e.target.name]: e.target.value });
  };
  const handleSelect3 = (option) => {
    setlist({ ...list, site_id: option.value });
    setsite_id(option.value);
  };
  const viewMain = (status) => {
    if (status == "fliter") {
      if (list.from_date == "" || list.from_date == null) {
        list.from_date = defaultValue;
      }
      if (list.to_date == "" || list.to_date == null) {
        list.to_date = defaultValue;
      }
    }

    const data = {
      from_date: list.from_date,
      to_date: list.to_date,
      site_id: list.site_id,
      status: status,
    };

    axios.post(`/api/view-hoentry`, data).then((res) => {
      if (res.status === 200) {
        setHolist(res.data.ho_entry);
        Data = res.data.ho_entry;
        setLoading(false);
      }
    });
  };

  const getList = (e) => {
    viewMain("fliter");
  };

  // end fliter function

  const columns = [
    {
      name: "S.NO",
      cell: (row, index) => index + 1,
    },
    {
      name: "Entry Date",
      selector: (row) => row.entry_date,
      sortable: true,
    },
    {
      name: "HO Entry Number",
      selector: (row) => row.ho_entry_no,
      sortable: true,
    },
    {
      name: "Site Name",
      selector: (row) => row.site_name,
      sortable: true,
    },
    {
      name: "Courier name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Courier mode name",
      selector: (row) => row.courier_mode_id,
      sortable: true,
    },
    {
      name: "Edit",
      selector: (row) => (
        <Link to={`/admin/update-ho/${row.ho_id}`} className="editclass">
          {" "}
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <Link
          href="#"
          onClick={(e) => deleteHo(e, row.ho_entry_no)}
          className="delclass"
        >
          {" "}
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      ),
    },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
  `;
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <input
        id="search"
        type="text"
        autoFocus={true}
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="searchbox"
      />
      <ClearButton type="button" onClick={onClear} className="clearbtn">
        <i className="fas fa-close" />
      </ClearButton>
    </>
  );
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = Holist.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    const onFilterValue = (e) => {
      e.preventDefault();
      setFilterText(e.target.value);
      e.target.focus();
    };
    return (
      <FilterComponent
        onFilter={onFilterValue}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // <Link to={`/admin/update-ho/${row.ho_id}`} className="btn btn-success btn-sm"> <FontAwesomeIcon icon={faEdit} /></Link>

  //https://react-data-table-component.netlify.app/?path=/docs/getting-started-patterns--page

  const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({
    data,
  }) => {
    return <p></p>;
  };
  const [pending, setPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(Data);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="AdminHo">
      <div className="container px-4">
        <motion.div className="card mt-4" 
          initial={{ x: 100}} 
          animate={{ x: 0}} 
          transition={{ type: 'spring', stiffness: 250}}>
          <div className="card-icon">
            <i className="fa-solid fa-table-list" />
          </div>
          <div className="card-header">
            <div>
              <h4> HO Entry List</h4>
            </div>
            <div>
              <Link
                to="/admin/create-ho"
                className="btn btn-primary btn-sm float-end"
              >
                Add +
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="side-gap">      
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                        <motion.div className="filter-form" 
                        initial={{ opacity: 0}} 
                        animate={{ opacity: 1}} 
                        transition={{ type: 'tween', delay: .5}}>
                            <label>From Date :</label>
                            <input
                            type="date"
                            name="from_date"
                            id="from_date"
                            onChange={handleInput}
                            defaultValue={defaultValue}
                            className="form-control"
                            />
                        </motion.div>            
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                        <motion.div className="filter-form" 
                        initial={{ opacity: 0}} 
                        animate={{ opacity: 1}} 
                        transition={{ type: 'tween', delay: .6}}>
                            <label>TO Date :</label>
                            <input
                            type="date"
                            name="to_date"
                            id="to_date"
                            onChange={handleInput}
                            defaultValue={defaultValue}
                            className="form-control"
                            />
                        </motion.div>                
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                        <motion.div className="filter-form sitenm" 
                        initial={{ opacity: 0}} 
                        animate={{ opacity: 1}} 
                        transition={{ type: 'tween', delay: .7}}>
                            <input type="hidden" name="ss_id" id="ss_id" value={s_id} />
                            <label>Site Name :</label>
                            <Select
                            id="site_id"
                            name="site_id"
                            placeholder="Select Site..."
                            isSearchable
                            value={site_options.label}
                            key={site_options.key}
                            options={site_options}
                            onChange={(option) => handleSelect3(option)}
                            />
                        </motion.div>               
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                        <motion.div className="filter-form btn" 
                        initial={{ opacity: 0}} 
                        animate={{ opacity: 1}} 
                        transition={{ type: 'tween', delay: .8}}>
                            <button
                            type="submit"
                            onClick={getList}
                            className=" px-4 float-end mt-3"
                            >
                            GO
                            </button>                
                        </motion.div>
                    </div>
                </div>
            </div>
            <DataTable
              pagination
              responsive
              keyFiled="ho_id"
              columns={columns}
              data={filteredItems}
              selectableRowsComponentProps={selectProps}
              sortIcon={sortIcon}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              progressPending={pending}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
export default AdminHo;
