import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import $ from 'jquery'; 

function ViewCourier() {
const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [courierlist, setCourierlist] = useState([]);


    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/view-couriermode`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setCourierlist(res.data.courier)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    const deleteCourier = (e, id) => {
        e.preventDefault();
        
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting";

        axios.get(`/api/delete-couriermode/${id}`).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");
                // thisClicked.closest("tr").remove();
                  $(thisClicked).parent().parent().parent().remove();
                window.location.reload();
            }
            else if(res.data.status === 404)
            {
                swal("Success",res.data.message,"success");
                thisClicked.innerText = "Delete";
                 window.location.reload();
            }
        });

    }
    const downloadCSV=(array)=> {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
if (csv == null) return;

const filename = 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
 }

 link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const convertArrayOfObjectsToCSV=(e,array)=> {
    e.preventDefault();
  let result;
// console.log(Object.keys(courierlist[0]));
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(courierlist[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
      let ctr = 0;
     keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

         result += item[key];          
          ctr++;
      });
      result += lineDelimiter;
 });

  return result;
}
const ExpandedComponent = ({ courierlist }) => <pre>{JSON.stringify(courierlist, null, 2)}</pre>;

const Export = ({ onExport }) => 
   <div className='export'>
    <small>Export File </small>
    <span onClick={e => onExport(e.target.value)}> 
        <i className="fa-solid fa-file-export"></i>
    </span>
</div>;
const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(courierlist)} />, []);

const columns = [
    {
        name: 'Company Mode Name',
        selector: row => row.courier_mode_name,
         sortable: true,
    },
    {
        name: 'Remarks',
        selector: row => row.remarks,
         sortable: true,
    },

    {
        name: 'Status',
        selector: row => row.status==1 ?'Active' : 'Inactive',
         sortable: true,
    },
    {
        name: 'Edit',
        selector: row => <Link to={`/admin/edit-couriermode/${row.id}`} className="editclass"><i className="fas fa-edit"></i></Link>,
    },
    {
        name: 'Delete',
        selector: row => <span className="delclass" onClick={ (e) => deleteCourier(e, row.id) }><i className="fas fa-trash"></i></span>,
    },
];

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;`;

const TextField = styled.input `
height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
      <input
          id="search"
         type="text"
         autoFocus={true}
         placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
          className="searchbox"
      />
      <ClearButton type="button" onClick={onClear} className="clearbtn">
          <i className='fas fa-close' />
      </ClearButton>
  </>
);

const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = courierlist.filter(
     item => item.courier_mode_name && item.courier_mode_name.toLowerCase().includes(filterText.toLowerCase()),
 );

    const subHeaderComponentMemo = React.useMemo(() => {
     const handleClear = () => {
          if (filterText) {
             setResetPaginationToggle(!resetPaginationToggle);
             setFilterText('');
         }
     };
const onFilterValue = (e) => {
    e.preventDefault();
    setFilterText(e.target.value)
    e.target.focus()

}
     return (
        <FilterComponent onFilter={onFilterValue} onClear={handleClear} filterText={filterText} />
     );     
}, [filterText, resetPaginationToggle]);
    return  (
        <div className='ViewCourierMode'>
            <div className="container px-4">
                <div className="card mt-4">
                    <div className='card-icon'>
                        <i className="fa-solid fa-table-list" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Courier Mode Creation List  </h4>
                        </div>
                        <div>
                            <Link to="/admin/couriermode" className="btn btn-primary btn-sm float-end">Add +</Link>
                        </div>
                    </div>
                    <div className="card-body">
                       <DataTable
                            columns={columns}
                            data={filteredItems}
                           pagination
                           
                          
                           fixedHeader
                           fixedHeaderScrollHeight="500px"
                           highlightOnHover
                           subHeader
                           paginationResetDefaultPage={resetPaginationToggle} 
                            subHeaderComponent={subHeaderComponentMemo}
                           actions={actionsMemo}
                           persistTableHead
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCourier;