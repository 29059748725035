import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import AllCatsubSite from './AllCatsubSite'
import Datetime from 'react-datetime';
import Upload from '../../../assets/admin/img/Upload.png';
import Select from 'react-select';
import { faHome, faArrowsUpDown, faEdit, faDeleteLeft, faTrash, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const token2 = localStorage.getItem('auth_token');
const user_name = localStorage.getItem('auth_name');

const SiteSublist = ({ MainData }) => {
  const user_id = localStorage.getItem("rat_id");
  var user = null;
  if (token2)
    user = user_name;
  else
    user = null;


  var list = '';
  var EDITVIST = '';
  var button = '';
  var empty = '';
  var ho_up = '';
  const date = new Date();
  const defaultValue = date.toLocaleDateString('en-CA');
var place='Select Person...';

  const [InvSublist, setInvSublist] = useState([]);
  const [showvendor, setvendor] = useState([]);
  const [showrefno, setrefno] = useState([]);
  const [showemployee, setemployee] = useState([]);
  const [edited, setedited] = useState([]);
  // const [Sublist, setSublist] = useState([]);
  const [getsub, setgetsub] = useState([]);
  const [getbutton, setbutton] = useState([]);
  const [vendor_name_choosen, setvendor_name_choosen] = useState();
  const [handover_by_choosen, sethandover_by_choosen] = useState([]);
  const [placeholder, setplaceholder] = useState([]);

  const [SubList, setSubList] = useState({
    sub_inv_no: '',
    sub_inv_date: '',
    vendor_name: '',
    refernce_no: '',
    amount: '',
    exp_type: '',
    remarks_site_sub: '',

    bill_upload: '',
   
    edit_id: '',
    inv_for: '',
    error_list: [],

  });
  const [choose, setchoose] = useState([]);


  useEffect(() => {

    if (EDITVIST == 200) {

      button = <button type="submit" className="btn btn-primary px-4 float-end" onClick={submitsitentrysub}><span>Edit</span></button>;
    }
    else {

      button = <button type="submit" className="btn btn-primary px-4 float-end" onClick={submitsitentrysub}><span>Add</span></button>;
    }
    setbutton(button);


  }, []);


  const handleInput = (e) => {

    e.persist();
    setSubList({ ...SubList, [e.target.name]: e.target.value })



  }

  

  useEffect(() => {
    setTimeout(() => {
      
      Getsublist();
    }, 4000);
    
    axios.post(`/api/get-employee`).then(res => {
      if (res.data.status === 200) {
        
        setemployee(res.data.employee);
        setplaceholder(place);
      }
    });
  }, []);
  const employee = (e) => {
    axios.post(`/api/get-employee`).then(res => {
      if (res.data.status === 200) {
        
        setemployee(res.data.employee);
        setplaceholder(place);
      }
    });
  }



  const openInNewTab = (url, id) => {

    onmouseover = window.open('/admin/bill-upload-site/' + id, '', 'height=550,width=800,scrollbars=yes,left=320,top=120,toolbar=no,location=no,directories=no,status=no,menubar=no');
    return false;
  };
  const Empty = [];

  const state = (e) => {
    SubList.sub_inv_no = '';
    SubList.sub_inv_date = '';
    SubList.sub_inv_for = '';
    SubList.vendor_name = '';
    SubList.refernce_no = '';
    SubList.amount = '';
    SubList.exp_type = '';
    SubList.remarks_site_sub = '';
   
    SubList.bill_upload = '';
    SubList.inv_for = '';
    setvendor(Empty);
    handleInput(e);
  }





  const Getsublist = (e) => {
    const data = {

      site_entry_no: document.getElementById('invoice_no').value,

      category:'Invoice',
      num :'1'
    }

    axios.post(`/api/view-siteinvsub`, data).then(res => {


      // if (res.data.ho_entrysub != '') {

      // document.getElementById("site_id").setAttribute("disabled", "disabled");
      // document.getElementById("courier_name").setAttribute("disabled", "disabled");
      // document.getElementById("courier_mode").setAttribute("disabled", "disabled");
      // document.getElementById("categoery").removeAttribute("disabled");

      setInvSublist(res.data.site_entrysub)


      // }
      // else {

      //   // document.getElementById("site_id").removeAttribute("disabled");
      //   // document.getElementById("courier_name").removeAttribute("disabled");
      //   // document.getElementById("courier_mode").removeAttribute("disabled");
      //   // document.getElementById("categoery").removeAttribute("disabled");
      //   setInvSublist(res.data.ho_entrysub)
      // }

    });

  }

  list =
    InvSublist.map((item, index) => {
      return (
        <>

          <tr >
            <td>{index + 1}</td>
            <td>{item.inv_no}</td>
            <td>{item.inv_date}</td>
            <td>{item.inv_for} </td>
            <td>{item.party_name}</td>

            <td>{item.reference_no}</td>
            <td>{item.amount}</td>
            <td>{item.type_expenses}</td>
            <td>{item.remarks}</td>
            <td align='center'>{item.bill_upload}
              <Link onClick={handleInput, (url) => openInNewTab(url, item.id)}  >
                <img src={Upload} wdith='17' height='17' />
              </Link></td>
            


            <td className='td-flex'>
            <button onClick={(e) => edit_sub(e, item.id)} className="editclass btn-sm"><FontAwesomeIcon  icon={faEdit} /></button>

            <button type="button" onClick={(e) => sub_del(e, item.id)} className="delclass btn-sm"><FontAwesomeIcon  icon={faTrash} /></button>
             </td>
          </tr>

        </>
      )
    });




  const handleInput_1 = (e) => {

    e.persist();
    setSubList({ ...SubList, [e.target.name]: e.target.value })



  }



  // the function to add the  sublist value for invoice type 
  // 
  const submitsitentrysub = (e) => {



    e.preventDefault();


     MainData.site_id = document.getElementById('s_id').value;

    MainData.courier_mode = document.getElementById('courier_mode').value;

    MainData.docket_no = document.getElementById('docket_no').value;
    MainData.form_wh = document.getElementById('form_wh').value;
   
    MainData.categoery = document.getElementById('categoery').value;

    if (MainData.courier_mode == 'By Hand') {

      MainData.by_hand = document.getElementById('by_hand').value;
      MainData.courier_name = '';
    } else if (MainData.courier_mode == 'Courier') {
      MainData.courier_name = document.getElementById('c_id').value;
      MainData.by_hand = '';
    }


    var edit_inv_for = document.getElementById('inv_for').value;
    var edit_inv_no = document.getElementById('sub_inv_no').value;
    var edit_sub_inv_date = document.getElementById('sub_inv_date').value;
    var edit_vendor_name = document.getElementById('vendor_name').value;

    var edit_refernce_no = document.getElementById('refernce_no').value;
    var edit_amount = document.getElementById('amount').value;
    var edit_exp_type = document.getElementById('exp_type').value;
    var edit_remarks = document.getElementById('remarks').value;
    //var edit_bill_upload=document.getElementById('bill_upload').value;
    

    SubList.sub_inv_no = edit_inv_no;
    SubList.sub_inv_date = edit_sub_inv_date;

    // SubList.refernce_no = edit_refernce_no;
    SubList.refernce_no = edit_refernce_no;
    SubList.amount = edit_amount;
    SubList.exp_type = edit_exp_type;
    SubList.remarks_site_sub = edit_remarks;
    SubList.bill_upload = '';
    
    SubList.inv_for = edit_inv_for;


    // -------------------------------------------------Edited by mohana-----
    SubList.vendor_name = edit_vendor_name;

    // --------------------------------------------------------------------------

    handleInput(e);

    if (MainData.entry_date == '') {
      MainData.entry_date = defaultValue;
    }


    MainData.enter_by = user_id;

    if (MainData.courier_mode == 'By Hand') {

      if (!MainData.by_hand) {
swal('By Hand');
       
       
        return false;

      }

    }
    else if (MainData.courier_mode == 'Courier') {

      if (!MainData.courier_name) {
swal('Select Courier Name');
        
        return false;

      }

    }

    if (!MainData.site_id) {
      swal('Select Site Name');

      return false;
    }

    else if (!MainData.courier_mode) {
      swal('Select Mode Name');

      return false;

    }

    else if (!MainData.docket_no) {
      swal('Fill  docket no');

      return false;

    }
    else if (!MainData.form_wh) {
      swal('Fill  From Whom');

      return false;

    }

   
    else if (!SubList.sub_inv_no) {
      SubList.error_list = 'fill invoice'
      handleInput(e);
      return false;
    }
    else if (!SubList.sub_inv_date) {
      SubList.error_list = 'fill  Date'
      handleInput(e);
      return false;
    }
    else if (!edit_vendor_name) {
      SubList.error_list = 'fill vendor name'
      handleInput(e);
      return false;
    }
    else if (!SubList.refernce_no) {
      SubList.error_list = 'fill refernce no'
      handleInput(e);
      return false;
    }
    else if (!SubList.amount) {
      SubList.error_list = 'fill amount'
      handleInput(e);
      return false;
    }
    else if (!SubList.exp_type) {
      SubList.error_list = 'fill expenses type'
      handleInput(e);
      return false;
    }

    

    else {

      SubList.error_list = ''


      handleInput(e);

      const data = {

        site_entry_no: document.getElementById('invoice_no').value,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        
        category:'Invoice',
        inv_no: SubList.sub_inv_no,
        inv_date: SubList.sub_inv_date,
        vendor_name: document.getElementById('vendor_name').value,
        reference_no: SubList.refernce_no,
        amount: SubList.amount,
        type_expenses: SubList.exp_type,
        remarks: SubList.remarks_site_sub,
        
        inv_for: SubList.inv_for,
        bill_upload: SubList.bill_upload

      }

      axios.post(`api/store-site`, data).then(res => {
        if (res.data.status === 200) {

          Getsublist(e);

          state(e);

          handleInput(e);




        }
        else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });
    }
  }

  const editsiteentrysub = (e) => {



    e.preventDefault();


    MainData.site_id = document.getElementById('s_id').value;

    MainData.courier_mode = document.getElementById('courier_mode').value;

    MainData.docket_no = document.getElementById('docket_no').value;
    MainData.form_wh = document.getElementById('form_wh').value;
    
    MainData.categoery = document.getElementById('categoery').value;


    if (MainData.courier_mode == 'By Hand') {

      MainData.by_hand = document.getElementById('by_hand').value;

    } else if (MainData.courier_mode == 'Courier') {
      MainData.courier_name = document.getElementById('c_id').value;
      MainData.by_hand = '';
    }

    var edit_inv_no = document.getElementById('sub_inv_no').value;
    var edit_sub_inv_date = document.getElementById('sub_inv_date').value;
    var edit_vendor_name = document.getElementById('vendor_name').value;
    var edit_refernce_no = document.getElementById('refernce_no').value;
    var edit_amount = document.getElementById('amount').value;
    var edit_exp_type = document.getElementById('exp_type').value;
    var edit_remarks = document.getElementById('remarks').value;
    //var edit_bill_upload=document.getElementById('bill_upload').value;
    
   
    SubList.sub_inv_no = edit_inv_no;
    SubList.sub_inv_date = edit_sub_inv_date;
    SubList.vendor_name = edit_vendor_name;
    SubList.refernce_no = edit_refernce_no;
    SubList.amount = edit_amount;
    SubList.exp_type = edit_exp_type;
    SubList.remarks_site_sub = edit_remarks;
    SubList.bill_upload = '';
   
    handleInput(e);


    if (MainData.courier_mode == 'By Hand') {

      if (!MainData.by_hand) {

        MainData.error_list.by_hand = 'Fill this filed';
        handleInput(e);
        return false;

      }

    }
    else if (MainData.courier_mode == 'Courier') {

      if (!MainData.courier_name) {

        MainData.error_list.courier_name = 'Select Courier Name';
        handleInput(e);
        return false;

      }

    }

    if (MainData.entry_date == '') {
      MainData.entry_date = defaultValue;
    }


    MainData.enter_by = user_id;



    if (!MainData.site_id) {
      swal('Select Site Name');

      return false;
    }

    else if (!MainData.courier_mode) {
      swal('Select Mode Name');

      return false;

    }

    else if (!MainData.docket_no) {
      swal('Fill  docket no');

      return false;

    }
    else if (!MainData.form_wh) {
      swal('Fill  From Whom');

      return false;

    }

    else if (!SubList.sub_inv_no) {
      SubList.error_list = 'fill invoice'
      handleInput(e);
      return false;
    }
    else if (!SubList.sub_inv_date) {
      SubList.error_list = 'fill  Date'
      handleInput(e);
      return false;
    }
    else if (!SubList.vendor_name) {
      SubList.error_list = 'fill vendor name'
      handleInput(e);
      return false;
    }
    else if (!SubList.refernce_no) {
      SubList.error_list = 'fill refernce no'
      handleInput(e);
      return false;
    }
    else if (!SubList.amount) {
      SubList.error_list = 'fill amount'
      handleInput(e);
      return false;
    }
    else if (!SubList.exp_type) {
      SubList.error_list = 'fill expenses type'
      handleInput(e);
      return false;
    }

   
    else {

      SubList.error_list = ''


      handleInput(e);

      const data = {

        site_entry_no: document.getElementById('invoice_no').value,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        
        category:'Invoice',
        inv_no: SubList.sub_inv_no,
        inv_date: SubList.sub_inv_date,
        vendor_name: SubList.vendor_name,
        reference_no: SubList.refernce_no,
        amount: SubList.amount,
        type_expenses: SubList.exp_type,
        remarks: SubList.remarks_site_sub,
        
        bill_upload: SubList.bill_upload,
        inv_for: SubList.inv_for,
        edit_id: SubList.edit_id,
      }


      axios.post(`api/edit-site`, data).then(res => {
        if (res.data.status === 200) {
     
          Getsublist(e);

          button = <button type="submit" className="btn btn-primary px-4 float-end" onClick={submitsitentrysub}><span>Add</span></button>;
          setbutton(button);
          state(e);

          handleInput(e);
         






        }
        else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });







    }



  }

  const sub_del = (e, id) => {
    e.preventDefault();
    axios.get(`/api/det-subsite/${id}`).then(res => {
      if (res.data.status === 200) {
        Getsublist(e);

      }
    });
  }

  const getvendor = (e) => {
    handleInput(e);
    
    var get_inv = document.getElementById('inv_for').value;
   var id_site = document.getElementById('s_id').value;
    const data = {


      site_id: id_site,
      invoice_for: get_inv,
    }

    axios.post(`/api/get-vendor`, data).then(res => {
      if (res.data.status === 200) {
        Getsublist(e);
        setvendor(res.data.site_party);

      }
      else if (res.data.status === 400) {
        swal('No Vendors In This Site');
      }
    });
  }
  var option = '';
  const vendor_options = showvendor.map((option, index) => {
    return { label: option.party_name, value: option.id, key: option.id }
  })


  // function for *getref_no* geting refernce number for relavent vendor 


  const getref_no = (option) => {



    // setSubList({ ...SubList, "vendor_name": option.value });
    // setvendor_name_choosen(option.value);

    // document.getElementById("vendor_name").value=SubList.vendor_name;
    var ven1='';
    var get_inv = document.getElementById('inv_for').value;
    
    var ven_edit=document.getElementById('edited').value;
   
    
    if(ven_edit!=''){
      var vend = document.getElementById('vendor_name').value;
      setSubList({ ...SubList, "vendor_name": vend });
    }
    else{
      setvendor_name_choosen(option.value);
      var vend = option.value;
      
      setSubList({ ...SubList, "vendor_name": vend });
    }
     
      
    
   
    
    var site_id = document.getElementById('s_id').value;
    const data = {
      site_id: site_id,
      vendor_id: vend,
      invoice_for: get_inv,
    }
 

    axios.post(`/api/get-ref_no`, data).then(res => {
      if (res.data.status === 200) {
        setrefno(res.data.ref_no);
      }
      else if (res.data.status === 400) {
        swal('No Refernce Number For This Vendor');
      }
    });


  }


  var REFNO = "";
  REFNO =
    showrefno.map((item, index) => {
      return (
        <option key={item.reference_no} value={item.reference_no}>
          {item.reference_no}
        </option>


      )
    });

  const getemployee = (e) => {

    



   

  }

  var HANDOVER = "";
  HANDOVER =
    showemployee.map((item, index) => {
      return (
        <option key={item.id} value={item.id}>
          {item.employee_name}/ {item.div_name}
        </option>


      )
    });


   const handover_options = showemployee.map((option, index) => {
    return { label: option.employee_name + '/' + option.designation_name, value: option.user_id_a, key: option.user_id_a }
  })



  const edit_sub = (e, id) => {

    e.preventDefault();

    axios.get(`/api/get-subsite/${id}`).then(res => {
     
      if (res.data.status === 200) {





        EDITVIST = res.data.status;
      
        SubList.sub_inv_no = res.data.inv_no;
        SubList.sub_inv_date = res.data.inv_date;
        SubList.inv_for = res.data.inv_for;
        

        setvendor_name_choosen(res.data.vendor_name);
        setedited(res.data.vendor_name);
       
        // setTimeout(() => {
         
        SubList.vendor_name = res.data.vendor_name;
         
        // }, 3000);
        SubList.refernce_no = res.data.reference_no;
        SubList.amount = res.data.amount;
        SubList.exp_type = res.data.type_expenses;
        SubList.remarks_site_sub = res.data.remarks;
        SubList.bill_upload = res.data.bill_upload;
       
        SubList.edit_id = res.data.id
        handleInput(e);
        if (EDITVIST == 200) {

          button = <button type="submit" className="btn btn-
           px-4 float-end" onClick={editsiteentrysub}><span>Edit</span></button>;
        }
        setbutton(button);
      }

    });
    handleInput(e);


     setTimeout(() => {
      getvendor(e);
      getref_no(e);

    }, 1500);

    handleInput(e);
  }
  const inv_table = [
    { id: 'Purchase Order', name: 'Purchase Order' },
    { id: 'Work Order', name: 'work_order_main' },
    { id: 'Direct Purchase', name: 'Direct Purchase' },
    { id: 'GRN', name: 'GRN' },
    { id: 'Other Expenses', name: 'Other Expenses' },
    { id: 'Transporter', name: 'Transporter' },
    { id: 'Sales Order', name: 'Sales Order' },
    { id: 'DC', name: 'DC' },

  ];
  var INVTABLE = "";
  INVTABLE =
    inv_table.map((item, index) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>


      )
    });
  
  return (
    <form calssName='form-horizontal' id="INVOICE">
      <span style={{ color: 'red' }} >{SubList.error_list}</span>
      <div className="table-hdbox">
        <div className="icon">
          <i className="fa-solid fa-file-invoice-dollar"></i>
        </div>
        <div className=""><h6>INVOICE</h6></div>
      </div>
        <div className="table-responsive">
          <table className="table display" cellSpacing="0">
            <thead>
              <tr>
                <th>#S.No</th>

                <th>Inv NO</th>
                <th>Inv Date </th>
                <th>Inv For</th>
                <th>Vendor Name</th>

                <th>Refernce No</th>
                <th>Amount</th>

                <th>Type of Expenses</th>
                <th>Remarks</th>
                <th>bill Upload</th>
                
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#
                </td>
                <td> <input type="text" name="sub_inv_no" id="sub_inv_no" onChange={handleInput} value={SubList.sub_inv_no} className="form-invsbcontrol" />
                </td>
                <td> <input type="date" name="sub_inv_date" id="sub_inv_date" onChange={handleInput} value={SubList.sub_inv_date} className="form-invsbcontrol" />
                </td>
                <td> <select type="text" name="inv_for" id="inv_for" className="form-invsbcontrol" onChange={getvendor} value={SubList.inv_for} defaultValue={SubList.inv_for} >
                  <option key="" value="">
                    Select
                  </option>
                  {INVTABLE}
                </select></td>
                <td>
                  <input type="hidden" value={vendor_name_choosen} id="vendor_name" />
                  <input type="hidden" value={edited} id="edited" />
                  <Select
                    name="vendor_name"

                    placeholder='Select Vendor...'

                    isSearchable
                    value={vendor_options.filter(vendor_options => 
                      vendor_options.value == SubList.vendor_name)}
      
                  
                    key={vendor_options.key}
                    options={vendor_options}
                    onChange={(option) => getref_no(option)}


                  />













                </td>
                <td>

                  <select type="hidden" name="refernce_no" id="refernce_no" className="form-invsbcontrol" onChange={handleInput} value={SubList.refernce_no} defaultValue={SubList.refernce_no}  >
                    <option key="" value="">
                      Select
                    </option>
                    {REFNO}
                  </select>
                </td>







                <td> <input type="text" name="amount" id="amount" onChange={handleInput} value={SubList.amount} className="form-invsbcontrol" />
                </td>
                <td> <input type="text" name="exp_type" id="exp_type" onChange={handleInput} value={SubList.exp_type} className="form-invsbcontrol" />
                </td>
                <td> <input type="text" name="remarks" id="remarks" onChange={handleInput} Value={SubList.remarks_site_sub} className="form-invsbcontrol" />
                </td>
                
                <td>
                  

                </td>

                <td className="td-flex">
                  {getbutton}

                </td>
              </tr>

              {list}
            </tbody>
          </table>
        </div>
    </form>

  );

}

export default SiteSublist;