import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

function Courier() {

    const [courierInput, setCourier] = useState({
        
        courier_mode_name: '',
       
       remarks: '',
        status: 1,
        error_list: [],
    });
    
    const handleInput = (e) => {
        e.persist();
        setCourier({...courierInput, [e.target.name]: e.target.value })
    }

    const submitCourier = (e) => {
        e.preventDefault();
var s=0;
if(courierInput.status==="1")
{
s=1;
}
// alert(courierInput.descrip);
        const data = {
           
        courier_mode_name: courierInput.courier_mode_name,
       
            remarks:courierInput.remarks,
            status:s
          
        }

        axios.post(`api/store-couriermode`, data).then(res => {
            if(res.data.status === 200)
            {
                e.target.reset();
                swal("Success",res.data.message,"success");
                window.location.href="/admin/view-couriermode"
                // document.getElementById('CATEGORY_FORM').reset();
            }
            else if(res.data.status === 400)
            {
                setCourier({...courierInput, error_list:res.data.errors});
            }
        });

    }

    var display_errors = [];
    if(courierInput.error_list)
    {
        display_errors = [
            courierInput.error_list.courier_mode_name,
         courierInput.error_list.status
        ]
    }

    return  (

        <div className="CourierMode">
            <div className="container-fluid px-4">

                {
                    display_errors.map( (item,index) => {
                       return( <p className="mb-1" key={index}>{item}</p>)
                    })
                }

                <div className="card mt-4">
                    <div className='card-icon'>
                        <i className="fa-solid fa-file-lines" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Add Courier Company </h4>
                        </div>
                        <div>
                            <Link to="/admin/view-couriermode" className="btn btn-primary btn-sm float-end">View Courier Mode</Link>
                        </div>
                    </div>
                    <div className="card-body">

                        <form onSubmit={submitCourier} id="COURIER_FORM">                                                       
                                <div className="card-body">

                                    <div className="form-group mb-3">
                                        <label>Courier Mode Name</label>
                                        <input type="text" name="courier_mode_name" onChange={handleInput} value={courierInput.courier_mode_name} className="form-control" />
                                        <span>{courierInput.error_list.courier_mode_name}</span>
                                    </div>
                                  

                                    <div className="form-group mb-3">
                                        <label>Remarks</label>
                                        <textarea name="remarks" onChange={handleInput} defaultValue={courierInput.remarks} className="form-control"></textarea>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Status</label>&nbsp;&nbsp;&nbsp;
                                        <input type="checkbox" name="status" onChange={handleInput} value={courierInput.status} />
                                    </div>

                                </div>
                             
                          
                            <button type="submit" className="btn btn-primary px-4 float-end">Submit</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courier;