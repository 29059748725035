import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import HoSublist from "./HoSublist";
import PettyCash from "./PettyCash";
import OtherSub, { Getsublist } from "./OtherSub";
import AllCatsub from "./AllCatsub";
import Datetime from "react-datetime";
import Select from "react-select";
import { motion } from "framer-motion";
function CreateHo() {
  const styles = {
    site: {
      width: "100px",
    },
  };
  // variable asn
  var ho_number = "";
  var viewsite_HTMLTABLE = "";
  var subpage = "";
  var sublist = "";
  var COURIER_FROM_CO = "";
  var COURIER_FROM_HB = "";
  // SESSION user geting
  const token2 = localStorage.getItem("auth_token");
  const user_name = localStorage.getItem("auth_name");
  const user_id = localStorage.getItem("rat_id");
  var user = null;
  if (token2) user = user_name;
  else user = null;
  const [show_co, set_co] = useState(true);
  const [show_bh, set_bh] = useState(false);
  const [courierInput, setCourier] = useState({
    invoice_no: "",
    site_id: "",
    courier_mode: "",
    entry_date: "",
    courier_name: "",
    docket_no: "",
    form_wh: "",
    date_time: "",
    by_hand: "",
    enter_by: "",
    recived_by: "",
    categoery: "",
    error_list: [],
  });
  // setting usestate
  const [options, setOptions] = useState([]);
  const [HOENTRY, setHOENTRY] = useState([]);
  const [courierlist, setCourierlist] = useState([]);
  const [Showsub, setshowsub] = useState([]);
  const [Show, setshow] = useState([]);
  const [GetOthers, setOthers] = useState([]);
  const [GetPettycash, setPettycash] = useState([]);
  const [couriermode, setCouriermodelist] = useState([]);
  const [sitelist, setsitelist] = useState([]);
  const [courier_id, setcourier_id] = useState([]);
  const [s_id, sets_id] = useState([]);
  var def = "Courier";
  // const cat = [
  //     {id: 'Invoice', name: 'Invoice'},
  //     {id: 'Letter', name: 'Letter'},
  //     {id: 'Invitation', name: 'Invitation'},
  //     {id: 'Claim', name: 'Claim'},
  //     {id: 'Others', name: 'Others'},
  //     {id: 'PettyCash', name: 'PettyCash'},
  //   ];

  // category drop dwon and maping
  const cat = [
    { id: "Invoice", name: "Invoice" },
    { id: "Others", name: "Letter / Invitation / Claim / Others" },
    { id: "PettyCash", name: "PettyCash" },
  ];

  var Category = "";
  Category = cat.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
  // courier name drop dwond and maping

  useEffect(() => {
    let isMounted = true;
    courierInput.courier_mode = "Courier";
    axios.get(`/api/view-courier`).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setCourierlist(res.data.courier);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  var viewcourier_HTMLTABLE = "";
  viewcourier_HTMLTABLE = courierlist.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.company_name}
      </option>
    );
  });
  const [courierlist_choosen, setCourierlist_choosen] = useState([]);
  const handleSelect3 = (option) => {
    setCourierlist_choosen(option.value);
    setCourier({ ...courierInput, courier_name: option.value });
    setcourier_id(option.value);
  };

  // couriermode drop dwon and maping

  const courier_mode = [
    { id: "Courier", name: "Courier" },
    { id: "By Hand", name: "By Hand" },
  ];
  var viewcouriermode_HTMLTABLE = "";

  viewcouriermode_HTMLTABLE = courier_mode.map((item, index) => {
    return (
      <option key={item.id} value={item.id} selected>
        {item.name}
      </option>
    );
  });

  const courier_from = (e) => {
    handleInput(e);
    var mode_type = document.getElementById("courier_mode").value;
    handleInput(e);
    if (mode_type == "By Hand") {
      set_bh(true);
      set_co(false);
      courierInput.courier_name = "";
      handleInput(e);
    } else {
      set_bh(false);
      set_co(true);
      courierInput.by_hand = "";
      handleInput(e);
    }
  };

  //site drop down and maping

  useEffect(() => {
    let isMount1 = true;

    axios.get(`/api/view-site`).then((res) => {
      if (isMount1) {
        if (res.status === 200) {
          setsitelist(res.data.site);
        }
      }
    });
    return () => {
      isMount1 = false;
    };
  }, []);

  const courier_options = courierlist.map((option, index) => {
    return { label: option.company_name, value: option.id, key: option.id };
  });
  const site_options = sitelist.map((option, index) => {
    return { label: option.site_name, value: option.id, key: option.id };
  });

  //this function use to geting invoice from passing value to laravel and get bvalue from api
  //  this function works after selecting site name passing value gets INVOICE NUMBER FROM LARAVEL
  const [siteId, setsiteId] = useState("");
  const Get_invoice = (option) => {
    // handleInput(e);

    // var selectElement = e.target;
    // swal(selectElement);
    // var value = selectElement.value;
    var site_id = option.value;

    axios.get(`/api/get-inv/${site_id}`).then((res) => {
      ho_number = res.data.invoice_no;

      setHOENTRY(ho_number);
    });

    setCourier({ ...courierInput, site_id: option.value });
    sets_id(option.value);
  };

  //current date dispay
  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  // showing sublist while selecting then categorys
  const ShowSublist = (e) => {
    handleInput(e);

    var selectElement = e.target;
    var value = selectElement.value;

    var get_categoery = value;
    courierInput.categoery = get_categoery;
    // e.preventDefault();

    if (courierInput.invoice_no != "") {
      courierInput.error_list.categoery = "";
      handleInput(e);
      if (get_categoery == "Invoice") {
        subpage = <HoSublist MainData={courierInput} />;
        sublist = <AllCatsub MainData={courierInput} />;
      } else if (get_categoery == "") {
        subpage = "";
        sublist = <AllCatsub MainData={courierInput} />;
      } else if (get_categoery == "PettyCash") {
        subpage = <PettyCash MainData={courierInput} />;
        sublist = <AllCatsub MainData={courierInput} />;
      } else {
        subpage = <OtherSub MainData={courierInput} />;
        sublist = <AllCatsub MainData={courierInput} />;
      }
      setshow(sublist);
      setshowsub(subpage);
      // else if(get_categoery=='Letter'){

      //     subpage=<OtherSub MainData={courierInput} />;

      // }
      // else if(get_categoery=='Invitation'){
      //     subpage=<OtherSub MainData={courierInput} />;

      // }
      // else if(get_categoery=='Claim'){
      //     subpage=<OtherSub MainData={courierInput} />;

      // }
      // else if(get_categoery=='Others'){
      //     subpage=<OtherSub MainData={courierInput} />;

      // }
    } else {
      courierInput.error_list.categoery = "Select Site Name";
      document.getElementById("categoery").value = "";
      handleInput(e);
    }
  };

  //store each changes in the form
  const handleInput = (e) => {
    e.persist();
    setCourier({ ...courierInput, [e.target.name]: e.target.value });
  };
  // submit button function with validation and send data to laravel and store the datas
  const submithoentry = (e) => {
    e.preventDefault();

    if (courierInput.entry_date == "") {
      courierInput.entry_date = defaultValue;
    }
    swal(courierInput.courier_mode);

    courierInput.enter_by = user;

    courierInput.site_id = document.getElementById("s_id").value;

    if (courierInput.courier_mode == "Courier") {
      if (!courierInput.courier_name) {
        courierInput.error_list.courier_name = "Select Courier Name";
        handleInput(e);
        return false;
      }
    } else if (courierInput.courier_mode == "By Hand") {
      if (!courierInput.by_hand) {
        courierInput.error_list.by_hand = "Fill this filed";
        handleInput(e);
        return false;
      }
    }

    if (!courierInput.site_id) {
      courierInput.error_list.site_id = "Select Site Name";
      handleInput(e);
      return false;
    } else if (!courierInput.courier_mode) {
      courierInput.error_list.courier_mode = "Select Mode Name";
      handleInput(e);
      return false;
    } else if (!courierInput.docket_no) {
      courierInput.error_list.docket_no = "Fill this filed";
      handleInput(e);
      return false;
    } else if (!courierInput.form_wh) {
      courierInput.error_list.form_wh = "Fill this filed";
      handleInput(e);
      return false;
    } else if (!courierInput.recived_by) {
      courierInput.error_list.recived_by = "Fill this filed";
      handleInput(e);
      return false;
    } else {
      courierInput.error_list.site_id = "";
      courierInput.error_list.courier_name = "";
      courierInput.error_list.courier_mode = "";
      courierInput.error_list.by_hand = "";
      courierInput.error_list.docket_no = "";
      courierInput.error_list.form_wh = "";
      courierInput.error_list.recived_by = "";
      courierInput.error_list.categoery = "";
      handleInput(e);

      const data = {
        ho_entry_no: HOENTRY,
        site_id: courierInput.site_id,
        entry_date: courierInput.entry_date,
        courier_id: courierInput.courier_name,
        courier_mode_id: courierInput.courier_mode,
        by_hand: courierInput.by_hand,
        docket_no: courierInput.docket_no,
        from_wh: courierInput.form_wh,
        date_time: courierInput.date_time,
        entry_by: user_id,
        recived_by: courierInput.recived_by,
        category: courierInput.categoery,
      };

      axios.post(`api/store-homain`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success", res.data.message, "success");
          window.location.href = "/admin/admin-ho";
          // document.getElementById('CATEGORY_FORM').reset();
        } else if (res.data.status === 400) {
          setCourier({ ...courierInput, error_list: res.data.errors });
        }
      });
    }
  };

  var display_errors = [];
  if (courierInput.error_list) {
    display_errors = [
      courierInput.error_list.company_name,

      courierInput.error_list.status,
    ];
  }

  return (
    <div className="CreateHo">
      <div className="container-fluid px-4">
        {display_errors.map((item, index) => {
          return (
            <p className="mb-1" key={index}>
              {item}
            </p>
          );
        })}

        <motion.div className="card mt-4" 
          initial={{ opacity: 0}} 
          animate={{ opacity: 1 }} 
          transition={{ type: 'spring', stiffness: 50}}>
          <div className="card-icon">
            <i className="fa-solid fa-file-lines" />
          </div>
          <div className="card-header">
            <div>
              <h4>Add Ho Entry</h4>
            </div>
            <div>
              <Link
                to="/admin/admin-ho"
                className="btn btn-primary btn-sm float-end"
              >
                View Entries
              </Link>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form-horizontal "
              onSubmit={submithoentry}
              id="HO_FORM"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .1, type:'spring', stiffness: 180}}>
                      <div className=" mb-4">
                        <label>Tracker Form No :</label>
                        <p
                          style={{ color: "red" }}
                          id="inv_ho"
                          onChange={handleInput}
                        >
                          {HOENTRY}
                        </p>
                        <input
                          type="hidden"
                          name="invoice_no"
                          id="invoice_no"
                          onChange={handleInput}
                          defaultValue={(courierInput.invoice_no = HOENTRY)}
                          className="form-control"
                        />
                      </div>
                    </motion.div>
                    <motion.div className="form-group mb-3 " style={styles.app} 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .2, type:'spring', stiffness: 180}}>
                      <input type="hidden" id="s_id" value={s_id} />

                      <label>Site Name</label>

                      <Select
                        id="site_id"
                        name="site_id"
                        placeholder="Select Site..."
                        isSearchable
                        value={options.label}
                        key={options.key}
                        options={site_options}
                        onChange={(option) => Get_invoice(option)}
                      />
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.site_id}
                      </span>
                    </motion.div>                    
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .3, type:'spring', stiffness: 180}}>
                      <label>Docket No</label>
                      <input
                        type="text"
                        name="docket_no"
                        id="docket_no"
                        onChange={handleInput}
                        value={courierInput.docket_no}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.docket_no}
                      </span>
                    </motion.div>
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .4, type:'spring', stiffness: 180}}>
                      <label>Enter By </label>
                      <input
                        type="text"
                        name="enter_by"
                        id="enter_by"
                        onChange={handleInput}
                        className="form-control"
                        defaultValue={user}
                        disabled
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.enter_by}
                      </span>
                    </motion.div>                                       
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <div className="form-group mb-3 visibility">
                      <label>Date</label>
                      <input
                        type="date"
                        name="entry_date"
                        id="entry_date"
                        onChange={handleInput}
                        defaultValue={defaultValue}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.entry_date}
                      </span>
                    </div>                    
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .2, type:'spring', stiffness: 180}}>
                      <label>Courier Mode</label>

                      <select
                        type="text"
                        name="courier_mode"
                        id="courier_mode"
                        onChange={courier_from}
                        value={courierInput.courier_mode}
                        defaultValue={"Courier"}
                        className="form-control"
                      >
                        {viewcouriermode_HTMLTABLE}
                      </select>
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.courier_mode}
                      </span>
                    </motion.div>
                    {/* <div className="form-group mb-3">
                      <label>Category</label>

                      <select
                        type="text"
                        name="categoery"
                        id="categoery"
                        onChange={(handleInput, ShowSublist)}
                        value={courierInput.categoery}
                        className="form-control"
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {Category}
                      </select>
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.categoery}
                      </span>
                    </div> */}
                     <motion.div className="form-group mb-3" 
                      initial={{y:80, opacity: 0}} 
                      animate={{y:0, opacity: 1}}    
                      transition={{ delay: .3, type:'spring', stiffness: 180}}>
                      <label>From </label>
                      <input
                        type="text"
                        name="form_wh"
                        id="form_wh"
                        onChange={handleInput}
                        value={courierInput.form_wh}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.form_wh}
                      </span>
                    </motion.div>
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .4, type:'spring', stiffness: 180}}>
                      <label>Recived By</label>
                      <input
                        type="text"
                        name="recived_by"
                        id="recived_by"
                        onChange={handleInput}
                        value={courierInput.recived_by}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.recived_by}
                      </span>
                    </motion.div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .1, type:'spring', stiffness: 180}} 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .1, type:'spring', stiffness: 180}}>
                      <label>Date</label>
                      <input
                        type="date"
                        name="entry_date"
                        id="entry_date"
                        onChange={handleInput}
                        defaultValue={defaultValue}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.entry_date}
                      </span>
                    </motion.div>                    
                    {show_co ? (
                      <motion.div className="form-group mb-3" 
                      initial={{y:80, opacity: 0}} 
                      animate={{y:0, opacity: 1}}    
                      transition={{ delay: .2, type:'spring', stiffness: 180}}>
                        <input type="hidden" id="c_id" value={courier_id} />
                        <label>Courier Name</label>

                        <Select
                          name="courier_name"
                          id="courier_name"
                          placeholder="Select Courier..."
                          isSearchable
                          value={options.label}
                          key={options.key}
                          options={courier_options}
                          onChange={(option) => handleSelect3(option)}
                        />

                        <span style={{ color: "red" }}>
                          {courierInput.error_list.courier_name}
                        </span>
                      </motion.div>
                    ) : null}
                    {show_bh ? (
                      <motion.div className="form-group mb-3" 
                      initial={{y:80, opacity: 0}} 
                      animate={{y:0, opacity: 1}}    
                      transition={{ delay: .3, type:'spring', stiffness: 180}}>
                        <label>By Hand</label>
                        <input
                          type="text"
                          name="by_hand"
                          id="by_hand"
                          onChange={handleInput}
                          value={courierInput.by_hand}
                          className="form-control"
                        />

                        <span style={{ color: "red" }}>
                          {courierInput.error_list.by_hand}
                        </span>
                      </motion.div>
                    ) : null}
                    <motion.div className="form-group mb-3" 
                     initial={{y:80, opacity: 0}} 
                     animate={{y:0, opacity: 1}}    
                     transition={{ delay: .4, type:'spring', stiffness: 180}}>
                      <label>Date &Time</label>
                      <input
                        type="datetime-local"
                        name="date_time"
                        id="date_time"
                        onChange={handleInput}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.date_time}
                      </span>
                    </motion.div>                                                          
                  </div>
                </div>
              </div>
            </form>

            <div className="container-fluid px-4 card-gap">
              <HoSublist MainData={courierInput} />
            </div>

            <div className="container-fluid px-4 card-gap" data-aos="fade-up" data-aos-offset="100" data-aos-delay="200">
              <PettyCash MainData={courierInput} />
            </div>
            <div className="container-fluid px-4 card-gap" data-aos="fade-up" data-aos-offset="100" data-aos-delay="300">
              <OtherSub MainData={courierInput} />
            </div>

            <div className="btn-align">
              <button
                data-aos="zoom-in" data-aos-offset="100" data-aos-delay="400"
                type="submit"
                onClick={submithoentry}
                className="btn btn-primary px-4 float-end mt-3"
              >
                Submit
              </button>
            </div>
          </div>
        </motion.div>

        {Show}
      </div>
    </div>
  );
}

export default CreateHo;
