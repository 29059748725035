import React from "react";
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const EditableRow2 = ({
  editFormData2,
  handleEditFormChange2,
  handleCancelClick2,
  ChangeImage,
  deleteFile2,
  show,
  handleClose,
  handleShow2,
  uploadSingleeditFile
}) => {

  return (
    <tr>
      <td>
       <input
          type="hidden"
          name="entry_no"
          required="required"
          placeholder="Entry No"
           value={editFormData2.entry_no}
          onChange={handleEditFormChange2}
        />
    
       <input
          type="hidden"
          name="category"
          required="required"
          value={editFormData2.category}
          disabled="disabled"
          placeholder="Invoice"
          className="form-control"
          onChange={handleEditFormChange2}
        />
    
     
         <input
          type="text"
          name="inv_no"
          value={editFormData2.inv_no}
          className="form-control"
          required="required"
          placeholder="Inv No"
          onChange={handleEditFormChange2}
        />
      </td>
      <td>
         <input
          type="date"
          name="inv_date"
          value={editFormData2.inv_date}
          className="form-control"
          required="required"
          placeholder="Inv Date"
          onChange={handleEditFormChange2}
        />
      </td>
      <td>
      <input
          type="text"
          name="vendor_name"
          value={editFormData2.vendor_name}
          className="form-control"
          required="required"
          placeholder="Vendor Name"
          onChange={handleEditFormChange2}
        />
      </td>
      <td>
       <input
          type="text"
          name="ref_no"
          value={editFormData2.ref_no}
          className="form-control"
          required="required"
          placeholder="Reference No."
          onChange={handleEditFormChange2}
        />
      </td>
       <td>
      <input
          type="text"
          name="amntrs"
          value={editFormData2.amntrs}
          className="form-control"
          required="required"
          placeholder="Amount Rs."
          onChange={handleEditFormChange2}
        />
      </td>
      <td>
      <input
          type="text"
          name="exp"
          value={editFormData2.exp}
          className="form-control"
          required="required"
          placeholder="Expenses Type"
          onChange={handleEditFormChange2}
        />
      </td>
      <td>
        <input
          type="text"
          name="remarks"
          value={editFormData2.remarks}
          className="form-control"
          required="required"
          placeholder="Remarks"
          onChange={handleEditFormChange2}
        />
      </td>
       <td>

        {(editFormData2.bills_upl).split(",").length > 0 && 
          (editFormData2.bills_upl).split(",").map((item, index) => {
            return (
              <div key={item}>
                <img src={item} alt="" className="imageprev"/>
              </div>
            );
          })}
          <Button variant="primary" onClick={handleShow2}>
       Change
      </Button>
       <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Images</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
              <div className="row g-3" >
        <div className="col-md-12">

<input
          type="file"
          name="bills_upl"
          className="form-control"         
          placeholder="Bills Upload"
          onChange={uploadSingleeditFile}

        />
        </div>
        
      <div className="col-md-12">
      <table>
      <tbody>

  {

        (editFormData2.bills_upl).split(',').length > 0 &&
          (editFormData2.bills_upl).split(',').map((item, index) => {
            return (
            <tr>
                    <td>{item}</td>
                    <td>
<button type="button" className="btn btn-danger" onClick={() => deleteFile2(index)}>
                  delete
                </button>

                    </td>
                </tr>
            );
          })
 
          }
      
      </tbody>
</table>
      </div>
      </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        
      </td>
      <td>
        <button type="submit" className="btn btn-success">Save</button>
        <button type="button" className="btn btn-danger" onClick={handleCancelClick2}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow2;