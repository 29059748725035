import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Select from "react-select";

import { faHome, faArrowsUpDown, faEdit, faDeleteLeft, faTrash, faHandshakeSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTable, { ExpanderComponentProps } from 'react-data-table-component';


function AdminHo() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [Holist, setHolist] = useState([]);
    var Data = '';
    var sn = 0;
    var no = '';
    const sortIcon = <FontAwesomeIcon icon={faArrowsUpDown} />;
    const deleteHo = (e, id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting";

        axios.get(`/api/delete-Ho/${id}`).then(res => {
            if (res.data.status === 200) {
                swal("Success", res.data.message, "success");

                window.location.reload();
            }
            else if (res.data.status === 404) {
                swal("Success", res.data.message, "success");
                thisClicked.innerText = "Delete";
                window.location.reload();
            }
        });

    }


    //list fliter function 
    const [list, setlist] = useState({
        from_date: "",
        to_date: "",
        site_id: "",

        error_list: [],
    });
    // Created by Brindha on 27.01.2023 to refresh page
    const [rec, setrec] = useState('fa-solid fa-envelope');

    const [sitelist, setsitelist] = useState([]);
    const [s_id, setsite_id] = useState([]);
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    useEffect(() => {

        axios.get(`/api/view-site`).then((res) => {

            if (res.status === 200) {
                setsitelist(res.data.site);
            }

        });


        viewMain('empty');

    }, []);

    const date = new Date();
    const defaultValue = date.toLocaleDateString("en-CA");

    const site_options = sitelist.map((option, index) => {
        return { label: option.site_name, value: option.id, key: option.id };
    });
    const handleInput = (e) => {
        e.persist();
        setlist({ ...list, [e.target.name]: e.target.value });
    };
    const handleSelect3 = (option) => {

        setlist({ ...list, site_id: option.value });
        setsite_id(option.value);
    };
    const viewMain = (status) => {
        if (status == 'fliter') {
            if (list.from_date == '' || list.from_date == null) {
                list.from_date = defaultValue;
            }
            if (list.to_date == '' || list.to_date == null) {
                list.to_date = defaultValue;
            }
        }

        const data = {
            from_date: list.from_date,
            to_date: list.to_date,
            site_id: list.site_id,
            status: status,
        };

        axios.post(`/api/view-entry`, data).then((res) => {
            if (res.status === 200) {
                setHolist(res.data.ho_entry)
                Data = res.data.ho_entry;
                setLoading(false);
            }
        });
    }

    const getList = (e) => {


        viewMain('fliter');


    }

    // end fliter function

    const data = "received";

    // const bySite = (id) => {
    //     console.log(id);
    //     axios.put(`/api/bySite/${id}`,data).then(res => {
    //         if (res.data.status === 200) {
    //             swal("Success", res.data.message, "success");             
    //         }
    //         else if (res.data.status === 404) {
    //             swal("Error",res.data.message,"error");              
    //         } 
    //         window.location.reload();
    //     });
    // }

    // const byHo = (ho_id) => {
    //     console.log(ho_id);
    //     axios.put(`/api/byHo/${ho_id}`,data).then(res => {
    //         if (res.data.status === 200) {
    //             swal("Success", res.data.message, "success");            
    //         }
    //         else if (res.data.status === 404) {
    //             swal("Error",res.data.message,"error");              
    //         } 
    //         window.location.reload();
    //     });
    // }

    // Created by Brindha on 25.01.2023 to change receive status  
    function bySite(e, id) {
        e.preventDefault();
        swal({
            title: 'Are you sure want to change the status?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // alert('test');
                axios.put(`/api/bySite/${id}`, data).then(res => {
                    if (res.data.status === 200) {
                        // alert(document.getElementByID('recenc'+id).innerHTML)
                        swal("Received!", res.data.message, "success");
                        // setrec('fa-solid fa-envelope-circle-check');

                        document.getElementById('recenc' + id).innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-circle-check" class="svg-inline--fa fa-envelope-circle-check editclass  btn-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L478.9 160.8C412.3 167.2 356.5 210.8 332.6 270.6L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM294.4 339.2L320.8 319.4C320.3 324.9 320 330.4 320 336C320 378.5 335.1 417.6 360.2 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2zM640 336C640 415.5 575.5 480 496 480C416.5 480 352 415.5 352 336C352 256.5 416.5 192 496 192C575.5 192 640 256.5 640 336zM540.7 292.7L480 353.4L451.3 324.7C445.1 318.4 434.9 318.4 428.7 324.7C422.4 330.9 422.4 341.1 428.7 347.3L468.7 387.3C474.9 393.6 485.1 393.6 491.3 387.3L563.3 315.3C569.6 309.1 569.6 298.9 563.3 292.7C557.1 286.4 546.9 286.4 540.7 292.7H540.7z"></path></svg>'
                        // const [rec, setrec] = useState('fa-solid fa-envelope');  
                        // window.location.reload();                       
                    }
                    else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    }

                });
            }
            else {
                swal("Cancelled", "Courier status has not changed :)", "error");
            }
        })
    }

    // Created by Brindha on 25.01.2023 to change receive status  
    function byHo(e, ho_id) {
        e.preventDefault();
        swal({
            title: 'Are you sure want to change the status?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // alert('test');
                axios.put(`/api/byHo/${ho_id}`, data).then(res => {
                    if (res.data.status === 200) {
                        // alert(document.getElementByID('recenc'+ho_id).innerHTML)
                        swal("Received!", res.data.message, "success");

                        // setrec('fa-solid fa-envelope-circle-check');
                        document.getElementById('recenc' + ho_id).innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-circle-check" class="svg-inline--fa fa-envelope-circle-check editclass  btn-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L478.9 160.8C412.3 167.2 356.5 210.8 332.6 270.6L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM294.4 339.2L320.8 319.4C320.3 324.9 320 330.4 320 336C320 378.5 335.1 417.6 360.2 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2zM640 336C640 415.5 575.5 480 496 480C416.5 480 352 415.5 352 336C352 256.5 416.5 192 496 192C575.5 192 640 256.5 640 336zM540.7 292.7L480 353.4L451.3 324.7C445.1 318.4 434.9 318.4 428.7 324.7C422.4 330.9 422.4 341.1 428.7 347.3L468.7 387.3C474.9 393.6 485.1 393.6 491.3 387.3L563.3 315.3C569.6 309.1 569.6 298.9 563.3 292.7C557.1 286.4 546.9 286.4 540.7 292.7H540.7z"></path></svg>'
                        // const [rec, setrec] = useState('fa-solid fa-envelope');     
                        // window.location.reload();                  
                    }
                    else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    }

                });
            }
            else {
                swal("Cancelled", "Courier status has not changed :)", "error");
            }
        })
    }

    const columns = [
        {
            name: 'S.NO',
            cell: (row, index) => index + 1,
            wrap: true,
        },
        {
            name: 'Entry Date',
            selector: row => row.entry_date,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Entry Number',
            selector: row => row.ho_entry_no,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Site Name',
            selector: row => row.site_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Courier name',
            selector: row => row.company_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Courier mode name',
            selector: row => row.courier_mode_id,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Invoice No',
            selector: row => row.reciver_acknowleged_status,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Action',
            selector: row =>
                row.reciver_acknowleged_status == 'received' ? (<FontAwesomeIcon className="editclass  btn-sm" icon="fa-solid fa-envelope-circle-check" />) : (
                    row.entry_type == 'site entry' ? (
                        <span id={"recenc" + row.id2}><button type="submit" className="editclass btn-sm" onClick={(e) => bySite(e, row.id2)}>
                            <FontAwesomeIcon icon="fa-solid fa-envelope" /></button></span>
                    ):(
                        <span id={"recenc" + row.id2}><button type="submit" className="editclass btn-sm" onClick={(e) => byHo(e, row.id2)}>
                            <FontAwesomeIcon icon="fa-solid fa-envelope" /></button></span>
                    )
                )
        },
    ];


    const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;`;


    const TextField = styled.input`
height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;`;
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <input
                id="search"
                type="text"
                autoFocus={true}
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                className="searchbox"
            />
            <ClearButton type="button" onClick={onClear} className="clearbtn">
                <i className='fas fa-close' />
            </ClearButton>
        </>
    );
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = Holist.filter(

        item => JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1

    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {


            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        const onFilterValue = (e) => {
            e.preventDefault();
            setFilterText(e.target.value)
            e.target.focus()

        }
        return (
            <FilterComponent onFilter={onFilterValue} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
        return (

            <table cellPadding="2" cellSpacing="0" border="0" className="InnerTable">
                <tbody>
                    <tr>
                        <td>Invoice For</td>
                        <td>:</td>
                        <td>{data.inv_for}</td>
                    </tr>
                    <tr>
                        <td>Vendor Name</td>
                        <td>:</td>
                        <td>{data.vendor_name}</td>
                    </tr>
                    <tr>
                        <td>Reference No</td>
                        <td>:</td>
                        <td>{data.reference_no}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td>:</td>
                        <td>{data.amount}</td>
                    </tr>
                    <tr>
                        <td>Type of Expenses</td>
                        <td>:</td>
                        <td>{data.type_expenses}</td>
                    </tr>
                    <tr>
                        <td>From Whom</td>
                        <td>:</td>
                        <td>{data.from_wh}</td>
                    </tr>
                    <tr>
                        <td>To Whom</td>
                        <td>:</td>
                        <td>{data.to_wh}</td>
                    </tr>
                    <tr>
                        <td>Remarks</td>
                        <td>:</td>
                        <td>{data.remarks}</td>
                    </tr>
                </tbody>
            </table>
        );;
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '28px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '28px',
            },
        },
    };
    // <Link to={`/admin/update-ho/${row.ho_id}`} className="btn btn-success btn-sm"> <FontAwesomeIcon icon={faEdit} /></Link>

    //https://react-data-table-component.netlify.app/?path=/docs/getting-started-patterns--page

    // const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    //     return (

    //         <p></p>

    //     );
    // };
    const [pending, setPending] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(Data);
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);



    return (
        <div className="Rec_Acknowledge">
            <div className="container px-4">
                <div className="card mt-4">
                    <div className="card-icon">
                        <i className="fa-solid fa-table-list" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Receiver Acknowledgement</h4>
                        </div>
                        <div>
                            {/* <Link to="/admin/by-ho/:id" className="btn btn-primary btn-sm float-end">Add +</Link> */}
                        </div>
                    </div>
                    <div className="card-body table-responsive">

                        <div className="side-gap">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                                    <div className="filter-form">
                                        <label>From Date :</label>
                                        <input
                                            type="date"
                                            name="from_date"
                                            id="from_date"
                                            onChange={handleInput}
                                            defaultValue={defaultValue}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                                    <div className="filter-form">
                                        <label>TO Date :</label>
                                        <input
                                            type="date"
                                            name="to_date"
                                            id="to_date"
                                            onChange={handleInput}
                                            defaultValue={defaultValue}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                                    <div className="filter-form sitenm">
                                        <input type="hidden" name="ss_id" id="ss_id" value={s_id} />
                                        <label>Site Name :</label>
                                        <Select
                                            id="site_id"
                                            name="site_id"
                                            placeholder="Select Site..."
                                            isSearchable
                                            value={site_options.label}
                                            key={site_options.key}
                                            options={site_options}
                                            onChange={(option) => handleSelect3(option)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-sm-12 col-12">
                                    <div className="filter-form btn">
                                        <button
                                            type="submit"
                                            onClick={getList}
                                            className=" px-4 float-end mt-3"
                                        >
                                            GO
                                        </button>
                                    </div>
                                </div>
                                {/* <button onClick={(e)=>exportToExcel(fileName)} color="primary" */}
                                {/* >ex</button> */}

                                {/* <ExportExcel excelData={Data} fileName={"Excel Export"} /> */}
                            </div>
                        </div>




                        <DataTable columns={columns} data={filteredItems}
                            pagination
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="500px"
                            highlightOnHover
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            // actions={actionsMemo}

                            persistTableHead
                            customStyles={customStyles}
                        />

                    </div>
                </div>
            </div>
        </div>
    );

}
export default AdminHo;

