import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import swal from 'sweetalert';

function Courier() {

    const [courierInput, setCourier] = useState({
        
        company_name: '',
        company_person_name: '',
        mobile_no:'',
        DOT:'',
        email:'',
        address:'',
        accno:'',
        acc_holder_name:'',
        ifsc_code:'',
        branch_name:'',   
        descrip: '',
        status: 1,
        error_list: [],
    });
    
    const handleInput = (e) => {
        e.persist();
        setCourier({...courierInput, [e.target.name]: e.target.value })
       
        console.log(e.target.value);
    }

    const submitCourier = (e) => {
        e.preventDefault();
var s=0;
if(courierInput.status==="1")
{
s=1;
}
// alert(courierInput.descrip);
        const data = {
           
        company_name: courierInput.company_name,
        company_person_name: courierInput.company_person_name,
        mobile_no:courierInput.mobile_no,
        DOT:courierInput.DOT,
        email:courierInput.email,
        address:courierInput.address,
        accno:courierInput.accno,
        acc_holder_name:courierInput.acc_holder_name,
        ifsc_code:courierInput.ifsc_code,
        branch_name:courierInput.branch_name,
            descrip:courierInput.descrip,
            status:s
          
        }

        axios.post(`api/store-courier`, data).then(res => {
            if(res.data.status === 200)
            {
                e.target.reset();
                swal("Success",res.data.message,"success");
                window.location.href="/admin/view-courier";
                // document.getElementById('CATEGORY_FORM').reset();
            }
            else if(res.data.status === 400)
            {
                setCourier({...courierInput, error_list:res.data.errors});
            }
        });

    }

    var display_errors = [];
    if(courierInput.error_list)
    {
        display_errors = [
            courierInput.error_list.company_name,
      
         courierInput.error_list.status
        ]
    }

    return  (
        <div className ="Courier">
            <div className="container-fluid px-4">

                {
                    display_errors.map( (item,index) => {
                       return( <p className="mb-1" key={index}>{item}</p>)
                    })
                }

                    <motion.div className="card mt-4"
                        initial={{ opacity: 0}} 
                        animate={{ opacity: 1 }} 
                        transition={{ type: 'spring', stiffness: 50}}>
                        <div className='card-icon' >
                            <i className="fa-solid fa-file-lines" />
                        </div>
                        <div className="card-header">
                            <div>
                                <h4 >Add Courier Company</h4>
                            </div>
                            <div>
                                <Link to="/admin/view-courier" className="btn btn-primary btn-sm float-end">View Courier</Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitCourier} id="COURIER_FORM">                                                            
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                                <motion.div className="form-group mb-3"
                                                    initial={{y:80, opacity: 0}} 
                                                    animate={{y:0, opacity: 1}}    
                                                    transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                                    <label>Courier Company Name</label>
                                                    <input type="text" name="company_name" onChange={handleInput} value={courierInput.company_name} className="form-control" />
                                                    <span>{courierInput.error_list.company_name}</span>
                                                     <div class="form-border"></div>
                                                </motion.div>
                                                <motion.div className="form-group mb-3"
                                                 initial={{y:80, opacity: 0}} 
                                                    animate={{y:0, opacity: 1}}    
                                                    transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                                    <label>DOT(Landline Number)</label>
                                                    <input type="text" name="DOT" onChange={handleInput} value={courierInput.DOT} className="form-control" />
                                                </motion.div> 
                                                <motion.div className="form-group mb-3" 
                                                 initial={{y:80, opacity: 0}} 
                                                 animate={{y:0, opacity: 1}}    
                                                 transition={{ delay: .3, type:'spring', stiffness: 180}}>
                                                    <label>Account Holder Name</label>
                                                    <input type="text" name="acc_holder_name" onChange={handleInput} value={courierInput.acc_holder_name} className="form-control" />
                                                </motion.div>                                                                                                                                                
                                                <motion.div className="form-group mb-3" 
                                                initial={{y:80, opacity: 0}} 
                                                animate={{y:0, opacity: 1}}    
                                                transition={{ delay: .4, type:'spring', stiffness: 180}}>
                                                    <label>Address</label>
                                                     <textarea name="address" onChange={handleInput} value={courierInput.address} className="form-control"></textarea>
                                                </motion.div>    
                                            </div>                                             
                                            <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                                <motion.div className="form-group mb-3"
                                                    initial={{y:80, opacity: 0}} 
                                                    animate={{y:0, opacity: 1}}    
                                                    transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                                    <label>Contact Person Name</label>
                                                    <input type="text" name="company_person_name" onChange={handleInput} value={courierInput.company_person_name} className="form-control" />
                                                </motion.div>
                                                <motion.div className="form-group mb-3"
                                                 initial={{y:80, opacity: 0}} 
                                                 animate={{y:0, opacity: 1}}    
                                                 transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                                    <label>Email</label>
                                                    <input type="email" name="email" onChange={handleInput} value={courierInput.email} className="form-control" />
                                                </motion.div>
                                                                                                                                      
                                                <motion.div className="form-group mb-3"
                                                 initial={{y:80, opacity: 0}} 
                                                 animate={{y:0, opacity: 1}}    
                                                 transition={{ delay: .3, type:'spring', stiffness: 180}}>
                                                    <label>Account Number</label>
                                                    <input type="text" name="accno" onChange={handleInput} value={courierInput.accno} className="form-control" />
                                                </motion.div>                                                
                                                <motion.div className="form-group mb-3" 
                                                initial={{y:80, opacity: 0}} 
                                                animate={{y:0, opacity: 1}}    
                                                transition={{ delay: .4, type:'spring', stiffness: 180}}>
                                                    <label>Description</label>
                                                    <textarea name="descrip" onChange={handleInput} defaultValue={courierInput.descrip} className="form-control"></textarea>
                                                </motion.div>
                                            </div>                                             
                                            <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                                                <motion.div className="form-group mb-3"
                                                    initial={{y:80, opacity: 0}} 
                                                    animate={{y:0, opacity: 1}}    
                                                    transition={{ delay: .1, type:'spring', stiffness: 180}}>
                                                    <label>Mobile Number</label>
                                                    <input type="text" name="mobile_no" onChange={handleInput} value={courierInput.mobile_no} className="form-control" />
                                                </motion.div>                                                
                                                <motion.div className="form-group mb-3"
                                                 initial={{y:80, opacity: 0}} 
                                                 animate={{y:0, opacity: 1}}    
                                                 transition={{ delay: .2, type:'spring', stiffness: 180}}>
                                                    <label>Branch Name</label>
                                                    <input type="text" name="branch_name" onChange={handleInput} value={courierInput.branch_name} className="form-control" />
                                                </motion.div>
                                                <motion.div className="form-group mb-3" 
                                                    initial={{y:80, opacity: 0}} 
                                                    animate={{y:0, opacity: 1}}    
                                                    transition={{ delay: .3, type:'spring', stiffness: 180}}>                                                
                                                    <label>IFSC Code</label>
                                                    <input type="text" name="ifsc_code" onChange={handleInput} value={courierInput.ifsc_code} className="form-control" />
                                                </motion.div>
                                                <motion.div className="form-group mb-3" 
                                                initial={{y:80, opacity: 0}} 
                                                animate={{y:0, opacity: 1}}    
                                                transition={{ delay: .4, type:'spring', stiffness: 180}}>
                                                    <label>Status</label>&nbsp;&nbsp;&nbsp;
                                                    <input type="checkbox" name="status" onChange={handleInput} value={courierInput.status} ></input>
                                                    
                                                </motion.div>
                                            </div>
                                        </div>                                                                            
                                    </div>       
                                    <div className='btn-align'>
                                        <button type="submit" className="btn btn-primary px-4 float-end">Submit</button>
                                    </div>                                                                                     
                            </form>
                        </div> 
                    </motion.div>
            </div>
        </div>
    )
}

export default Courier;