import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Datetime from 'react-datetime';
import CategoryAction from './CategoryAction';
const token2 = localStorage.getItem('auth_token');
const user_name = localStorage.getItem('auth_name');
function UpdateHo() {
    var user = null;
    if (token2)
        user = user_name;
    else
        user = null;
    console.log(useParams());
    const ho_id = useParams().id;
    var edit_site_id = '';
    var edit_categoery = '';
    var subpage = '';
    var ho_number = '';
    var sublist = "";
    const [Show, setshow] = useState([]);
    const [courierInput, setCourier] = useState({
        invoice_no: '',
        site_id: '',
        courier_mode: '',
        entry_date: '',
        courier_name: '',
        docket_no: '',
        form_wh: '',
        date_time: '',
        by_hand: '',
        enter_by: '',
        recived_by: '',
        categoery: '',
        error_list: [],
    });
    const [options, setOptions] = useState([]);
    const [HOENTRY, setHOENTRY] = useState([]);
    const [courierlist, setCourierlist] = useState([]);
    const [Showsub, setshowsub] = useState([]);
    const [select_site, setselect_site] = useState([]);
    const [select_courier, setselect_courier] = useState([]);
    const [select_courier_mode, setselect_courier_mode] = useState([]);
    const [select_catogery, setselect_catogery] = useState([]);
    const [couriermode, setCouriermodelist] = useState([]);
    const [sitelist, setsitelist] = useState([]);


    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/updateHo/${ho_id}`).then(res => {
            if (isMounted) {
                if (res.status === 200) {
                    setHOENTRY(res.data.ho_entry_no);
                    setselect_site(res.data.site_id);
                    courierInput.invoice_no = res.data.ho_entry_no;

                    setselect_courier(res.data.courier_id);
                    courierInput.entry_date = res.data.entry_date;
                    setselect_courier_mode(res.data.courier_mode_id);
                    courierInput.by_hand = res.data.by_hand;
                    courierInput.docket_no = res.data.docket_no;
                    courierInput.form_wh = res.data.from_wh;
                    courierInput.recived_by = res.data.recived_by;
                    courierInput.entry_by = res.data.entry_by;
                    

                    setselect_catogery(res.data.category);
                    edit_categoery = res.data.category;


                    
                   
                   
                    
                    
                    

                }
            }
        });
        return () => {
            isMounted = false
        };

    }, []);

    // const cat = [
    //     {id: 'Invoice', name: 'Invoice'},
    //     {id: 'Letter', name: 'Letter'},
    //     {id: 'Invitation', name: 'Invitation'},
    //     {id: 'Claim', name: 'Claim'},
    //     {id: 'Others', name: 'Others'},
    //     {id: 'PettyCash', name: 'PettyCash'},
    //   ];
    const cat = [
        { id: 'Invoice', name: 'Invoice' },
        { id: 'Others', name: 'Letter / Invitation / Claim / Others' },
        { id: 'PettyCash', name: 'PettyCash' },
    ];

    var Category = "";
    Category =
        cat.map((item, index) => {
            return (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>


            )
        });


    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/view-courier`).then(res => {
            if (isMounted) {
                if (res.status === 200) {
                    setCourierlist(res.data.courier)

                }
            }
        });
        return () => {
            isMounted = false
        };

    }, []);

    var viewcourier_HTMLTABLE = "";
    
        courierlist.map((item, index) => {

            if(select_courier==item.id){
             viewcourier_HTMLTABLE=   item.company_name
            }
            
        });




    useEffect(() => {
        let isMount = true;

        axios.get(`/api/view-couriermode`).then(res => {
            if (isMount) {
                if (res.status === 200) {
                    setCouriermodelist(res.data.courier)

                }
            }
        });
        return () => {
            isMount = false
        };

    }, []);

    var viewcouriermode_HTMLTABLE = "";
   
        couriermode.map((item, index) => {

            if(select_courier_mode==item.id){
                viewcouriermode_HTMLTABLE=item.courier_mode_name;
            }
            
        });



    useEffect(() => {
        let isMount1 = true;

        axios.get(`/api/view-site`).then(res => {

            if (isMount1) {

                if (res.status === 200) {
                    setsitelist(res.data.site)

                }
            }
        });
        return () => {
            isMount1 = false
        };

    }, []);

    var default_setect = {

        site: edit_site_id,

    };

    var viewsite_HTMLTABLE = "";
    var SiteName="";
   
        sitelist.map((item, index) => {
if(select_site==item.id){
        SiteName=item.site_name;
}
         
        
        });

    const Get_invoice = (e) => {

        handleInput(e);

        var selectElement = e.target;
        var value = selectElement.value;
        var site_id = value;

        axios.get(`/api/get-inv/${site_id}`).then(res => {
            ho_number = res.data.invoice_no;

            setHOENTRY(ho_number);



        });



    }




    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');

    const ShowSublist = (e) => {

        handleInput(e);

        var selectElement = e.target;
        var value = selectElement.value;

        var get_categoery = value;
        courierInput.categoery = get_categoery;
        // e.preventDefault();
        setselect_catogery(get_categoery);


        
      
    }


    const handleInput = (e) => {

        e.persist();
        setCourier({ ...courierInput, [e.target.name]: e.target.value })



    }

    const submithoentry = (e) => {
        e.preventDefault();
        courierInput.entry_date = document.getElementById('entry_date').value;
        swal(courierInput.entry_date);
        if (courierInput.entry_date == '') {
            courierInput.entry_date = defaultValue;
        }


        courierInput.enter_by = user;

        courierInput.site_id = document.getElementById('site_id').value;
        courierInput.courier_name = document.getElementById('courier_name').value;
        courierInput.courier_mode = document.getElementById('courier_mode').value;
        courierInput.by_hand = document.getElementById('by_hand').value;
        courierInput.docket_no = document.getElementById('docket_no').value;
        courierInput.form_wh = document.getElementById('form_wh').value;
        courierInput.recived_by = document.getElementById('recived_by').value;
        courierInput.categoery = document.getElementById('categoery').value;
        if (!courierInput.site_id) {
            courierInput.error_list.site_id = 'Select Site Name';
            handleInput(e);
            return false;
        }
        else if (!courierInput.courier_name) {
            courierInput.error_list.courier_name = 'Select Courier Name';
            handleInput(e);
            return false;

        }
        else if (!courierInput.courier_mode) {
            courierInput.error_list.courier_mode = 'Select Mode Name';
            handleInput(e);
            return false;

        }
        else if (!courierInput.by_hand) {

            courierInput.error_list.by_hand = 'Fill this filed';
            handleInput(e);
            return false;

        }
        else if (!courierInput.docket_no) {

            courierInput.error_list.docket_no = 'Fill this filed';
            handleInput(e);
            return false;

        }
        else if (!courierInput.form_wh) {

            courierInput.error_list.form_wh = 'Fill this filed';
            handleInput(e);
            return false;

        }

        else if (!courierInput.recived_by) {

            courierInput.error_list.recived_by = 'Fill this filed';
            handleInput(e);
            return false;

        }
        else if (!courierInput.categoery) {

            courierInput.error_list.categoery = 'Select Categoery';
            handleInput(e);
            return false;

        }

        else {

            courierInput.error_list.site_id = '';
            courierInput.error_list.courier_name = '';
            courierInput.error_list.courier_mode = '';
            courierInput.error_list.by_hand = '';
            courierInput.error_list.docket_no = '';
            courierInput.error_list.form_wh = '';
            courierInput.error_list.recived_by = '';
            courierInput.error_list.categoery = '';
            handleInput(e);

            const data = {

                ho_entry_no: HOENTRY,
                site_id: courierInput.site_id,
                entry_date: courierInput.entry_date,
                courier_id: courierInput.courier_name,
                courier_mode_id: courierInput.courier_mode,
                by_hand: courierInput.by_hand,
                docket_no: courierInput.docket_no,
                from_wh: courierInput.form_wh,
                date_time: courierInput.entry_date,
                entry_by: user,
                recived_by: courierInput.recived_by,
                category: courierInput.categoery,
            }

            axios.post(`api/store-homain`, data).then(res => {
                if (res.data.status === 200) {

                    swal("Success", res.data.message, "success");
                    window.location.href = "/admin/admin-ho";
                    // document.getElementById('CATEGORY_FORM').reset();
                }
                else if (res.data.status === 400) {
                    setCourier({ ...courierInput, error_list: res.data.errors });
                }
            });
        }
    }



    var display_errors = [];
    if (courierInput.error_list) {
        display_errors = [
            courierInput.error_list.company_name,

            courierInput.error_list.status
        ]
    }

    return (
        <div className="container-fluid px-4">

            {
                display_errors.map((item, index) => {
                    return (<p className="mb-1" key={index}>{item}</p>)
                })
            }

            <div className="card mt-4">
                <div className="card-header">
                    <h4>Receiver Acknowledgement
                        <Link to="/admin/admin-ho" className="btn btn-primary btn-sm float-end">View Entries</Link>
                    </h4>
                </div>
                <div className="card-body">

                    <form calssName='form-horizontal ' onSubmit={submithoentry} id="HO_FORM">


                        <div className="card-body">

                            <div className="form-group mb-3">
                                <input type='hidden' id='invoice' value={HOENTRY} />
                                <label>Tracker Form No :</label>
                                <p style={{ color: 'red' }} id='inv_ho' onChange={handleInput} >{HOENTRY}</p>
                    

                            </div>
                            <div className="form-group row">
                                <div class="col-sm">
                                    <label>Site Name : {SiteName}</label>
                                </div>
                                <div class="col-sm">
                                    <label>Date : {courierInput.entry_date}</label>
                                    </div>





                            </div>
                            <div className="form-group row">

                                <div class="col-sm">
                                    <label>Courier Name : {viewcourier_HTMLTABLE}</label>
                                        </div>


                                <div class="col-sm">

                                    <label>Courier Mode : {viewcouriermode_HTMLTABLE} </label> </div>





                            </div>
                            <div className="form-group row">
                                <div class="col-sm">
                                    <label>By Hand : {courierInput.by_hand}</label>
                                   
                                </div>

                                <div class="col-sm">
                                    <label>Docket No : {courierInput.docket_no}</label>
                                    
                                </div>
                            </div>

                            <div className="form-group row">
                                <div class="col-sm">
                                    <label>From : {courierInput.form_wh} </label>
                                    </div>
                                <div class="col-sm">
                                    <label>Date &Time :</label>
                                    </div>
                            </div>
                            <div className="form-group row">
                                <div class="col-sm">
                                    <label>Enter By : {courierInput.entry_by} </label>
                                  
                                </div>
                                <div class="col-sm">
                                    <label>Recived By : {courierInput.recived_by}</label>
                                   
                                </div>

                            </div>
                            


                        </div>



                    </form>



                    <div className="container-fluid px-4">

                        {Showsub}



                    </div>
                    <button type="submit" onClick={submithoentry} className="btn btn-primary px-4 float-end">Action</button>
                </div>

            </div>

           <CategoryAction MainData={courierInput} />
        </div>
    )
}

export default UpdateHo;