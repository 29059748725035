import React from 'react'

function Profile() {

    return (
        <h1>I am Profile</h1>
    );

}

export default Profile;