import React from 'react';
import { Outlet} from 'react-router-dom';
import '../../assets/admin/css/styles.css';
import {Navigate} from 'react-router-dom';

import swal from 'sweetalert';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

const MasterLayout = () =>{
// alert(user);
   const token2=localStorage.getItem('auth_token');
const user_name=localStorage.getItem('auth_name');
var user=null;
 
  if(token2)
  {
    user=user_name;
    // alert(user);
  }
  else{
    swal("Warning","Login First","warning");
    return <Navigate to="/" replace />;
  }
// if (!user) {
//     swal("Warning","Login First","warning");
//     return <Navigate to="/" replace />;
//   }
	return(

<div className="sb-nav-fixed">
  <Navbar user={user}/> 
    <div id="layoutSidenav">
      <Sidebar/>
      <div id="layoutSidenav_content">
        <main>
          <Outlet/>
        </main>
        <Footer/>
      </div>
    </div>
 </div>
		);

}

export default MasterLayout