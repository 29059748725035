import React,{useState} from 'react';
import logo from '../../../assets/admin/img/side-logo.png';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './custom.css';

const Login = () =>{

const navigate = useNavigate();

const [loginInput,setLogin]=useState({
user_name:'',
password:'',
error_list:[],
});
const handleInput=(e)=>{
	e.persist();
	setLogin({...loginInput,[e.target.name]:e.target.value});

}
const loginSubmit=(e)=>{
	e.preventDefault();

	const data={
		user_name:loginInput.user_name,
		password:loginInput.password
	}
	axios.get('/sanctum/csrf-cookie').then(response => {
   
	axios.post('api/login',data).then(res=>{
if(res.data.status===200)
{
  	localStorage.setItem('auth_token',res.data.token);
	localStorage.setItem('auth_name',res.data.user_name);
  localStorage.setItem('sub',res.data.sub);
  localStorage.setItem('rat_id',res.data.rat_id);
	swal("Success",res.data.message,"success");
	navigate('/admin');
}
else if(res.data.status===401)
{
	swal("Warning",res.data.message,"warning");
}
else{
	setLogin({...loginInput,error_list:res.data.validation_errors});
}
	});

	});
}

  return(
<section className="h-100 gradient-form">
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-xl-10">
        <div className="card rounded-3 text-black">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="card-body p-md-5 mx-md-4">

                <div className="text-center">
                  <img src={logo}
                    className="front-logo" alt="logo"/>
                  <h4 className="mt-1 mb-5 pb-1">We are The Zigma Team</h4></div>

                <form onSubmit={loginSubmit}>
                  <p>Please login to your account</p>

                  <div className="form-outline mb-4">
                   <label className="form-label" htmlFor="user_name">Username</label>
                    <input type="text" onChange={handleInput} value={loginInput?.user_name} id="user_name" name="user_name" className="form-control"
                      placeholder="Enter User Name" />
                   <span>{loginInput.error_list?.user_name}</span>
                  </div>

                  <div className="form-outline mb-4">
                   <label className="form-label" htmlFor="password">Password</label>
                    <input type="password" id="password" onChange={handleInput} value={loginInput?.password} name="password"  placeholder="" className="form-control" />
                   <span>{loginInput.error_list?.password}</span>
                  </div>

                  <div className="text-center pt-1 mb-5 pb-1 w-100">
                    <input type="submit" className="btn btn-primary btn-block fa-lg gradient-custom-2 border-0 mb-3 w-100" value="Login"/>
                  </div>

                     </form>

              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-evenly text-center gradient-custom-2">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 className="mb-4">Zigma Courier Module</h4>
                <p className="small mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    );

}

export default Login