import React from "react";
import Select from 'react-select'

const EditableRow3 = ({
  editFormData3,
  handleEditFormChange3,
  handleCancelClick3,
}) => {
  return (
    <tr>
      <td>
       <input
          type="hidden"
          name="entry_no"
          required="required"
          placeholder="Entry No"
           value={editFormData3.entry_no}
          onChange={handleEditFormChange3}
        />
       
    
     
      <input
          type="text"
          name="category"
          required="required"
          readOnly="readonly"
          placeholder="category"
           value={editFormData3.category}
          onChange={handleEditFormChange3}
        />
      
          <input
          type="text"
          name="refno"
          required="required"
          placeholder="Ref No"
           value={editFormData3.refno}
          onChange={handleEditFormChange3}
        />
      </td>
     
      <td>
       <input
          type="text"
          name="remarks"
          required="required"
          placeholder="Remarks"
          value={editFormData3.remarks}
          onChange={handleEditFormChange3}
        />
      </td>
      <td>
        <button type="submit" className="btn btn-success">Save</button>
        <button type="button" className="btn btn-danger" onClick={handleCancelClick3}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow3;