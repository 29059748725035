import React, {useEffect, useState } from 'react';
import { Link,useParams} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import SiteSublist from './SiteSublist';
import PettyCashSite from './PettyCashSite';
import OtherSubSite from './OtherSubSite';
import Datetime from 'react-datetime';
import ImageUploading from 'react-images-uploading';
const token2=localStorage.getItem('auth_token');
const user_name=localStorage.getItem('auth_name');
function BillUpload(){
   
   var sub_id = useParams().id;
   var FILELIST='';
   var imageurl="";
const [get_image, setImage] = useState([]);
const [img, setImg] = useState();

 const openInNewTab = (url,id) => {

     onmouseover= window.open('https://courier.zigmaglobal.in/backend/Siteuplodes/'+id,'','height=550,width=800,scrollbars=yes,left=320,top=120,toolbar=no,location=no,directories=no,status=no,menubar=no');
//  return false; 
//  onmouseover= window.open('http://192.168.1.51:8001/backend/public/Siteuplodes/'+id,'','height=550,width=800,scrollbars=yes,left=320,top=120,toolbar=no,location=no,directories=no,status=no,menubar=no');
 return false; 
  };

useEffect(() => {
  Imge_sub();
   }, []);

const Imge_sub =(e) =>{

axios.post(`/api/list-site-files/${sub_id}`).then(res=>{

        if(res.data.status==200){

setImage(res.data.list_files);
console.log(get_image);
        }
             
           
        });

}

   
 
 

 FILELIST =
        get_image.map( (item,index) => {
           imageurl="https://courier.zigmaglobal.in/backend/Siteuplodes/"+item.image_name;
          // imageurl="'http://192.168.1.51:8001/backend/public/Siteuplodes/"+item.image_name;
            return (
            <>


                <tr >
                <td>{index+1}</td>
                    <td><Link onClick={(url) => openInNewTab(url, item.image_name)}  >
        {item.image_name}

      </Link></td>
      <td>
      <img src={imageurl} alt="" className="imageprev" width="100" height='100' />
      
      </td>
                   <td>
                       
                        <button type="button"  onClick={ (e) => del_image(e, item.site_img_id) } className="btn btn-danger btn-sm">Delete</button>
                  
                    </td>
                </tr>
                   
             </>
            ) 
        });



const colsetab =(e) =>{

window.close();

}


const UploadImage =(e) =>{

 const uploadFileEle = document.getElementById("file_name")
 
  if (uploadFileEle.files.length === 0) {
        alert('please choose a file')
        return false;
      }

       let file = uploadFileEle.files[0]

      let formData = new FormData();
      formData.append('file', file);

console.log("Image FIles",formData);

console.log("Image FIles1",file);

 axios.post(`/api/site-upload-files/${sub_id}`,formData).then(res=>{

        if(res.data.status==200)
                {
                  window.location.href = "/admin/bill-upload-site/"+sub_id;

                }
             
           
        });

}

  const del_image = (e, id) => {

e.preventDefault();
axios.get(`/api/det-siteimg/${id}`).then(res=>{
            if(res.data.status === 200)
            {
 
             window.location.href = "/admin/bill-upload-site/"+sub_id;
               }


});



     }

  
    return (
        <div className="container-fluid px-4">



            <div className="card mt-4">
                <div className="card-header">
                    <h4>Bill Upload
                       
                    </h4>
                </div>
                <div className="card-body">

                    <form calssName='form-horizontal ' id="HO_FORM">


                        <div className="card-body">
                        
                       
                            
                            <div className="form-group row">
                                <div class="col-sm">
                                    <label>Upload File</label>
                                    <input type="file" name="file_name" id="file_name" onChange={UploadImage} className="form-invcontrol" />

                                    <span style={{ color: 'red' }} ></span>
                                </div>
                            
                                <div class="col-sm">
                                    
                                </div>
                            </div>





<table className="table display" cellSpacing="0">
  <thead>
  <tr>
                                <th>#S.No</th>

                                
                                <th>Document Name</th>
                                 <th>Document View</th>
                               
                                <th>Action</th>
                               
                                
  </tr>
  </thead>
  <tbody>
  {FILELIST}
  </tbody>
  </table>









                           
                        </div>

<button type="submit" onClick={colsetab} className="btn btn-danger px-4 float-end">Colse</button>
                        
                    </form>

                
           
        
       
        </div>

         </div>

        </div>
    )
}

export default BillUpload;