import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import SiteSublist from "./SiteSublist";
import PettyCashSite from "./PettyCashSite";
import OtherSubSite from "./OtherSubSite";
import Datetime from "react-datetime";
import AllCatsubSite from "./AllCatsubSite";
import Select from "react-select";
import { motion } from "framer-motion";
const token2 = localStorage.getItem("auth_token");
const user_name = localStorage.getItem("auth_name");
function UpdateHo() {
  var user = null;
  if (token2) user = user_name;
  else user = null;
  console.log(useParams());
  const site_id = useParams().id;
  var edit_site_id = "";
  var edit_categoery = "";
  var subpage = "";
  var ho_number = "";
  var sublist = "";
  const [Show, setshow] = useState([]);
  const [courierInput, setCourier] = useState({
    invoice_no: "",
    site_id: "",
    courier_mode: "",
    entry_date: "",
    courier_name: "",
    docket_no: "",
    form_wh: "",
    date_time: "",
    by_hand: "",
    enter_by: "",

    categoery: "",
    error_list: [],
  });
  const [options, setOptions] = useState([]);
  const [HOENTRY, setHOENTRY] = useState([]);
  const [courierlist, setCourierlist] = useState([]);
  const [Showsub, setshowsub] = useState([]);
  const [select_site, setselect_site] = useState([]);
  const [select_courier, setselect_courier] = useState([]);
  const [select_courier_mode, setselect_courier_mode] = useState([]);
  const [select_catogery, setselect_catogery] = useState([]);
  const [couriermode, setCouriermodelist] = useState([]);
  const [sitelist, setsitelist] = useState([]);
  const [show_co, set_co] = useState(true);
  const [courier_id, setcourier_id] = useState([]);
  const [show_bh, set_bh] = useState(false);
  const [s_id, sets_id] = useState([]);
  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/updatesite/${site_id}`).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setHOENTRY(res.data.site_entry_no);
          setselect_site(res.data.site_id);
          sets_id(res.data.site_id);
          courierInput.invoice_no = res.data.ho_entry_no;

          setselect_courier(res.data.courier_id);
          setcourier_id(res.data.courier_id);
          courierInput.entry_date = res.data.entry_date;
          setselect_courier_mode(res.data.courier_mode_id);
          courierInput.by_hand = res.data.by_hand;
          courierInput.docket_no = res.data.docket_no;
          courierInput.form_wh = res.data.from_wh;
          courierInput.date_time = res.data.date_time;

          setselect_catogery(res.data.category);
          edit_categoery = res.data.category;
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);
  const [courierlist_choosen, setCourierlist_choosen] = useState([]);
  const handleSelect3 = (option) => {
    setCourierlist_choosen(option.value);
    setCourier({ ...courierInput, courier_name: option.value });
    setcourier_id(option.value);
  };
  // const cat = [
  //     {id: 'Invoice', name: 'Invoice'},
  //     {id: 'Letter', name: 'Letter'},
  //     {id: 'Invitation', name: 'Invitation'},
  //     {id: 'Claim', name: 'Claim'},
  //     {id: 'Others', name: 'Others'},
  //     {id: 'PettyCash', name: 'PettyCash'},
  //   ];
  const cat = [
    { id: "Invoice", name: "Invoice" },
    { id: "Others", name: "Letter / Invitation / Claim / Others" },
    { id: "PettyCash", name: "PettyCash" },
  ];

  var Category = "";
  Category = cat.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/view-courier`).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setCourierlist(res.data.courier);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  var viewcourier_HTMLTABLE = "";
  viewcourier_HTMLTABLE = courierlist.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.company_name}
      </option>
    );
  });

  const courier_options = courierlist.map((option, index) => {
    return { label: option.company_name, value: option.id, key: option.id };
  });

  const courier_mode = [
    { id: "Courier", name: "Courier" },
    { id: "By Hand", name: "By Hand" },
  ];
  var viewcouriermode_HTMLTABLE = "";

  viewcouriermode_HTMLTABLE = courier_mode.map((item, index) => {
    return (
      <option key={item.id} value={item.id} selected>
        {item.name}
      </option>
    );
  });

  useEffect(() => {
    let isMount1 = true;

    axios.get(`/api/view-site`).then((res) => {
      if (isMount1) {
        if (res.status === 200) {
          setsitelist(res.data.site);
        }
      }
    });
    return () => {
      isMount1 = false;
    };
  }, []);

  var default_setect = {
    site: edit_site_id,
  };

  const site_options = sitelist.map((option, index) => {
    return { label: option.site_name, value: option.id, key: option.id };
  });
  // const Get_invoice = (e) => {

  //     handleInput(e);

  //     var selectElement = e.target;
  //     var value = selectElement.value;
  //     var site_id = value;

  //     axios.get(`/api/get-inv/${site_id}`).then(res => {
  //         ho_number = res.data.invoice_no;

  //         setHOENTRY(ho_number);

  //     });

  // }
  const Get_invoice = (option) => {
    // handleInput(e);

    // var selectElement = e.target;
    // swal(selectElement);
    // var value = selectElement.value;
    var site_id = option.value;

    axios.get(`/api/get-inv/${site_id}`).then((res) => {
      ho_number = res.data.invoice_no;

      setHOENTRY(ho_number);
    });

    setCourier({ ...courierInput, site_id: option.value });
    sets_id(option.value);
  };

  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  const ShowSublist = (e) => {
    handleInput(e);

    var selectElement = e.target;
    var value = selectElement.value;

    var get_categoery = value;
    courierInput.categoery = get_categoery;
    // e.preventDefault();
    setselect_catogery(get_categoery);

    if (get_categoery == "Invoice") {
      subpage = <SiteSublist MainData={courierInput} />;

      sublist = <AllCatsubSite MainData={courierInput} />;
    } else if (get_categoery == "") {
      subpage = "";
      sublist = <AllCatsubSite MainData={courierInput} />;
    } else if (get_categoery == "PettyCash") {
      subpage = <PettyCashSite MainData={courierInput} />;
      sublist = <AllCatsubSite MainData={courierInput} />;
    } else {
      subpage = <OtherSubSite MainData={courierInput} />;
      sublist = <AllCatsubSite MainData={courierInput} />;
    }
    setshow(sublist);
    setshowsub(subpage);
  };

  const handleInput = (e) => {
    e.persist();
    setCourier({ ...courierInput, [e.target.name]: e.target.value });
  };

  const submithoentry = (e) => {
    e.preventDefault();
    courierInput.entry_date = document.getElementById("entry_date").value;

    if (courierInput.entry_date == "") {
      courierInput.entry_date = defaultValue;
    }

    courierInput.enter_by = user;

    courierInput.site_id = document.getElementById("s_id").value;

    courierInput.courier_mode = document.getElementById("courier_mode").value;

    courierInput.docket_no = document.getElementById("docket_no").value;
    courierInput.form_wh = document.getElementById("form_wh").value;

    courierInput.categoery = document.getElementById("categoery").value;

    if (courierInput.courier_mode == "Courier") {
      courierInput.courier_name = document.getElementById("c_id").value;
      courierInput.by_hand = "";
      if (!courierInput.courier_name) {
        swal();

        courierInput.error_list.courier_name = "Select Courier Name";
        handleInput(e);
        return false;
      }
    } else if (courierInput.courier_mode == "By Hand") {
      courierInput.courier_name = "";
      courierInput.by_hand = document.getElementById("by_hand").value;
      if (!courierInput.by_hand) {
        swal();

        courierInput.error_list.by_hand = "Fill this filed";
        handleInput(e);
        return false;
      }
    }
    if (!courierInput.site_id) {
      swal();

      courierInput.error_list.site_id = "Select Site Name";
      handleInput(e);
      return false;
    } else if (!courierInput.courier_mode) {
      swal();

      courierInput.error_list.courier_mode = "Select Mode Name";
      handleInput(e);
      return false;
    } else if (!courierInput.docket_no) {
      swal();

      courierInput.error_list.docket_no = "Fill this filed";
      handleInput(e);
      return false;
    } else if (!courierInput.form_wh) {
      swal();
      courierInput.error_list.form_wh = "Fill this filed";
      handleInput(e);
      return false;
    } else if (!courierInput.categoery) {
      swal();

      courierInput.error_list.categoery = "Select Categoery";
      handleInput(e);
      return false;
    } else {
      courierInput.error_list.site_id = "";
      courierInput.error_list.courier_name = "";
      courierInput.error_list.courier_mode = "";
      courierInput.error_list.by_hand = "";
      courierInput.error_list.docket_no = "";
      courierInput.error_list.form_wh = "";

      courierInput.error_list.categoery = "";
      handleInput(e);

      const data = {
        site_entry_no: document.getElementById("invoice_no").value,
        site_id: courierInput.site_id,
        entry_date: courierInput.entry_date,
        courier_id: courierInput.courier_name,
        courier_mode_id: courierInput.courier_mode,
        by_hand: courierInput.by_hand,
        docket_no: courierInput.docket_no,
        from_wh: courierInput.form_wh,
        date_time: courierInput.entry_date,
        entry_by: user,

        category: courierInput.categoery,
      };

      axios.post(`api/store-sitemain`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success", res.data.message, "success");
          window.location.href = "/admin/admin-site";
          // document.getElementById('CATEGORY_FORM').reset();
        } else if (res.data.status === 400) {
          setCourier({ ...courierInput, error_list: res.data.errors });
        }
      });
    }
  };

  var display_errors = [];
  if (courierInput.error_list) {
    display_errors = [
      courierInput.error_list.company_name,

      courierInput.error_list.status,
    ];
  }
  const courier_from = (e) => {
    handleInput(e);
    var mode_type = document.getElementById("courier_mode").value;
    setselect_courier_mode(mode_type);
    handleInput(e);
    swal(mode_type);
    if (mode_type == "By Hand") {
      set_bh(true);
      set_co(false);
      courierInput.courier_name = "";
      handleInput(e);
    } else {
      set_bh(false);
      set_co(true);
      courierInput.by_hand = "";
      handleInput(e);
    }
  };

  return (
    <div className="UpdateSite">
      <div className="container-fluid px-4">
        {display_errors.map((item, index) => {
          return (
            <p className="mb-1" key={index}>
              {item}
            </p>
          );
        })}

        <motion.div className="card mt-4"
          initial={{ opacity: 0}} 
          animate={{ opacity: 1 }} 
          transition={{ type:'spring', stiffness: 50}} >
          <div className="card-icon">
            <i className="fa-solid fa-file-lines" />
          </div>
          <div className="card-header">
            <div>
              <h4>Update Site Entry</h4>
            </div>
            <div>
              <Link
                to="/admin/admin-site"
                className="btn btn-primary btn-sm float-end"
              >
                View Entries
              </Link>
            </div>
          </div>
          <div className="card-body">
            <form
              calssName="form-horizontal "
              onSubmit={submithoentry}
              id="HO_FORM"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <motion.div className="form-group mb-3"
                      initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .1, type:'spring', stiffness: 50}} >
                      <div className=" mb-4">
                        <label>Tracker Form No :</label>
                        <p
                          style={{ color: "red" }}
                          id="inv_ho"
                          onChange={handleInput}
                        >
                          {HOENTRY}
                        </p>
                        <input
                          type="hidden"
                          name="invoice_no"
                          id="invoice_no"
                          onChange={handleInput}
                          value={(courierInput.invoice_no = HOENTRY)}
                          defaultValue={"fdgfdgd"}
                          className="form-control"
                        />
                      </div>
                    </motion.div>
                    <motion.div class="form-group mb-3"
                      initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .2, type:'spring', stiffness: 50}}>
                      <input type="hidden" id="s_id" value={s_id} />
                      <label>Site Name</label>
                      <Select
                        id="site_id"
                        name="site_id"
                        placeholder="Select Site..."
                        isSearchable
                        //    value={site_options.label}
                        value={site_options.filter(
                          (site_options) => site_options.value == select_site
                        )}
                        key={site_options.key}
                        options={site_options}
                        onChange={(option) => Get_invoice(option)}
                      />
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.site_id}
                      </span>
                    </motion.div>
                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                     animate={{y:0, opacity: 1 }} 
                     transition={{ delay: .3, type:'spring', stiffness: 50}}>
                      <label>Docket No</label>
                      <input
                        type="text"
                        name="docket_no"
                        id="docket_no"
                        onChange={handleInput}
                        value={courierInput.docket_no}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.docket_no}
                      </span>
                    </motion.div>
                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                     animate={{y:0, opacity: 1 }} 
                     transition={{ delay: .4, type:'spring', stiffness: 50}}>
                      <label>Enter By </label>
                      <input
                        type="text"
                        name="enter_by"
                        id="enter_by"
                        onChange={handleInput}
                        className="form-control"
                        defaultValue={user}
                        disabled
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.enter_by}
                      </span>
                    </motion.div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <div class="form-group mb-3 visibility">
                      <label>Category</label>

                      <select
                        type="text"
                        name="categoery"
                        id="categoery"
                        onChange={() => (handleInput, ShowSublist)}
                        value={select_catogery}
                        defaultValue={select_catogery}
                        className="form-control"
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {Category}
                      </select>
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.categoery}
                      </span>
                    </div>

                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .2, type:'spring', stiffness: 50}}>
                      <label>Courier Mode</label>

                      <select
                        type="text"
                        name="courier_mode"
                        id="courier_mode"
                        onChange={courier_from}
                        value={select_courier_mode}
                        defaultValue={select_courier_mode}
                        className="form-control"
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {viewcouriermode_HTMLTABLE}
                      </select>
                      <span style={{ color: "red" }}>
                        {courierInput.error_list.courier_mode}
                      </span>
                    </motion.div>
                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                     animate={{y:0, opacity: 1 }} 
                     transition={{ delay: .4, type:'spring', stiffness: 50}}>
                      <label>From </label>
                      <input
                        type="text"
                        name="form_wh"
                        id="form_wh"
                        onChange={handleInput}
                        value={courierInput.form_wh}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.form_wh}
                      </span>
                    </motion.div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-12">
                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                     animate={{y:0, opacity: 1 }} 
                     transition={{ delay: .1, type:'spring', stiffness: 50}}>
                      <label>Date</label>
                      <input
                        type="date"
                        name="entry_date"
                        id="entry_date"
                        onChange={handleInput}
                        defaultValue={defaultValue}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.entry_date}
                      </span>
                    </motion.div>

                    {show_co ? (
                      <motion.div className="form-group mb-3"
                       initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .2, type:'spring', stiffness: 50}}>
                        <input type="hidden" id="c_id" value={courier_id} />
                        <label>Courier Name</label>

                        <Select
                          name="courier_name"
                          id="courier_name"
                          placeholder="Select Courier..."
                          isSearchable
                          value={courier_options.filter(
                            (courier_options) =>
                              courier_options.value == select_courier
                          )}
                          // value={courier_options.label}
                          key={courier_options.key}
                          options={courier_options}
                          onChange={(option) => handleSelect3(option)}
                        />

                        <span style={{ color: "red" }}>
                          {courierInput.error_list.courier_name}
                        </span>
                      </motion.div>
                    ) : null}

                    {show_bh ? (
                      <motion.div className="form-group mb-3"
                      initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .2, type:'spring', stiffness: 50}}>
                        <label>By Hand</label>
                        <input
                          type="text"
                          name="by_hand"
                          id="by_hand"
                          onChange={handleInput}
                          value={courierInput.by_hand}
                          className="form-control"
                        />

                        <span style={{ color: "red" }}>
                          {courierInput.error_list.by_hand}
                        </span>
                      </motion.div>
                    ) : null}
                    <motion.div class="form-group mb-3"
                     initial={{y:50, opacity: 0}} 
                      animate={{y:0, opacity: 1 }} 
                      transition={{ delay: .3, type:'spring', stiffness: 50}}>
                      <label>Date &Time</label>
                      <input
                        type="datetime-local"
                        name="date_time"
                        id="date_time"
                        onChange={handleInput}
                        value={courierInput.date_time}
                        className="form-control"
                      />

                      <span style={{ color: "red" }}>
                        {courierInput.error_list.date_time}
                      </span>
                    </motion.div>
                  </div>
                </div>
              </div>
            </form>
            <div className="container-fluid px-4 card-gap" >
              <SiteSublist MainData={courierInput} />
            </div>
            <div className="container-fluid px-4 card-gap" data-aos="fade-left" data-aos-offset="100" data-aos-delay="200">
              <PettyCashSite MainData={courierInput} />
            </div>
            <div className="container-fluid px-4 card-gap" data-aos="fade-left" data-aos-offset="100" data-aos-delay="300">
              <OtherSubSite MainData={courierInput} />
            </div>
            <div className="container-fluid px-4">{Showsub}</div>
            <div className="btn-align">
              <button
              data-aos="fade-up" data-aos-offset="100" data-aos-delay="400"
                type="submit"
                onClick={submithoentry}
                className="btn btn-primary px-4 float-end mt-4"
              >
                Update
              </button>
            </div>
          </div>
        </motion.div>

        {Show}
      </div>
    </div>
  );
}

export default UpdateHo;
