import React from "react";
import Select from 'react-select'

const EditableRow = ({
  editFormData,
  handleSelect5,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <tr>
      <td>
       <input
          type="hidden"
          name="entry_no"
          required="required"
          placeholder="Entry No"
           value={editFormData.entry_no}
          onChange={handleEditFormChange}
        />
       
     
     
       <select name="category" defaultValue={editFormData.category} className="form-control" onChange={(e) => handleSelect5(e)}>
     <option value="Letter" >Letter</option>
     <option value="Invitation">Invitation</option>
     
     <option value="Claim">Claim</option>
     <option value="Others">Others</option>
     </select>
     
        <input
          type="text"
          name="from_whom"
          required="required"
          placeholder="From_whom"
          value={editFormData.from_whom}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
       <input
          type="text"
          name="to_whom"
          required="required"
          placeholder="TO Whom"
          value={editFormData.to_whom}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
       <input
          type="text"
          name="remarks"
          required="required"
          placeholder="Remarks"
          value={editFormData.remarks}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
        <button type="submit" className="btn btn-success">Save</button>
        <button type="button" className="btn btn-danger" onClick={handleCancelClick}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;