import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom'


const Sidebar = () => {

    const [focus, setFocus] = useState(false);

    const handleChange = () => {
        setFocus(!focus);
    }

    let toggle = focus ? 'active' : '';

    return (

        <div id="layoutSidenav_nav">
            <nav className="sidebar sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">

                        <Link className={`navLink nav-link ${toggle}`} onClick={handleChange} to="/admin">
                            <div className={`icon sb-nav-link-icon ${toggle}`}><i className="fas fa-tachometer-alt "></i></div>
                            Dashboard
                        </Link>

                        <Link className="navLink nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="icon sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Master
                            <div className="icon-arrow sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </Link>
                        <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <NavLink className="sub navLink nav-link" to="/admin/view-courier">Courier Company Creation</NavLink>
                                 </nav>
                        </div>

                        <a className="navLink nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts2" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="icon sb-nav-link-icon"><i className="fas fa-pencil" aria-hidden="true"></i></div>
                            Site Entry
                            <div className="icon-arrow sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </a>
                        <div className="collapse" id="collapseLayouts2" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <NavLink className="sub navLink nav-link" to="/admin/admin-site">Site Entry Creation</NavLink>
                                
                            </nav>
                        </div>
                        <a className="navLink nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts3" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="icon sb-nav-link-icon"><i className="fa-solid fa-building"></i></div>
                            HO Entry
                            <div className="icon-arrow sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </a>
                        <div className="collapse" id="collapseLayouts3" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <NavLink className="sub navLink nav-link" to="/admin/admin-ho">HO Entry Creation</NavLink>
                               
                            </nav>
                        </div>
                        <a className="navLink nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts4" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="icon sb-nav-link-icon"><i className="fa-sharp fa-solid fa-handshake" aria-hidden="true"></i></div>
                            Acknowledgement
                            <div className="icon-arrow sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </a>
                        <div className="collapse" id="collapseLayouts4" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <NavLink className="sub navLink nav-link" to="/admin/view-ac">Site Acknowledgement</NavLink>
                                <NavLink className="sub navLink nav-link"  to="/admin/ack">Receiver Acknowledgement</NavLink>

                            </nav>
                        </div>


                    </div>
                </div>

            </nav>
        </div>
    );

}

export default Sidebar