import React from "react";

const ReadOnlyRow2 = ({ category, handleEditClick2, handleDeleteClick2,file,deleteFile }) => {
  return (
    <tr>
    
     
<td><input type="checkbox"/></td>
      <td>{category.inv_no}</td>
      <td>{category.inv_date}</td>
         <td>{category.vendor_name}</td>
            <td>{category.ref_no}</td>
               <td>{category.amntrs}</td>
                 <td>{category.exp}</td>
      <td>{category.remarks}</td>
        <td>
        {(category.bills_upl).split(",").length > 0 &&
          (category.bills_upl).split(",").map((item, index) => {
            return (
              <div key={item}>
                <img src={item} alt="" className="imageprev" />        
              </div>
            );
          })}
      </td>
    <td><input type="text"/></td>
    </tr>
  );
};

export default ReadOnlyRow2;