import React,{useState} from 'react';
import { Link } from 'react-router-dom'
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import logo from '../Images/add_icon.png';
import { faArrowsUpDown, faBars, faClose, faTrash, faArrowsLeftRight, faEllipsisVertical, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// const MenuToggle=()=>{
    
            
//             document.body.classList.toggle('sb-sidenav-toggled');
//             localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
   
//     }

const handleSelect=(e)=>{
    e.preventDefault();
    var cls=document.getElementById("navbarDropdown").classList;
    var arcls=(cls).length;
    // alert(arcls);
       if(arcls==2)
       {
            document.getElementById("navbarDropdown").classList.add("show");
            document.getElementById("ulmenu-end").classList.add("show");
            document.getElementById("ulmenu-end").setAttribute("data-bs-popper", "none");
        }
        else{
         document.getElementById("navbarDropdown").classList.remove("show");
            document.getElementById("ulmenu-end").classList.remove("show");
            document.getElementById("ulmenu-end").removeAttribute("data-bs-popper", "none");   
        }
   
    }
const Navbar = ({user}) =>{
 
const navigate = useNavigate();
const logoutSubmit=(e)=>{
const token=localStorage.getItem('auth_name');
const sub=localStorage.getItem('sub');
    e.preventDefault();
  const data={
        user_name:token,
        sub:sub
    }
    axios.post('/api/logout',data).then(res=>{
if(res.data.status===200){
    localStorage.removeItem('auth_token')
    localStorage.removeItem('auth_name')
    localStorage.removeItem('sub')
    swal("Success",res.data.message,"success");
        navigate('/');
        // windows.location.href="/";
}
    });
}

const [tog, setTog] = useState(false);

const Toggle = () => {
    setTog(!tog);
    document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));

}

return(

            <nav className="nav-bar">  
                <div className="d-flex">    
                    <Link className="link" to="/admin">
                        <img src={logo} className="brand-logo"/>
                        <h3 className="brand-name">Zigma</h3>
                    </Link>               

                
                    <button className="toggle-btn btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0 cl-wh" id="sidebarToggle" onClick={Toggle}>
                    { tog ? <FontAwesomeIcon  icon={faBars} /> : <FontAwesomeIcon icon={faArrowsLeftRight} /> }
                    </button>
                </div>   
                    <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></div>                    
                        <div className="logout">
                            <div className="user margin-r">{user}</div>                    
                                <Link className="cl-wh" onClick={logoutSubmit}>
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                </Link>
                            </div> 
                        
            </nav>
	);

}

export default Navbar