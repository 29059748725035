import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import View from "../../../assets/admin/img/View.png";
import Datetime from "react-datetime";
import Select from "react-select";
import { type } from "@testing-library/user-event/dist/type";
const token2 = localStorage.getItem("auth_token");
const user_name = localStorage.getItem("auth_name");
const AllCatsub = ({ MainData }) => {
  var user = null;
  if (token2) user = user_name;
  else user = null;

  const date = new Date();
  const det = date.toLocaleDateString("en-CA");
  const [year, month, day] = det.split("-");

  var INVOICE = "";
  var PETTYCASH = "";
  var OTHERS = "";
  var empty = "";
  const [GetInvoice, setInvoice] = useState([]);
  const [GetPettycash, setPettycash] = useState([]);
  const [GetOthers, setOthers] = useState([]);

  const [showemployee, setemployee] = useState([]);
  const [handover_by_choosen, sethandover_by_choosen] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const [isid, setid] = useState();
  const [showstatus, setsatus] = useState();
  const [showtick, settick] = useState();
  const [sedit, setedit] = useState(true);
  const [action, setaction] = useState({
    site_id: "",
    checked: "",
    handover_d: "",
  });
  useEffect(() => {
    //         // setInterval(function(){alert("Hello")},3000);
    //         var stop='';
    // const timer2 = setInterval(() => {
    //     stop='1';
    //   }, 3000);
    // //1800000
    //         if(MainData.categoery!=''){
    // const timer = setInterval(() => {
    //     if(stop==1){

    //          clearInterval(timer);
    //          clearInterval(timer2);
    //          stop='';
    //     }
    //     CallList();
    //   }, 7000);
    //         }
    //         else{
    //             CallList();
    //         }
    setTimeout(() => {
      CallList();
      axios.post(`/api/get-employee`).then((res) => {
        if (res.data.status === 200) {
          setemployee(res.data.employee);
        }
      });
    }, 3000);
  }, []);

  const handover_options = showemployee.map((option, index) => {
    return {
      label: option.employee_name + "/" + option.designation_name,
      value: option.user_id_a,
      key: option.user_id_a,
    };
  });

  const datas = [];
  const handleSelecthand = (option, id, ind, frm) => {
    var rec = document.getElementById("rec" + id).value;

    var condition = document.getElementById("condi").value;

    if (rec == "received") {
      sethandover_by_choosen(option.key);
      document
        .getElementById("handover" + id)
        .setAttribute("value", option.key);
      if (frm == "INVOICE") {
        var dummy_invoice = GetInvoice;
        dummy_invoice[ind]["handover"] = option.key;
      } else if (frm == "PETTYCASH") {
        var dummy_invoice = GetPettycash;
        dummy_invoice[ind]["handover"] = option.key;
      } else if (frm == "OTHERS") {
        var dummy_invoice = GetOthers;
        dummy_invoice[ind]["handover"] = option.key;
      }
    } else {
      sethandover_by_choosen(empty);
      document.getElementById("handover" + id).setAttribute("value", empty);
    }
    // if (satus == 'received') {
    //   sethandover_by_choosen(option.key)

    //   const data = {

    //     id: id,
    //     invoice: invoice,
    //     satus: satus,
    //     hand_over: option.value,
    //     acknowleged_by: acknowleged_by,
    //     acknowleged_date: acknowleged_date

    //   }

    //   axios.post(`/api/add-ack/`, data).then(res => {

    //     setIsChecked(empty);
    //     setid(empty);
    //     setsatus(empty);

    // if (res.data.ho_entrysub != '') {

    // document.getElementById("site_id").setAttribute("disabled", "disabled");
    // document.getElementById("courier_name").setAttribute("disabled", "disabled");
    // document.getElementById("courier_mode").setAttribute("disabled", "disabled");
    // document.getElementById("categoery").removeAttribute("disabled");

    // setInvSublist(res.data.ho_entrysub)

    // }
    // else {

    //   // document.getElementById("site_id").removeAttribute("disabled");
    //   // document.getElementById("courier_name").removeAttribute("disabled");
    //   // document.getElementById("courier_mode").removeAttribute("disabled");
    //   // document.getElementById("categoery").removeAttribute("disabled");
    //   setInvSublist(res.data.ho_entrysub)
    // }

    //   });

    // }
    // else {

    // }
  };

  const CallList = (e) => {
    var inv_no = document.getElementById("invoice").value;

    const data = {
      site_entry_no: inv_no,
    };

    axios.post(`/api/get-subdatasite`, data).then((res) => {
      if (res.data.inv_status == 200) {
        setInvoice(res.data.site_entry_invoice);
      }
      if (res.data.petty_satus == 200) {
        setPettycash(res.data.ho_entry_pettycash);
      }

      if (res.data.other_status == 200) {
        setOthers(res.data.ho_entry_Others);
      }
    });
  };
  var recv = "";
  var status = "";

  const received = (e, id, ind) => {
    swal();
    setid(id);
    setIsChecked(e.target.checked);

    // 👇️ this is the checkbox itself
    // console.log(e.target);

    // 👇️ this is the checked value of the field

    setTimeout(() => {
      var tick = document.getElementById("condi").value;

      console.log(tick);
      if (tick === "true") {
        status = "received";
        document.getElementById("rec" + id).setAttribute("checked", true);

        document.getElementById("rec" + id).setAttribute("value", "received");
        document.getElementById("site_id" + id).setAttribute("value", id);
        settick(status);
        //                 var dummy_invoice=GetInvoice
        // dummy_invoice[ind]["site_received_status"]="not-received"
        // console.log(dummy_invoice)
        // setInvoice(dummy_invoice);
        // swal("Success", "received", "success");
      } else if (tick === "false") {
        var s_id = "";
        status = "not-received";
        // settick(empty);

        document.getElementById("rec" + id).setAttribute("value", empty);
        document.getElementById("rec" + id).setAttribute("checked", false);
        document.getElementById("site_id" + id).setAttribute("value", id);

        // GetInvoice.map((item1, index) => {
        //     if(item1.id==id)
        //     {
        //         setInvoice({...GetInvoice, [item1.site_received_status]: "not-received" });

        //     }
        // })

        // console.log(id)
        // console.log(GetInvoice)
        // console.log(GetInvoice[id-1])
        // console.log(GetInvoice[id-1]["site_received_status"])
      }
      setsatus(status);

      var invoice = document.getElementById("invoice").value;
      //  if(status=='not-received'){

      //   const data = {

      //     id: id,
      //     invoice: invoice,
      //     satus: '',
      //     hand_over:'',
      //     acknowleged_by: '',
      //     acknowleged_date: '',

      //   }

      //   axios.post(`/api/add-ack/`, data).then(res => {

      //     setIsChecked(empty);
      //     setid(empty);
      //     setsatus(empty);

      //   });

      //  }
    }, 1000);
  };

  const submitAction = (e) => {
    var chk = [];
    var chk_id = [];
    var hand_id = [];
    var input = document.getElementsByName("rec[]");
    var input2 = document.getElementsByName("site_id[]");
    var input3 = document.getElementsByName("handover[]");
    var acknowleged_by = document.getElementById("acknowleged_by").value;
    var acknowleged_date = document.getElementById("acknowleged_date").value;
    var invoice = document.getElementById("invoice").value;
    for (var i = 0; i < input.length; i++) {
      var a = input[i];
      var b = input2[i];
      var c = input3[i];

      var k = a.value;
      var j = b.value;
      var l = c.value;

      chk.push(k);
      chk_id.push(j);
      hand_id.push(l);
    }
    // console.log(chk);
    // console.log(chk_id);
    // console.log(hand_id);
    const data = {
      id: chk_id,
      invoice: invoice,
      satus: chk,
      hand_over: hand_id,
      acknowleged_by: acknowleged_by,
      acknowleged_date: acknowleged_date,
    };
    axios.post(`/api/add-ackn`, data).then((res) => {
      if (res.data.status == 200) {
        swal("Success", res.data.message, "success");
        window.location.href = "/admin/view-ac";
      }

      chk = [];
      chk_id = [];
      hand_id = [];
    });
  };

  INVOICE = GetInvoice.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td align="center">
            <input type="hidden" name="condi" id="condi" value={isChecked} />
            {item.site_received_status == "received" ? (
              <input
                type="hidden"
                name="site_id[]"
                id={"site_id" + item.id}
                value={item.id}
              />
            ) : (
              <input type="hidden" name="site_id[]" id={"site_id" + item.id} />
            )}

            {item.site_received_status == "received" ? (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
                defaultValue="received"
                defaultChecked
              />
            ) : (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
              />
            )}
          </td>
          <td>{item.inv_no}</td>
          <td>{item.inv_date}</td>
          <td>{item.inv_for}</td>
          <td>{item.site_received_status}</td>

          <td>{item.reference_no}</td>
          <td>{item.amount}</td>
          <td>{item.type_expenses}</td>
          <td>{item.remarks}</td>
          <td align="center">
            <img src={View} wdith="19" height="19" />
          </td>
          <td>
            {isNaN(item.handover) ? (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
              />
            ) : (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
                value={item.handover}
              />
            )}

            <Select
              placeholder="Select Person..."
              hideSelectedOptions={false}
              isSearchable
              id={"sel_hand" + item.id}
              // value={handover_options.filter(handover_options =>
              // handover_options.value == SubList.handover)}
              value={handover_options.filter(
                (handover_options) => handover_options.value == item.handover
              )}
              key={handover_options.key}
              options={handover_options}
              onChange={(option) =>
                handleSelecthand(option, item.id, index, "INVOICE")
              }
            />
          </td>
        </tr>
      </>
    );
  });

  PETTYCASH = GetPettycash.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td align="center">
            {item.site_received_status == "received" ? (
              <input
                type="hidden"
                name="site_id[]"
                id={"site_id" + item.id}
                value={item.id}
              />
            ) : (
              <input type="hidden" name="site_id[]" id={"site_id" + item.id} />
            )}
            {item.site_received_status == "received" ? (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
                defaultValue="received"
                defaultChecked
              />
            ) : (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
              />
            )}
          </td>

          <td>{item.inv_for}</td>
          <td>{item.reference_no}</td>

          <td>{item.remarks}</td>
          <td>
            {isNaN(item.handover) ? (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
              />
            ) : (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
                value={item.handover}
              />
            )}
            <Select
              placeholder="Select Person..."
              name="handover"
              isSearchable
              hideSelectedOptions={false}
              id={"sel_hand" + item.id}
              value={handover_options.filter(
                (handover_options) => handover_options.value == item.handover
              )}
              // value={handover_options.key}
              // value={handover_options.filter(handover_options =>
              //     handover_options.value == SubList.handover)}
              key={handover_options.key}
              options={handover_options}
              onChange={(option) =>
                handleSelecthand(option, item.id, index, "PETTYCASH")
              }
            />
          </td>
        </tr>
      </>
    );
  });

  OTHERS = GetOthers.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td align="center">
            {item.site_received_status == "received" ? (
              <input
                type="hidden"
                name="site_id[]"
                id={"site_id" + item.id}
                value={item.id}
              />
            ) : (
              <input type="hidden" name="site_id[]" id={"site_id" + item.id} />
            )}

            {item.site_received_status == "received" ? (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
                defaultValue="received"
                defaultChecked
              />
            ) : (
              <input
                type="checkbox"
                name="rec[]"
                id={"rec" + item.id}
                onChange={(e) => received(e, item.id)}
              />
            )}
          </td>

          <td>{item.from_wh}</td>
          <td>{item.to_wh}</td>

          <td>{item.remarks}</td>
          <td>
            {isNaN(item.handover) ? (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
              />
            ) : (
              <input
                type="hidden"
                id={"handover" + item.id}
                name="handover[]"
                value={item.handover}
              />
            )}
            <Select
              placeholder="Select Person..."
              name="handover"
              isSearchable
              isClearable={true}
              hideSelectedOptions={false}
              // value={handover_options.filter(handover_options =>
              //     handover_options.value == SubList.handover)}
              value={handover_options.filter(
                (handover_options) => handover_options.value == item.handover
              )}
              id={"sel_hand" + item.id}
              key={handover_options.key}
              options={handover_options}
              onChange={(option) =>
                handleSelecthand(option, item.id, index, "OTHERS")
              }
            />
          </td>
        </tr>
      </>
    );
  });

  return (
    <div className="acknowledgement">
      <div className="table-hdbox">
        <div className="icon">
          <i className="fa-solid fa-file-invoice-dollar"></i>
        </div>
        <div className="">
          <h6>INVOICE</h6>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered  display" cellSpacing="0">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Received / Not</th>
              <th>Inv NO</th>
              <th>Inv Date </th>
              <th>Inv For </th>
              <th>Vendor Name</th>

              <th>Refernce No</th>
              <th>Amount</th>

              <th>Type of Expenses</th>
              <th>Remarks</th>
              <th>bill Upload</th>
              <th>Handover To</th>
            </tr>
          </thead>
          <tbody>{INVOICE}</tbody>
        </table>
      </div>

      <div className="table-hdbox pc">
        <div className="icon">
          <i className="fa-solid fa-sack-dollar"></i>
        </div>
        <div className="">
          <h6>PETTY CASH</h6>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered  display" cellSpacing="0">
          <thead>
            <tr>
              <th>#S.No</th>
              <th>Received / Not</th>
              <th>Inv For </th>
              <th>Reference No</th>
              <th>Remarks</th>
              <th>Handover To</th>
            </tr>
          </thead>
          <tbody>{PETTYCASH}</tbody>
        </table>
      </div>

        
      <div className="table-responsive">
        <div className="table-hdbox lico">
          <div className="icon">
            <i className="fa-solid fa-envelope"></i>
          </div>
          <div className="">
            <h6>LETTER / INVITATION / CLAIM / OTHERS</h6>
          </div>
        </div>
        <table className="table table-bordered  display" cellSpacing="0">
          <thead>
            <tr>
              <th>#S.No</th>
              <th>Received / Not</th>
              <th>From Whom</th>
              <th>To Whom</th>
              <th>Remarks</th>
              <th>Handover To</th>
            </tr>
          </thead>
          <tbody>{OTHERS}</tbody>
        </table>
      </div>

      <div className="d-flex justify-content-end w-100">
        <div className="table-responsive">
          <table className="table table-bordered  display" cellSpacing="0">
            <thead>
              <tr>
                <th>Acknowledged By</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user}</td>
                <td>
                  {day}/{month}/{year}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <input
        type="hidden"
        id="acknowleged_by"
        name="acknowleged_by"
        value={user}
      />
      <input
        type="hidden"
        id="acknowleged_date"
        name="acknowleged_date"
        value={det}
      />
      <div className="btn-align">
        <button
          type="submit"
          onClick={submitAction}
          className="  px-4 float-end my-4"
        >
          Action
        </button>
      </div>
    </div>
  );
};

export default AllCatsub;
