import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import $ from 'jquery'; 

function ViewCourier() {
const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [courierlist, setCourierlist] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/view-hoentry`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setCourierlist(res.data.ho_entry)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

const hideshown=(e,trid)=>{
   
     e.preventDefault();
       var tr =$('#child'+trid);
        // var row = table.row(tr);
 // alert(tr.className.indexOf('shown'));
        if (tr.attr('class')=='shown') {
            // This row is already open - close it
            // tr.hide();
            tr.removeClass('shown');
            tr.addClass('hidden');
             // tr.find('svg').attr('data-icon', 'plus-circle');    // FontAwesome 5
        } else {
            // Open this row
            // tr.show();
            tr.removeClass('hidden');
            tr.addClass('shown');
            
             // tr.find('svg').attr('data-icon', 'minus-circle'); // FontAwesome 5
        }
}
    const deleteHo = (e, id) => {
        e.preventDefault();
        
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting";

        axios.get(`/api/delete-Ho/${id}`).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");
                thisClicked.closest("tr").remove();
                window.location.reload();
            }
            else if(res.data.status === 404)
            {
                swal("Success",res.data.message,"success");
                thisClicked.innerText = "Delete";
                 window.location.reload();
            }
        });

    }

    var viewcourier_HTMLTABLE = "";
    if(loading)
    {
        return <h4>Loading Entry...</h4>
    }
    else
    {

        viewcourier_HTMLTABLE = 
        courierlist.map( (item,index) => {
            return (
            <>
                <tr key={item.ho_id}>
                <td  className="dt-control" id={`dtctrl${item.id}`} onClick={ (e) =>hideshown(e,item.id) }><i class="fa fa-plus-circle primary" aria-hidden="true"></i></td>
                <td>{index+1}</td>
                    <td>{item.entry_date}</td>
                    <td>{item.ho_entry_no}</td>
                    <td>{item.site_name}</td>
                    <td>{item.company_name}</td>
                      <td>{item.courier_mode_id}</td>
                        
                                
                                    
                    <td>
                        <Link to={`/admin/update-ho/${item.ho_id}`} className="btn btn-success btn-sm">Edit</Link>
                    </td>
                    <td>
                        <button type="button" onClick={ (e) => deleteHo(e, item.ho_entry_no) } className="btn btn-danger btn-sm">Delete</button>
                    </td>
                </tr>
                   <tr key={`child${item.ho_id}`} id={`child${item.ho_id}`} className="hidden">
<td colSpan="9">
<table cellPadding="9" cellSpacing="0" border="0" >
<tbody>
<tr>
<td>Docket No : </td>
<td>{item.docket_no}</td>
</tr>
<tr>
<td>From whom : </td>
<td>{item.from_wh}</td>
</tr>
<tr>
<td>By Hand : </td>
 <td>{item.by_hand}</td>
</tr>
<tr>
<td>Entry By : </td>
<td>{item.entry_by}</td>
</tr>

</tbody>
</table>
</td>
                </tr>
             </>
            )
        });
    }

    return  (
        <div className="container px-4">
            <div className="card mt-4">
                <div className="card-header">
                    <h4>HO Entry  List 
                        <Link to="/admin/create-ho" className="btn btn-primary btn-sm float-end">Add +</Link>
                    </h4>
                </div>
                <div className="card-body table-responsive ">
                
                    <table id="example" className="table table-bordered table-hover  display" cellSpacing="0">
                        <thead>
                            <tr>
                            <th></th>
           <th>S.NO</th>
                                <th>Entry Date</th>
                                <th>HO Entry Number</th>
                                <th>Site Name</th>
                               
                                <th>Courier name</th>
                                <th>Courier mode name</th>
                              
                                <th>edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewcourier_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ViewCourier;