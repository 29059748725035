import React from "react";

const ReadOnlyRow = ({ category, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
     <td><input type="checkbox"/></td>
      <td>{category.category}</td>
      <td>{category.from_whom}</td>
      <td>{category.to_whom}</td>
      <td>{category.remarks}</td>
      <td>
        <button
          type="button"
          onClick={(event) => handleEditClick(event, category)} className="btn btn-success"
        >
          Edit
        </button>
        <button type="button" onClick={() => handleDeleteClick(category.id)} className="btn btn-danger">
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;