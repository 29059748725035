import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import $ from 'jquery'; 
import DataTable, { ExpanderComponentProps,createTheme } from 'react-data-table-component';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

function ViewSiteEntry() {
const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [courierlist, setCourierlist] = useState([]);
///Fetch list of the Main form using view-main-siteentry API
    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/view-main-siteentry`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setCourierlist(res.data.courier)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);


///delete the Main form with sublist using Destroy_main API
    const deleteCourier = (e, id) => {
        e.preventDefault();
        
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting";

        axios.get(`/api/destroy_main/${id}`).then(res=>{
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");
                // thisClicked.closest(".sc-jrcTuL .dnyupR .rdt_TableRow").remove();
              
                 $(thisClicked).parent().parent().parent().remove();
                // window.location.reload();
            }
            else if(res.data.status === 404)
            {
                swal("Success",res.data.message,"success");
                thisClicked.innerText = "Delete";
                 window.location.reload();
            }
        });

    }

////////////////////starting Data Table ///////////////////////////////////////
// -----------------CSV Download-------------------------------
    const downloadCSV=(array)=> {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
if (csv == null) return;

const filename = 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
 }

 link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const convertArrayOfObjectsToCSV=(e,array)=> {
    e.preventDefault();
  let result;
// console.log(Object.keys(courierlist[0]));
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(courierlist[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
      let ctr = 0;
     keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

         result += item[key];          
          ctr++;
      });
      result += lineDelimiter;
 });

  return result;
}



const Export = ({ onExport }) =>
<div className='export'>
    <small>Export File </small>
    <span onClick={e => onExport(e.target.value)}> 
        <i className="fa-solid fa-file-export"></i>
    </span>
</div>;
const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(courierlist)} />, []);
/////////////////////////////////////ending exporting csv///////////////////////////////
////////////////////Data table columns
const columns = [
    {
        name: 'Tracker Form Number',
        selector: row => row.tracker_form_no,
         sortable: true,
          width:"180px",
          wrap: true,
          padding:0,
    },
    {
        name: 'Date',
        selector: row => row.date,
         sortable: true,
          width:"150px",
          wrap: true,
          padding:0,
    },

    {
        name: 'Site Name',
        selector: row => row.site_name,
         sortable: true,
          width:"150px",
    },
     {
        name: 'Mode',
        selector: row => row.courier_mode_name,
         sortable: true,
    },
     {
        name: 'Courier Name',
        selector: row => row.company_name,
         sortable: true,
          width:"150px",
          wrap: true,
    },
     
    

    {
        name: 'Edit',
        selector: row => <Link to={`/admin/edit_ack_site_entry/${row.id}`} className="editclass"><i className="fas fa-edit"></i></Link>,
        width:"80px"
       
    },
    {
        name: 'Delete',
        selector: row => <span className="delclass" onClick={ (e) => deleteCourier(e, row.id) }><i className="fas fa-trash"></i></span>,
width:"80px"
    },
];

/////////////////////////////Search (Filter Field)///////////////////////////////////
//Clear Button
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;`;
//Field Search for Datatable
const TextField = styled.input `
height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
      <input
          id="search"
         type="text"
         autoFocus={true}
         placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
          className="searchbox"
      />
      <ClearButton type="button" onClick={onClear} className="clearbtn">
          <i className='fas fa-close' />
      </ClearButton>
  </>
);

const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = courierlist.filter(
     item => item.tracker_form_no && item.tracker_form_no.toLowerCase().includes(filterText.toLowerCase()),
 );

    const subHeaderComponentMemo = React.useMemo(() => {
     const handleClear = () => {
          if (filterText) {
             setResetPaginationToggle(!resetPaginationToggle);
             setFilterText('');
         }
     };
const onFilterValue = (e) => {
    e.preventDefault();
    setFilterText(e.target.value)
    e.target.focus()

}
     return (
        <FilterComponent onFilter={onFilterValue} onClear={handleClear} filterText={filterText} />
     );     
}, [filterText, resetPaginationToggle]);
    /////////////////////////////////Ending Filter Component
    /////////////////////////Expanded Columns/////////////////////
    const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return (
      
            <table cellPadding="2" cellSpacing="0" border="0" className="InnerTable">
<tbody>
<tr>
<td>Docket No.</td>
<td>:</td>
<td>{data.docket_no}</td>
</tr>
<tr>
<td>Dated</td>
<td>:</td>
<td>{data.dated}</td>
</tr>
<tr>
<td>entered_by</td>
<td>:</td>
<td>{data.entered_by}</td>
</tr>
<tr>
<td>Entered Time and Date</td>
<td>:</td>
<td>{data.entered_time_and_date}</td>
</tr>

<tr>
<td>Status </td>
<td>:</td>
<td>{data.status==1 ?'Active' : 'Inactive'}</td>
</tr></tbody>
        </table>
    );;
};
//////////////////////////////Ending Expanded Columns///////////
   
////////////////////////////custom styles//////////////////////////////////
//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '28px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '28px',
        },
    },
};
createTheme('solarized', {
  text: {
    primary: 'black',
    secondary: 'black',
  },
  background: {
    default: 'white',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

////////////////////////////////////////////////////////end Custom Styles/////////////
  
    return  (
         <div className="ViewSiteEntry_acknowledge">
            <div className="container px-4">
                <div className="card mt-4">
                    <div className='card-icon'>
                        <i className="fa-solid fa-table-list" />
                    </div>
                    <div className="card-header">
                        <div>
                            <h4>Courier Company Creation List  </h4>
                        </div>
                        <div>
                            <Link to="/admin/add_ack_site_entry" className="btn btn-primary btn-sm float-end">Add +</Link>
                        </div>
                    </div>
                    <div className="card-body">
                      <DataTable columns={columns} data={filteredItems}
                           pagination
                           expandableRows
                            expandableRowsComponent={ExpandedComponent}
                           fixedHeader
                           fixedHeaderScrollHeight="500px"
                           highlightOnHover
                           subHeader
                           paginationResetDefaultPage={resetPaginationToggle} 
                            subHeaderComponent={subHeaderComponentMemo}
                                   actions={actionsMemo}
                                  
                                   persistTableHead
                                   customStyles={customStyles}
                                   
                                  

                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ViewSiteEntry;