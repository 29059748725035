import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import View from "../../../assets/admin/img/View.png";
import Datetime from "react-datetime";
const token2 = localStorage.getItem("auth_token");
const user_name = localStorage.getItem("auth_name");
const AllCatsub = ({ MainData }) => {
  var INVOICE = "";
  var PETTYCASH = "";
  var OTHERS = "";
  const [GetInvoice, setInvoice] = useState([]);
  const [GetPettycash, setPettycash] = useState([]);
  const [GetOthers, setOthers] = useState([]);

  useEffect(() => {
    var stop = "";
    const timer2 = setInterval(() => {
      stop = "1";
    }, 3000);
    //1800000
    if (MainData.categoery != "") {
      const timer = setInterval(() => {
        if (stop == 1) {
          clearInterval(timer);
          clearInterval(timer2);
          stop = "";
        }
        CallList();
      }, 7000);
    } else {
      CallList();
    }

    CallList();
  }, []);
  const CallList = (e) => {
    const data = {
      ho_entry_no: MainData.invoice_no,
    };

    axios.post(`/api/get-subdata/`, data).then((res) => {
      if (res.data.inv_status == 200) {
        setInvoice(res.data.ho_entry_invoice);
      }
      if (res.data.petty_satus == 200) {
        setPettycash(res.data.ho_entry_pettycash);
      }

      if (res.data.other_status == 200) {
        setOthers(res.data.ho_entry_Others);
      }
    });
  };

  INVOICE = GetInvoice.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td>{item.inv_no}</td>
          <td>{item.inv_date}</td>
          <td>{item.inv_for}</td>
          <td>{item.party_name}</td>

          <td>{item.reference_no}</td>
          <td>{item.amount}</td>
          <td>{item.type_expenses}</td>
          <td>{item.remarks}</td>
          <td align="center">
            <img src={View} wdith="30" height="30" />
          </td>
          <td>{item.employee_name}</td>
        </tr>
      </>
    );
  });

  PETTYCASH = GetPettycash.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>

          <td>{item.inv_for}</td>
          <td>{item.reference_no}</td>

          <td>{item.remarks}</td>
          <td>
            {item.employee_name} / {item.div_name}
          </td>
        </tr>
      </>
    );
  });

  OTHERS = GetOthers.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>

          <td>{item.from_wh}</td>
          <td>{item.to_wh}</td>

          <td>{item.remarks}</td>
          <td>
            {item.employee_name} / {item.div_name}
          </td>
        </tr>
      </>
    );
  });

  return (
    <div>
      <div className="card mt-4">
        <div className="card-icon">
          <i className="fa-solid fa-table-list" />
        </div>
        <div className="card-header">
          <h4>Invoice</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table   display" cellSpacing="0">
              <thead>
                <tr>
                  <th>S.No</th>

                  <th>Inv NO</th>
                  <th>Inv Date </th>
                  <th>Inv For </th>
                  <th>Vendor Name</th>

                  <th>Refernce No</th>
                  <th>Amount</th>

                  <th>Type of Expenses</th>
                  <th>Remarks</th>
                  <th>bill Upload</th>
                  <th>Handover to</th>
                </tr>
              </thead>
              <tbody>{INVOICE}</tbody>
            </table>
          </div>
        </div>{" "}
      </div>

      <div className="card mt-4">
        <div className="card-icon">
          <i className="fa-solid fa-table-list" />
        </div>
        <div className="card-header">
          <h4>Pettycash</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table   display" cellSpacing="0">
              <thead>
                <tr>
                  <th>#S.No</th>
                  <th>Inv For </th>
                  <th>Reference No</th>
                  <th>Remarks</th>
                  <th>Handover to</th>
                </tr>
              </thead>
              <tbody>{PETTYCASH}</tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-icon">
          <i className="fa-solid fa-table-list" />
        </div>
        <div className="card-header">
          <h4>Letter / Invitation / Claim / Others</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table   display" cellSpacing="0">
              <thead>
                <tr>
                  <th>#S.No</th>
                  <th>From Whom</th>
                  <th>To Whom</th>
                  <th>Remarks</th>
                  <th>Handover to</th>
                </tr>
              </thead>
              <tbody>{OTHERS}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCatsub;
