import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";
import Datetime from "react-datetime";
import {
  faHome,
  faArrowsUpDown,
  faEdit,
  faDeleteLeft,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const token2 = localStorage.getItem("auth_token");
const user_name = localStorage.getItem("auth_name");
const user_id = localStorage.getItem("rat_id");
const PettyCash = ({ MainData }) => {
  var user = null;
  if (token2) user = user_name;
  else user = null;
  var list = "";
  const [Pettycash, setPettycash] = useState([]);
  const [showrefno, setrefno] = useState([]);
  const [Sublist, setSublist] = useState([]);
  const [getbutton, setbutton] = useState([]);
  const [showemployee, setemployee] = useState([]);
  const [handover_by_choosen, sethandover_by_choosen] = useState([]);

  var button = "";
  var EDITVIST = "";
  var category = "";

  const state = (e) => {
    SubList.refernce_no = "";

    SubList.remarks = "";
    SubList.handover = "";

    handleInput(e);
  };
  useEffect(() => {
    setTimeout(() => {
      
      Getsublist();
    }, 4000);
    setbutton(button);
    axios.post(`/api/get-employee`).then((res) => {
      if (res.data.status === 200) {
        setemployee(res.data.employee);
      }
    });
  }, []);

  const handover_options = showemployee.map((option, index) => {
    return {
      label: option.employee_name + "/" + option.designation_name,
      value: option.user_id_a,
      key: option.user_id_a,
    };
  });

  const handleSelecthand = (option) => {
    document.getElementById("handover").value = option.value;

    sethandover_by_choosen(option.key);
    setSubList({ ...SubList, handover: option.key }, () => {});
  };

  const Getsublist = (e) => {
    const data = {
      ho_entry_no: document.getElementById("invoice_no").value,

      category: "PettyCash",
    };

    axios.post(`/api/view-invsub`, data).then((res) => {
      if (res.data.ho_entrysub != "") {
        document.getElementById("site_id").setAttribute("disabled", "disabled");
        document
          .getElementById("courier_name")
          .setAttribute("disabled", "disabled");
        document
          .getElementById("courier_mode")
          .setAttribute("disabled", "disabled");

        setPettycash(res.data.ho_entrysub);
      } else {
        document.getElementById("site_id").removeAttribute("disabled");
        document.getElementById("courier_name").removeAttribute("disabled");
        document.getElementById("courier_mode").removeAttribute("disabled");

        setPettycash(res.data.ho_entrysub);
      }
    });
  };

  useEffect(() => {
    if (EDITVIST == 200) {
      button = (
        <button
          type="submit"
          className="btn btn-primary px-4 float-end"
          onClick={edithopettysub}
        >
          <span>Edit</span>
        </button>
      );
    } else {
      button = (
        <button
          type="submit"
          className="btn btn-primary px-4 float-end"
          onClick={submitpettycash}
        >
          <span>Add</span>
        </button>
      );
    }
    setbutton(button);
  }, []);

  list = Pettycash.map((item, index) => {
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td>{item.inv_for}</td>
          <td>{item.reference_no}</td>
          <td>{item.remarks}</td>
          <td>
            {item.employee_name} / {item.div_name}
          </td>

          <td className="td-flex">
            <button
              onClick={(e) => edit_sub(e, item.ho_id)}
              className="editclass btn-sm"
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>

            <button
              type="button"
              onClick={(e) => sub_del(e, item.ho_id)}
              className="delclass btn-sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      </>
    );
  });

  const [SubList, setSubList] = useState({
    sub_inv_no: "",
    sub_inv_date: "",
    vendor_name: "",
    refernce_no: "",
    amount: "",
    exp_type: "",
    remarks: "",
    ch_for: "",
    bill_upload: "",
    handover: "",
    edit_id: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setSubList({ ...SubList, [e.target.name]: e.target.value });
  };

  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  const submitpettycash = (e) => {
    e.preventDefault();

    MainData.site_id = document.getElementById("s_id").value;

    MainData.courier_mode = document.getElementById("courier_mode").value;
    MainData.invoice_no = document.getElementById("invoice_no").value;
    MainData.docket_no = document.getElementById("docket_no").value;
    MainData.form_wh = document.getElementById("form_wh").value;
    MainData.recived_by = document.getElementById("recived_by").value;

    var edit_refernce_no = document.getElementById("petty_refernce_no").value;
    var edit_remarks = document.getElementById("petty_remarks").value;
    var edit_handover = document.getElementById("handover").value;
    var edit_ch_for = document.getElementById("ch_for").value;
    SubList.ch_for = edit_ch_for;
    SubList.refernce_no = edit_refernce_no;
    alert(SubList.refernce_no);
    SubList.remarks = edit_remarks;
    SubList.handover = edit_handover;

    if (MainData.courier_mode == "By Hand") {
      MainData.by_hand = document.getElementById("by_hand").value;
      MainData.courier_name = "";
      if (!MainData.by_hand) {
        swal("Fill this by hand");

        return false;
      }
    } else if (MainData.courier_mode == "Courier") {
      MainData.courier_name = document.getElementById("c_id").value;
      MainData.by_hand = "";

      if (!MainData.courier_name) {
        swal("Select Courier Name");

        return false;
      }
    }

    if (MainData.entry_date == "") {
      MainData.entry_date = defaultValue;
    }

    MainData.enter_by = user;

    if (!MainData.site_id) {
      swal("Select Site Name");

      return false;
    } else if (!MainData.courier_mode) {
      swal("Select Mode Name");

      return false;
    } else if (!MainData.docket_no) {
      swal("Fill  docket no");

      return false;
    } else if (!MainData.form_wh) {
      swal("Fill  From Whom");

      return false;
    } else if (!MainData.recived_by) {
      swal("Fill recived by");

      return false;
    } else if (!SubList.refernce_no) {
      SubList.error_list = "fill refernce no";
      handleInput(e);
      return false;
    } else if (!SubList.handover) {
      SubList.error_list = "fill Handover To";
      handleInput(e);
      return false;
    } else {
      SubList.error_list = "";

      handleInput(e);

      const data = {
        ho_entry_no: MainData.invoice_no,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        recived_by: MainData.recived_by,
        category: "PettyCash",

        inv_no: "",
        inv_date: "",
        vendor_name: "",

        amount: "",
        type_expenses: "",

        handover: SubList.handover,
        inv_for: SubList.ch_for,

        reference_no: SubList.refernce_no,
        remarks: SubList.remarks,
      };

      axios.post(`api/store-ho`, data).then((res) => {
        if (res.data.status === 200) {
          Getsublist(e);
          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitpettycash}
            >
              <span>Add</span>
            </button>
          );
          setbutton(button);
          state(e);
        } else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });
    }
  };

  const edithopettysub = (e, id) => {
    e.preventDefault();

    MainData.site_id = document.getElementById("s_id").value;

    MainData.courier_mode = document.getElementById("courier_mode").value;
    MainData.invoice_no = document.getElementById("invoice_no").value;
    MainData.docket_no = document.getElementById("docket_no").value;
    MainData.form_wh = document.getElementById("form_wh").value;
    MainData.recived_by = document.getElementById("recived_by").value;
    MainData.categoery = document.getElementById("categoery")?.value;

    var edit_refernce_no = document.getElementById("petty_refernce_no").value;

    var edit_remarks = document.getElementById("petty_remarks").value;
    var edit_handover = document.getElementById("handover").value;
    SubList.refernce_no = edit_refernce_no;

    SubList.remarks = edit_remarks;
    SubList.handover = edit_handover;

    handleInput(e);

    if (MainData.entry_date == "") {
      MainData.entry_date = defaultValue;
    }

    MainData.enter_by = user;

    if (MainData.courier_mode == "By Hand") {
      MainData.by_hand = document.getElementById("by_hand").value;
      MainData.courier_name = "";
      if (!MainData.by_hand) {
        swal("Fill this by hand");

        return false;
      }
    } else if (MainData.courier_mode == "Courier") {
      MainData.courier_name = document.getElementById("c_id").value;
      MainData.by_hand = "";

      if (!MainData.courier_name) {
        swal("Select Courier Name");

        return false;
      }
    }

    if (!MainData.site_id) {
      swal("Select Site Name");

      return false;
    } else if (!MainData.courier_mode) {
      swal("Select Mode Name");

      return false;
    } else if (!MainData.docket_no) {
      swal("Fill  docket no");

      return false;
    } else if (!MainData.form_wh) {
      swal("Fill  From Whom");

      return false;
    } else if (!MainData.recived_by) {
      swal("Fill recived by");

      return false;
    } else if (!SubList.refernce_no) {
      SubList.error_list = "fill refernce no";
      handleInput(e);
      return false;
    } else if (!SubList.handover) {
      SubList.error_list = "fill Handover To";
      handleInput(e);
      return false;
    } else {
      SubList.error_list = "";

      handleInput(e);

      const data = {
        ho_entry_no: MainData.invoice_no,
        site_id: MainData.site_id,
        entry_date: MainData.entry_date,
        courier_id: MainData.courier_name,
        courier_mode_id: MainData.courier_mode,
        by_hand: MainData.by_hand,
        docket_no: MainData.docket_no,
        from_wh: MainData.form_wh,
        date_time: MainData.entry_date,
        entry_by: user_id,
        recived_by: MainData.recived_by,
        category: "PettyCash",

        inv_no: "",
        inv_date: "",
        vendor_name: "",

        amount: "",
        type_expenses: "",

        handover: SubList.handover,

        inv_for: SubList.ch_for,
        reference_no: SubList.refernce_no,
        remarks: SubList.remarks,
        edit_id: SubList.edit_id,
      };

      axios.post(`api/edit-ho`, data).then((res) => {
        if (res.data.status === 200) {
          Getsublist(e);

          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitpettycash}
            >
              <span>Add</span>
            </button>
          );
          setbutton(button);
          state(e);
          e.target.reset();
        } else if (res.data.status === 400) {
          setSubList({ ...MainData, error_list: res.data.errors });
        }
      });
    }
  };

  const edit_sub = (e, id) => {
    e.preventDefault();
    axios.get(`/api/get-subho/${id}`).then((res) => {
      if (res.data.status === 200) {
        EDITVIST = res.data.status;
        getref_no(e);
        SubList.ch_for = res.data.inv_for;
        SubList.refernce_no = res.data.reference_no;

        SubList.remarks = res.data.remarks;
        SubList.handover = res.data.handover;
        SubList.edit_id = res.data.ho_id;
        handleInput(e);
        if (EDITVIST == 200) {
          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={edithopettysub}
            >
              <span>Edit</span>
            </button>
          );
        } else {
          button = (
            <button
              type="submit"
              className="btn btn-primary px-4 float-end"
              onClick={submitpettycash}
            >
              <span>Add</span>
            </button>
          );
        }
        setbutton(button);
      }
    });
  };
  const sub_del = (e, id) => {
    e.preventDefault();
    axios.get(`/api/det-subho/${id}`).then((res) => {
      if (res.data.status === 200) {
        Getsublist(e);
      }
    });
  };
  const inv_table = [
    { id: "Purchase Order", name: "Purchase Order" },
    { id: "Work Order", name: "work_order_main" },
    { id: "Direct Purchase", name: "Direct Purchase" },
    { id: "GRN", name: "GRN" },
    { id: "Other Expenses", name: "Other Expenses" },
    { id: "Transporter", name: "Transporter" },
    { id: "Sales Order", name: "Sales Order" },
    { id: "DC", name: "DC" },
  ];
  var INVTABLE = "";
  INVTABLE = inv_table.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  const getref_no = (e) => {
    handleInput(e);
    var get_inv = document.getElementById("ch_for").value;

    var site_id = document.getElementById("s_id").value;
    const data = {
      site_id: site_id,
      vendor_id: "Pettycash",
      invoice_for: get_inv,
    };

    axios.post(`/api/get-ref_no`, data).then((res) => {
      if (res.data.status === 200) {
        setrefno(res.data.ref_no);
      } else if (res.data.status === 400) {
        swal("No Refernce Number For This Vendor");
      }
    });
  };

  var REFNO = "";
  REFNO = showrefno.map((item, index) => {
    return (
      <option key={item.reference_no} value={item.reference_no}>
        {item.reference_no}
      </option>
    );
  });

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 999999999, position: 'absolute', top: '150px' }),
    menu: provided => ({ ...provided, zIndex: 999999999, position: 'absolute', top: '150px' })
    ///.....
  }

  return (
    <form calssName="form-horizontal" id="HO_FORM">
      <span style={{ color: "red" }}>{SubList.error_list}</span>
      <div className="table-hdbox pc">
        <div className="icon">
          <i className="fa-solid fa-sack-dollar"></i>
        </div>
        <div className="">
          <h6>PETTY CASH</h6>
        </div>
      </div>
      <div className="table-responsive">  
        <table className="table  display" cellSpacing="0">
          <thead>
            <tr>
              <th>#S.No</th>
              <th>chash For</th>
              <th>Reference No</th>
              <th>Remarks</th>
              <th>Handover to</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#</td>
              <td>
                {" "}
                <select
                  type="text"
                  name="ch_for"
                  id="ch_for"
                  className="form-invsbcontrol"
                  onChange={getref_no}
                  value={SubList.ch_for}
                  defaultValue={SubList.ch_for}
                >
                  <option key="" value="">
                    Select
                  </option>
                  {INVTABLE}
                </select>
              </td>

              <td>
                <select
                  type="hidden"
                  name="refernce_no"
                  id="petty_refernce_no"
                  className="form-invsbcontrol"
                  onChange={handleInput}
                  value={SubList.refernce_no}
                  defaultValue={SubList.refernce_no}
                >
                  <option key="" value="">
                    Select
                  </option>
                  {REFNO}
                </select>
              </td>
              <td>
                {" "}
                <input
                  type="text"
                  name="remarks"
                  id="petty_remarks"
                  onChange={handleInput}
                  value={SubList.remarks}
                  className="form-invsbcontrol"
                />
              </td>
              <td>
                <input
                  type="hidden"
                  value={handover_by_choosen}
                  id="handover"
                />
                <Select
                  placeholder="Select Person..."
                  name="handover"
                  isSearchable
                  value={handover_options.filter(
                    (handover_options) =>
                      handover_options.value == SubList.handover
                  )}
                  key={handover_options.key}
                  options={handover_options}
                  onChange={(option) => handleSelecthand(option)}
                  styles={customStyles}
                  // styles={{ menuPortal: base => ({ ...base, zIndex: 999999999999 }) }}
                  // styles={{
                  //   // Fixes the overlapping problem of the component
                  //   menu: provided => ({ ...provided, zIndex: 9999, position: absolute })
                  // }}
                />
              </td>
              <td>{getbutton}</td>
            </tr>

            {list}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default PettyCash;
