import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import View from '../../../assets/admin/img/View.png';
import Datetime from 'react-datetime';
import Select from 'react-select';
const token2 = localStorage.getItem('auth_token');
const user_name = localStorage.getItem('auth_name');
const AllCatsub = ({ MainData }) => {

    var user = null;
    if (token2)
        user = user_name;
    else
        user = null;

    const date = new Date();
    const det = date.toLocaleDateString('en-CA');
    const [year, month, day] = det.split('-');


    var INVOICE = "";
    var PETTYCASH = "";
    var OTHERS = "";
    var empty = '';
    const [GetInvoice, setInvoice] = useState([]);
    const [GetPettycash, setPettycash] = useState([]);
    const [GetOthers, setOthers] = useState([]);

    const [showemployee, setemployee] = useState([]);
    const [handover_by_choosen, sethandover_by_choosen] = useState([]);
    const [isChecked, setIsChecked] = useState();
    const [isid, setid] = useState();
    const [showstatus, setsatus] = useState();
    const [showtick, settick] = useState();
    const [sedit, setedit] = useState(true);
    const [action, setaction] = useState({
        site_id: '',
        checked: '',
        handover_d: '',
    }


    );
    useEffect(() => {

        //         // setInterval(function(){alert("Hello")},3000);
        //         var stop='';
        // const timer2 = setInterval(() => {
        //     stop='1';
        //   }, 3000);
        // //1800000
        //         if(MainData.categoery!=''){
        // const timer = setInterval(() => {
        //     if(stop==1){

        //          clearInterval(timer);
        //          clearInterval(timer2);
        //          stop='';
        //     }
        //     CallList();
        //   }, 7000);
        //         }
        //         else{
        //             CallList();
        //         }
        setTimeout(() => {

            CallList();
            axios.post(`/api/get-employee`).then(res => {
                if (res.data.status === 200) {

                    setemployee(res.data.employee);

                }
            });



        }, 3000);

    }, []);

    const handover_options = showemployee.map((option, index) => {
        return { label: option.employee_name + '/' + option.designation_name, value: option.user_id_a, key: option.user_id_a }
      })
    const datas = [];


    const CallList = (e) => {
        var inv_no = document.getElementById('invoice').value;

        const data = {

            site_entry_no: inv_no,

        }


        axios.post(`/api/get-subdatasite`, data).then(res => {





            if (res.data.inv_status == 200) {

                setInvoice(res.data.site_entry_invoice);
            }
            if (res.data.petty_satus == 200) {
                setPettycash(res.data.ho_entry_pettycash);
            }

            if (res.data.other_status == 200) {

                setOthers(res.data.ho_entry_Others);


            }

        });

    }
    var recv = '';
    var status = '';

    const received = (e, id, ind) => {


        setid(id);
        setIsChecked(e.target.checked);

        // 👇️ this is the checkbox itself
        // console.log(e.target);

        // 👇️ this is the checked value of the field

        setTimeout(() => {
            var tick = document.getElementById('condi').value;


            console.log(tick);
            if (tick === 'true') {
                status = 'received';
                document.getElementById("rec" + id).setAttribute('checked', true);

                document.getElementById("rec" + id).setAttribute('value', 'received');
                document.getElementById("site_id" + id).setAttribute('value', id);
                settick(status);
                var dummy_invoice = GetInvoice
                dummy_invoice[ind]["site_received_status"] = "not-received"
                console.log(dummy_invoice)
                setInvoice(dummy_invoice);


            } else if (tick === 'false') {
                var s_id = '';
                status = 'not-received';
                // settick(empty);

                document.getElementById("rec" + id).setAttribute('value', empty);
                document.getElementById("rec" + id).setAttribute('checked', false);
                document.getElementById("site_id" + id).setAttribute('value', id);

                // GetInvoice.map((item1, index) => {
                //     if(item1.id==id)
                //     {
                //         setInvoice({...GetInvoice, [item1.site_received_status]: "not-received" });

                //     }
                // })

                // console.log(id)
                // console.log(GetInvoice)
                // console.log(GetInvoice[id-1])
                // console.log(GetInvoice[id-1]["site_received_status"])
            }
            setsatus(status);


            var invoice = document.getElementById('invoice').value;
            //  if(status=='not-received'){

            //   const data = {

            //     id: id,
            //     invoice: invoice,
            //     satus: '',
            //     hand_over:'',
            //     acknowleged_by: '',
            //     acknowleged_date: '',


            //   }

            //   axios.post(`/api/add-ack/`, data).then(res => {

            //     setIsChecked(empty);
            //     setid(empty);
            //     setsatus(empty);

            //   });





            //  }

        }, 1000);




    };

    const submitAction = (e) => {
        var chk = [];
        var chk_id = [];
        var hand_id = [];
        var input = document.getElementsByName('rec[]');
        var input2 = document.getElementsByName('site_id[]');

        var acknowleged_by = document.getElementById('acknowleged_by').value;
        var acknowleged_date = document.getElementById('acknowleged_date').value;
        var invoice = document.getElementById('invoice').value;
        for (var i = 0; i < input.length; i++) {
            var a = input[i];
            var b = input2[i];


            var k = a.value;
            var j = b.value;



            chk.push(k);
            chk_id.push(j);



        }
        // console.log(chk);
        // console.log(chk_id);
        // console.log(hand_id);
        const data = {

            id: chk_id,
            invoice: invoice,
            satus: chk,

            acknowleged_by: acknowleged_by,
            acknowleged_date: acknowleged_date,


        }
        axios.post(`/api/site-recv/`, data).then(res => {

            alert(res.data.status);
            if (res.data.status == 200) {

                swal("Success", res.data.message, "success");
                window.location.href = "/admin/ack";
            }

            chk = [];
            chk_id = [];
            hand_id = [];


        });
    }

    INVOICE =
        GetInvoice.map((item, index) => {

            return (
                <>
                    <tr >
                        <td>{index + 1}</td>
                        <td align='center'>
                            <input type="hidden" name='condi' id='condi' value={isChecked} />
                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} value={item.id} />
                            ) : (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} />
                            )}

                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} defaultValue='received' defaultChecked />
                            ) : (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} />
                            )}


                        </td>
                        <td>{item.inv_no}</td>
                        <td>{item.inv_date}</td>
                        <td>{item.inv_for}</td>
                        <td>{item.party_name}</td>

                        <td>{item.reference_no}</td>
                        <td>{item.amount}</td>
                        <td>{item.type_expenses}</td>
                        <td>{item.remarks}</td>
                        <td align='center'>

                            <img src={View} wdith='30' height='30' />
                        </td>




                    </tr>

                </>
            )
        });

    PETTYCASH =
        GetPettycash.map((item, index) => {
            return (
                <>
                    <tr >
                        <td>{index + 1}</td>
                        <td align='center'>
                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} value={item.id} />
                            ) : (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} />
                            )}
                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} defaultValue='received' defaultChecked />
                            ) : (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} />
                            )}
                        </td>

                        <td>{item.inv_for}</td>
                        <td>{item.reference_no}</td>

                        <td>{item.remarks}</td>




                    </tr>

                </>
            )
        });



    OTHERS =
        GetOthers.map((item, index) => {
            return (
                <>
                    <tr >
                        <td>{index + 1}</td>
                        <td align='center'>

                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} value={item.id} />
                            ) : (
                                <input type="hidden" name='site_id[]' id={'site_id' + item.id} />
                            )}


                            {item.reciver_acknowleged_status == 'received' ? (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} defaultValue='received' defaultChecked />
                            ) : (
                                <input type="checkbox" name='rec[]' id={'rec' + item.id} onChange={(e) => received(e, item.id)} />
                            )}
                        </td>

                        <td>{item.from_wh}</td>
                        <td>{item.to_wh}</td>

                        <td>{item.remarks}</td>




                    </tr>

                </>
            )
        });





    return (

        <div>
            <div className="card mt-4">
                <div className="card-header">
                    <h4>Invoice</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-hover table-striped display" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Received / Not</th>
                                <th>Inv NO</th>
                                <th>Inv Date </th>
                                <th>Inv For </th>
                                <th>Vendor Name</th>

                                <th>Refernce No</th>
                                <th>Amount</th>

                                <th>Type of Expenses</th>
                                <th>Remarks</th>
                                <th>bill Upload</th>



                            </tr>
                            {INVOICE}
                        </thead>
                    </table>
                </div> </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h4>Pettycash</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-hover table-striped display" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>#S.No</th>
                                <th>Received / Not</th>
                                <th>Inv For </th>
                                <th>Reference No</th>
                                <th>Remarks</th>



                            </tr>
                            {PETTYCASH}
                        </thead>
                    </table>
                </div>


            </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h4>Letter / Invitation / Claim / Others</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-hover table-striped display" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>#S.No</th>
                                <th>Received / Not</th>
                                <th>From Whom</th>
                                <th>To Whom</th>
                                <th>Remarks</th>



                            </tr>
                            {OTHERS}
                        </thead>
                    </table>
                    <table className="table table-bordered table-hover table-striped display" cellSpacing="0">
                        <thead>
                            <tr>
                                <th align='left'>Acknowledged By</th>
                                <th>{user}</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>{day}/{month}/{year}</th>
                            </tr>
                        </thead>

                    </table>
                    <input type='hidden' id='acknowleged_by' name='acknowleged_by' value={user} />
                    <input type='hidden' id='acknowleged_date' name='acknowleged_date' value={det} />
                    <button type="submit" onClick={submitAction} className="btn btn-primary px-4 float-end">Action</button>
                </div>


            </div>
        </div>


    );
}

export default AllCatsub;