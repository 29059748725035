import React from 'react';



const Footer = () =>{


	return(
		 
            <footer className="footer">
                <div className="container-fluid px-4">
                    <div className="d-flex align-items-center justify-content-center small">
                        <div className="text-muted">Copyright © 2017-2018 ZIGMA All Rights Reserved.</div>
                        
                    </div>
                </div>
            </footer>
		);

}

export default Footer;